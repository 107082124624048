import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { AppBar } from "@mui/material";
import Schedule from "./Schedule";
import Timeline from "./Timeline";
import Overview from "./Overview";

const DataModal = ({ singleEnquiry, setSchedule, setComment, EmailComment, CallSchedule , users}) => {
  const [value, setValue] = React.useState("1");
  // console.log(singleEnquiry);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", p: "1rem", position: "relative" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Overview" value="1" />
            <Tab label="Appointment" value="2" />
            <Tab label="Comment Sheet" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Overview singleEnquiry={singleEnquiry} EmailComment={EmailComment} CallSchedule={CallSchedule} />
        </TabPanel>
        <TabPanel value="2">
          <Schedule setSchedule={setSchedule} singleEnquiry={singleEnquiry} users={users} />
        </TabPanel>
        <TabPanel value="3">
          <Timeline singleEnquiry={singleEnquiry} setComment={setComment} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default DataModal;
