import { getLoggedInUser } from "../../utils/loggedInUser";

export async function addIfNotExist(createLead, newEntry, debug = false) {
  // console.log("addIfNotExist in dbHelperMethods.js", dataAllLeads, criteria);

  if (!newEntry) {
    console.log("Entry to add is null");
    return;
  }

  const user = getLoggedInUser();

  try {
    const ManualForm = {
      manualForm: { ...newEntry, purpose: "Created", content: `${user?.name} generated this lead` },
    };
    await createLead(ManualForm);
    // await model.create(newEntry);  // Uncomment this when using database
  } catch (err) {
    console.log("addIfNotExist in dbHelperMethods.js, err:", err);
  }
}
