import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Stack, StepButton, StepLabel, TextField } from "@mui/material";
import { getLoggedInUser } from "../../../../utils/loggedInUser";
import { calculateActiveStep, calculateActiveStepForSubmission } from "./StatusCaclulator";
import Link from "@mui/material/Link";
import { studentFileDownloadPath } from "../../../../utils/studentFileDownloadUtil";
import DocumentCheck from "./Submission/DocumentCheck";
import ApplicationCheck from "./Submission/ApplicationCheck";
import CounselorView from "./Submission/CounselorView";
import { StateMachineAccessMethods } from "../../../../BussinessLogic/StateMachine/StateMachineAccess";

const steps = ["Documents Review", "Application Review", "Pending Submission"];
// const steps = ["Pending Submission", "Submitted", "Approved"];

const Submission = ({ setValue, setDisabledTab, disabledTab, onUpdate, data, student, updateStudent, studentTimelineUpdate, fileUpload }) => {
  // console.log(data);
  const [activeStep, setActiveStep] = useState(calculateActiveStepForSubmission(data, "submission", ["completed"]));
  const [proofFile, setProofFile] = useState("");
  const [proofFileObj, setProofFileObj] = useState(null);
  const [comment, setComment] = useState("");

  const loggedInUser = getLoggedInUser();

  function flattenObject(obj, parentKey = "") {
    let result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          // If the value is another nested object, recursively flatten it
          const nestedResult = flattenObject(obj[key], newKey);
          result = { ...result, ...nestedResult };
        } else if (Array.isArray(obj[key])) {
          // If the value is an array, loop through its elements
          for (let i = 0; i < obj[key].length; i++) {
            if (typeof obj[key][i] === "object") {
              // If the element is an object, recursively flatten it
              const nestedResult = flattenObject(obj[key][i], `${newKey}[${i}]`);
              result = { ...result, ...nestedResult };
            } else {
              // If the element is a primitive value, add it to the result
              result[`${newKey}[${i}]`] = obj[key][i];
            }
          }
        } else {
          // If the value is a primitive value, add it to the result
          result[newKey] = obj[key];
        }
      }
    }

    return result;
  }

  // const testData = flattenObject(student);

  const onFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProofFile(e.target.files[0].name);
      setProofFileObj(e.target.files[0]);
    }
  };

  const documentCheck = () => {
    const user = getLoggedInUser();
    onUpdate(
      {
        submission: {
          documentCheck: {
            uploadProf: proofFile,
            comments: comment,
            submittedBy: { id: user._id, name: user.name, email: user.email },
          },
        },
      },
      data._id,
      { uploadProf: proofFileObj },
    );
    studentTimelineUpdate("Document reviewed", "Review");
    //setActiveStep(1);
  };

  const nextTab = () => {
    setDisabledTab((prevData) => ({ ...prevData, tab2: false }));
    //setValue(2);
  };

  const appCheck = () => {
    const user = getLoggedInUser();
    onUpdate(
      {
        submission: {
          applicationCheck: {
            hello: "Hello",
          },
        },
      },
      data._id,
      { uploadProf: proofFileObj },
    );
    studentTimelineUpdate("Application reviewed before submission", "Review");
  };

  const approved = () => {
    const user = getLoggedInUser();
    onUpdate(
      {
        submission: {
          pendingSubmission: {
            uploadProf: proofFile,
            comments: comment,
            submittedBy: { id: user._id, name: user.name, email: user.email },
          },
        },
      },
      data._id,
      { uploadProf: proofFileObj },
    );
    studentTimelineUpdate("Application submitted and approved", "Submitted");

    // setActiveStep(2);
    // nextTab();
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <Stack
            spacing={2}
            alignItems="flex-start"
            sx={{
              px: 4,
            }}
          >
            <DocumentCheck data={data} onUpdate={onUpdate} student={student} fileUpload={fileUpload} />
            {/* <Typography variant="subtitle2">Assigned To: Admin</Typography> */}
            {/* <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2">Comments</Typography>
              <TextField
                id="outlined-multiline-static"
                placeholder="Enter Comments"
                sx={{ width: "100%" }}
                multiline
                rows={4}
                defaultValue=""
                onChange={(e) => setComment(e.target.value)}
              />
            </Box>
            <Box>
              <Typography variant="subtitle2">Upload Proof</Typography>
              <TextField type="file" onChange={onFileChange} />
            </Box> */}

            {loggedInUser?.role === (student?.appOfficer?.role || 'Application Officer') ||
              student?.counselor?.name === student?.appOfficer?.name 
              ?
              <Button
                variant="contained"
                onClick={documentCheck}
                disabled={!submissionAllVerifiedDocsCheck(data)}
                sx={{
                  position: "absolute",
                  right: "2%",
                  bottom: "0%",
                }}
              >
                Submit
              </Button>
              : ""}
          </Stack>
        );
      case 1:
        return (
          <Stack
            spacing={2}
            alignItems="flex-start"
            sx={{
              px: 4,
            }}
          >
            {/* <Typography variant="subtitle2">Assigned To: Admin</Typography> */}
            {loggedInUser?.role !== "Counselor" && <ApplicationCheck student={student} onUpdate={onUpdate} data={data} />}
            {loggedInUser?.role === "Counselor" && (
              <CounselorView onUpdate={onUpdate} student={student} data={data} updateStudent={updateStudent} />
            )}
            {StateMachineAccessMethods.roleAndUserCheck("Counselor", student?.counselor?.email) || (
              <Stack spacing={2} direction="row">
                <Button
                  sx={{
                    position: "absolute",
                    right: "2%",
                    bottom: "0%",
                  }}
                  variant="contained"
                  onClick={appCheck}
                >
                  Proceed
                </Button>
                {/* <Button variant="outlined">Revert</Button> */}
              </Stack>
            )}
          </Stack>
        );
      case 2:
        return StateMachineAccessMethods.roleAndUserCheck("Counselor", student?.counselor?.email) ? (
          <ApplicationInProgressComp />
        ) : (
          <Stack spacing={2}>
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2">Comments</Typography>
              <TextField
                id="outlined-multiline-static"
                placeholder="Enter Comments"
                sx={{ width: "100%", mt: "5px" }}
                multiline
                rows={4}
                defaultValue=""
                onChange={(e) => setComment(e.target.value)}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2">Upload Proof</Typography>
              <TextField type="file" onChange={onFileChange} sx={{ width: "100%", mt: "5px" }} />
            </Box>
            <Button
              variant="contained"
              onClick={approved}
              sx={{
                position: "absolute",
                right: "2%",
                bottom: "0%",
              }}
            >
              Approve
            </Button>
          </Stack>
        );
      default:
        return;
    }
  };
  const approvedDateObj = new Date(data.submission?.approved?.date);
  const approvedDate = approvedDateObj?.toLocaleString("en-US", { timeZone: "Asia/Karachi", dateStyle: "full", timeStyle: "medium" });
  const handleData = (step) => {
    switch (step) {
      case 0:
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              px: 10,
              pt: 2,
              "@media (max-width: 950px)": {
                p: "0px !important",
              },
            }}
          >
            {/* <Typography variant="subtitle1">Assigned to: {student?.appOfficer?.name}</Typography> */}
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Submitted By
              <span
                style={{
                  fontWeight: "500 ",
                }}
              >
                {data?.submission?.pendingSubmission?.submittedBy?.name || "N.A"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {" "}
              Proof
              <Link
                target="_blank"
                rel="noopener"
                download
                href={studentFileDownloadPath(data.stdId, data.submission?.pendingSubmission?.uploadProf)}
              >
                {" "}
                {data.submission?.pendingSubmission?.uploadProf}
              </Link>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // flexDirection: "column",
                // gap: ".5rem",
              }}
            >
              Comments
              <span
                style={{
                  fontWeight: "500 ",
                }}
              >
                {data.submission?.pendingSubmission?.comments}
              </span>
            </Typography>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="subtitle1">Approved By: {data?.submission?.approved?.approvedBy?.name || "N.A"}</Typography>
            <Typography variant="subtitle1">Date: {approvedDate}</Typography>
          </Box>
        );
      case 2:
        return <Box></Box>;
      default:
        return "";
    }
  };

  const [stepNumber, setStepNumber] = useState(0);
  useEffect(() => {
    setActiveStep(calculateActiveStepForSubmission(data, "submission", ["completed"]));
  }, [{ data }]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, idx) => (
          <Step key={label} disabled={data.stage > 1 ? false : true}>
            <StepLabel>{label}</StepLabel>
            {/* <StepButton onClick={() => setStepNumber(idx)}>{label}</StepButton> */}
          </Step>
        ))}
      </Stepper>
      {data.stage > 1 ? <Box mt={4}>{handleData(stepNumber)}</Box> : <Box mt={4}>{handleSteps(activeStep)}</Box>}
    </Box>
  );
};

export default Submission;

function submissionAllVerifiedDocsCheck(data) {
  // Check if data, appData, and documentUpload exist
  if (!data || !data.appData || !data.appData.documentUpload) return false;
  let allVerified = true;

  for (let field in data.appData.documentUpload) {
    const files = data.appData.documentUpload[field];
    for (let file of files) {
      // If any file in the field is not verified, return false
      if (!data.docCheck ||
        !data.docCheck[file] ||
        !data.docCheck[file].verify
      ) {
        return false;
      }
    }
  }
  // If all files in all fields are verified, return true
  return allVerified;
}

const ApplicationInProgressComp = ({ msg }) => {
  return (
    <Box sx={{ textAlign: "center" }}>
      {msg ? (
        <Typography>{msg}</Typography>
      ) : (
        <>
          <Typography variant="h3">Application in Progress</Typography>
          {/* <Typography variant="h3">Pending :</Typography> */}
        </>
      )}
    </Box>
  );
};
