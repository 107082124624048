import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import React from "react";
import InputForm from "../../../Components/Common/Form/InputForm";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";

const stateForm = {
  firstChoice: {
    name: "firstChoice",
    type: "text",
    label: "First Choice",
    value: "",
    required: true,
  },
  secondChoice: {
    name: "secondChoice",
    type: "text",
    label: "Second Choice",
    value: "",
    required: true,
  },
  thirdChoice: {
    name: "thirdChoice",
    type: "text",
    label: "Third Choice",
    value: "",
    required: true,
  },
};

const State = ({ setModal, goBack, control, handleSubmit, nextStep, setStudentData, isEdit, existingStudentData: sData }) => {
  const [listOfCountries, setListOfCountries] = useState([]);

  const handleCancel = () => {
    setModal(false);
  };
  const methods = useForm({
    defaultValues: {
      firstChoice: "",
      secondChoice: "",
      thirdChoice: "",
    },
  });

  const onSubmit = (data) => {
    setStudentData((prevData) => [...prevData, data]);
    nextStep();
  };

  useEffect(() => {
    const headers = new Headers();
    headers.append("X-CSCAPI-KEY", "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg==");

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((data) =>
        setListOfCountries(
          data.map((item) => ({
            label: item.name,
            value: item.name,
            level: item.id,
          })),
        ),
      )
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <Box my={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
          <Controller
            defaultValue={isEdit ? sData?.registrationData?.firstChoice : ""}
            control={control}
            name="firstChoice"
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={listOfCountries}
                onChange={(_, data) => onChange(data)}
                renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="First Choice" />}
                {...field}
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
          <Controller
            defaultValue={isEdit ? sData?.registrationData?.secondChoice : ""}
            control={control}
            name="secondChoice"
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={listOfCountries}
                onChange={(_, data) => onChange(data)}
                renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Second Choice" />}
                {...field}
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
          <Controller
            defaultValue={isEdit ? sData?.registrationData?.thirdChoice : ""}
            control={control}
            name="thirdChoice"
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={listOfCountries}
                onChange={(_, data) => onChange(data)}
                renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Third Choice" />}
                {...field}
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
          <Box
            mt={2}
            sx={{
              width: "70%",
              position: "absolute",
              height: "7%",
              bottom: "3%",
              right: "3%",
            }}
          >
            <Stack spacing={2} direction="row" sx={{display: "flex", justifyContent: "end"}}>
              <Button variant="outlined" onClick={goBack}>
                Back
              </Button>
              <Button variant="contained" type="submit">
                Next
              </Button>
               <Button variant="outlined" color="error" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </FormProvider>

      {/* <InputForm
        config={{
          noPaper: true,
          saveText: "Next",
          cancelButton: true,
          backButton: true,
          headerValue: "State / Region Preferred",
        }}
        handleSave={nextStep}
        handleClose={handleCancel}
        handleBack={backStep}
        formFieldSchema={stateForm}
      /> */}
    </Box>
  );
};

export default State;
