import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Box,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import EscalateIcon from "../../Images/EscalateIcon";
import IconSquareRotated from "../../Images/IconSquareRotated";
import IconStar from "../../Images/IconStar";
import VisibilityIcon from "@mui/icons-material/Visibility";

const VisaCard = ({ visas, setStdUpdate, getVisas, updateVisa }) => {
  const data = visas;
  // console.log("data visa", data);

  const [StatusProcessModal, setStatusProcessModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setStatusProcessModal(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // For InProcess Status
  const handleProcess = async () => {
    await updateVisa({ isProcess: true, isClosed: false }, data?._id);
    getVisas();
    setStatusProcessModal(false);
    setStdUpdate(true);
  };
  // For Closed Status
  const handleClose = async () => {
    await updateVisa({ isClosed: true, isProcess: false }, data?._id);
    getVisas();
    setStatusProcessModal(false);
    setStdUpdate(true);
  };

  // Modal For Processing Status
  const StatusModalOpen = () => setStatusProcessModal(true);

  const handleFavorite = async () => {
    await updateVisa({ isFavorite: !data.isFavorite }, data?._id);
    setStdUpdate(true);
    getVisas();
  };

  if (visas?.visas?.length === 0) {
    return (
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          mt: 20,
          color: "#1968AD",
          fontWeight: "bold",
        }}
      >
        No Visa Applications Available at This Time
      </Typography>
    );
  }

  return (
    <>
      <Card
        sx={{
          width: 316,
          overflow: "initial",
          height: 290,
          bgcolor:
            data?.isProcess && data?.stage < 8 && !data?.isClosed
              ? "#EAF1FF"
              : data?.isClosed
              ? "#FFF9ED"
              : data?.stage >= 8
              ? "#67d58363"
              : "white",

          boxShadow: `0px 2px 3px  ${
            data?.isProcess && data?.stage < 8 && !data?.isClosed
              ? "#4361ee"
              : data?.isClosed
              ? "#E2A03F"
              : data?.stage >= 8
              ? "#118409de"
              : "Grey"
          }`,

          [`@media (max-width: 455px)`]: {
            width: 300,
          },

          [`@media (max-width: 390px)`]: {
            width: 270,
          },
        }}
      >
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "start", alignItems: "start  ", gap: "5px" }}>
            <Avatar
              sx={{
                width: 35,
                height: 35,

                overflow: "hidden",
              }}
              src=""
            />
            <Box>
              <Typography variant="p" component="div" sx={{ textAlign: "start", fontSize: 14, fontWeight: "600", color: "black" }}>
                {data?.studentName}
              </Typography>
              <Typography variant="subtitle2" component="div" sx={{ fontSize: "12px", color: "gray" }}>
                Reg #: {data?.applicationId}
              </Typography>
            </Box>
          </Box>

          {/* </Stack> */}

          <Stack mt={2} direction="row" justifyContent="space-between" alignItems="center" color="gray">
            <Typography variant="subtitle1" color="black" sx={{ fontWeight: "400" }}>
              Counselor(s)
            </Typography>
            <span>{data?.counselor?.name}</span>
          </Stack>

          <Stack my={1} direction="row" justifyContent="space-between" alignItems="center" color="gray">
            <Typography variant="subtitle1" color="black" sx={{ fontWeight: "400" }}>
              Branch{" "}
            </Typography>
            <span style={{ color: "gray", fontSize: "0.8rem" }}>{data?.branch}</span>
          </Stack>

          <Stack my={1} direction="row" justifyContent="space-between" alignItems="center" color="gray">
            <Typography variant="subtitle1" color="black" sx={{ fontWeight: "400" }}>
              Visa Officer
            </Typography>
            <span>{data?.visaOfficer?.name}</span>
          </Stack>
          <Stack my={1} direction="row" justifyContent="space-between" alignItems="center" color="gray">
            <Typography variant="subtitle1" color="black" sx={{ fontWeight: "200" }}>
              Stage
            </Typography>
            <span style={{fontSize:'15px'}}>
              {data?.stage === 5
                ? "Document Check List"
                : data?.stage === 6
                ? "Meeting Schedule"
                : data?.stage === 7
                ? "Document Verification Proof"
                : data?.stage === 8
                ? "2nd Verification Proof"
                : data?.stage > 8
                ? "Visa Process Completed"
                : "Under Process"}
            </span>
              
          </Stack>

          <Stack mt={1} spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" color="black" sx={{ fontWeight: "400" }}>
                Escalate
              </Typography>
              <Box>
                {data?.appPackStats?.escalatedValue === "Low Priorty" ? (
                  <EscalateIcon color={"blue"} />
                ) : data?.appPackStats?.escalatedValue === "High Priority" ? (
                  <EscalateIcon color={"red"} />
                ) : data?.appPackStats?.escalatedValue === "Medium Priority" ? (
                  <EscalateIcon color={"green"} />
                ) : null}
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Tooltip
                  title={
                    data?.isProcess && data?.stage < 8 && !data?.isClosed
                      ? "In Processing"
                      : data?.isClosed === true
                      ? "Processing Closed"
                      : data?.stage >= 8
                      ? "Visa Process Completed"
                      : "Not Started"
                  }
                  placement="top"
                >
                  <button
                    onClick={(event) => {
                      if (!data?.moveToVisa && event) {
                        setAnchorEl(event.currentTarget);
                        StatusModalOpen((prev) => !prev);
                      }
                    }}
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    <IconSquareRotated
                      fill={
                        data?.isProcess && data?.stage < 8 && !data?.isClosed
                          ? "#4361ee"
                          : data?.isClosed === true
                          ? "#E2A03F"
                          : data?.stage >= 8
                          ? "#118409de"
                          : "Grey"
                      }
                    />
                  </button>
                </Tooltip>

                <Popper open={StatusProcessModal} anchorEl={anchorEl} placement="bottom-start">
                  <Paper ref={dropdownRef} sx={{ p: 1, width: 180 }}>
                    <MenuList>
                      <MenuItem onClick={handleProcess} style={{ gap: "5px" }}>
                        <IconSquareRotated fill="#4361ee" />
                        In Processing
                      </MenuItem>
                      <MenuItem onClick={handleClose} style={{ gap: "5px" }}>
                        <IconSquareRotated fill="#E2A03F" />
                        Closed
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Popper>
              </Stack>

              <Stack direction="row" justifyContent="space-center" alignItems="center" color={"gray"} gap={1}>
                <button onClick={handleFavorite} style={{ border: "none", backgroundColor: "transparent" }}>
                  <IconStar fill={data?.isFavorite ? "#E2A040" : ""} />
                </button>

                <Link to={`/visa/${data?._id}`} style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                  <Box
                    sx={{
                      paddingTop: "6px",
                    }}
                  >
                    <VisibilityIcon />
                  </Box>
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default VisaCard;
