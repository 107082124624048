import VisaCard from "./VisaCard";
import { Autocomplete, Box, Divider, IconButton, List, MenuItem, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { CRUDFunction } from "../../reduxCURD/container";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import ArrowLeft from "../../Images/ArrowLeft";
import ArrowRight from "../../Images/ArrowRight";
import IconNotesEdit from "../../Images/IconNotesEdit";
import IconSquareRotated from "../../Images/IconSquareRotated";
import { branchesOptions } from "../../Data/BranchesOptions";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import IconStar from "../../Images/IconStar";
import { GridMenuIcon } from "@mui/x-data-grid";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const Visa = (props) => {
  console.log("props", props);
  const [sidebarOpen, setSidebarOpen] = useState(false); // Sidebar open by default on large screens
  const [currentPage, setCurrentPage] = useState(1);
  const [branchNames, setBranchNames] = useState(branchesOptions[0]);
  const [firstRender, setFirstRender] = useState(true);
  const [stdUpdate, setStdUpdate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [counselorFilter, setCounselorFilter] = useState("");
  const [registerationId, setRegisterationId] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(props?.visas);
  const [showsearchQuery, setshowSearchQuery] = useState(false);

  const itemsPerPage = 12;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const getStudents = async () => {
    await props.getStudents();
  };

  const handleBranchChange = (newValue) => {
    const newBranch = branchesOptions.find((branch) => branch.text === newValue.target.textContent);
    setBranchNames(newBranch);
  };

  const isMobile = useMediaQuery("(max-width:1190px)");

  useEffect(() => {
    getStudents();
  }, []);

  const handleSearchChange = (value) => {
    if (value) {
      const fullName = `${value.studentName || ""}`.toLocaleLowerCase().trim();
      setSearchQuery(fullName);
    } else {
      setSearchQuery("");
    }
  };
  const uniqueCounselors = [...new Set(props?.visas.map((student) => student.counselor?.name).filter((name) => name))];
  const handleCounselorChange = (value) => {
    if (value) {
      setCounselorFilter(value);
    } else {
      setCounselorFilter("");
    }
  };

  const handleRegisterationChange = (e) => {
    setRegisterationId(e.target.value);
  };

  const handleSearch = () => {
    const newFilteredStudents = props?.visas.filter((student) => {
      const fullName = `${student?.studentName || ""}`.toLowerCase();
      const counselorName = `${student?.counselor?.name || ""}`.toLowerCase();
      const branchName = `${student.branch?.text || student.branch || ""}`.toLowerCase();
      const registerationID = `${student?.applicationId || ""}`;

      return (
        (searchQuery === "" || fullName.includes(searchQuery.toLowerCase())) &&
        (counselorFilter === "" || counselorName.includes(counselorFilter.toLowerCase())) &&
        (branchNames.value === "" || branchName.includes(branchNames.value.toLowerCase())) &&
        (registerationId === "" || registerationID?.includes(registerationId))
      );
    });

    setFilteredStudents(newFilteredStudents);
  };

  const handleSearchClick = () => {
    if (searchQuery === "" && counselorFilter === "" && branchNames === "" && registerationId === "") {
      // If all search fields are empty, fetch all students
      setFilteredStudents(props?.visas);
      setCurrentPage(1);
      setshowSearchQuery(true);
    } else {
      // Perform the regular search
      handleSearch();
    }
    if (isMobile) setSidebarOpen(false);
  };

  const getVisas = async () => {
    props.getVisas();
  };

  useEffect(() => {
    getVisas();
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (props?.visas.length === 0) return;

    if (firstRender) {
      setFilteredStudents(props?.visas);
      setFirstRender(false);
    }
    // Update only if students data has changed
    else if (JSON.stringify(props?.visas) !== JSON.stringify(filteredStudents) && stdUpdate) {
      setFilteredStudents(props?.visas);
      setStdUpdate(false);
    }
  }, [props?.visas]);
  const displayedStudents = filteredStudents.slice(startIndex, endIndex);
  return (
    <Box
      width="100%"
      sx={{
        pl: 2,
        pr: 1,
        py: 12,
      }}
    >
      <Box width="100%" sx={{ display: "flex", gap: "3.5rem 1.5rem ", position: "relative" }}>
        {sidebarOpen && (
          <Box sx={{ backgroundColor: "white", borderRadius: "10px", padding: "1rem", marginBottom: "1rem" }}>
            <List
              sx={{
                backgroundColor: "white",
                position: isMobile ? "absolute" : "relative",
                zIndex: 10,
              }}
            >
              <MenuItem
                onClick={() => {
                  setFilteredStudents(props?.visas);
                  setshowSearchQuery(false);
                  if (isMobile) setSidebarOpen(false);
                }}
                sx={{
                  gap: "1rem",
                  ":hover": {
                    backgroundColor: "rgba(203, 208, 212, 0.27)",
                    color: "#4361ee",
                  },
                  borderRadius: "5px",
                }}
              >
                <IconNotesEdit />
                All Students
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setFilteredStudents(props?.visas.filter((student) => student?.isFavorite));
                  setshowSearchQuery(false);
                  if (isMobile) setSidebarOpen(false);
                }}
                sx={{
                  gap: "1rem",
                  ":hover": {
                    backgroundColor: "rgba(203, 208, 212, 0.27)",
                    color: "#4361ee",
                  },
                  borderRadius: "5px",
                }}
              >
                <IconStar fill="" />
                Favorites
              </MenuItem>

              {/*  status */}

              <Box sx={{ marginTop: "1rem", marginBottom: "5px" }}>
                <Divider />
              </Box>
              <List
                sx={{
                  color: "gray",
                }}
              >
                {" "}
                Status
              </List>
              <MenuItem
                onClick={() => {
                  setFilteredStudents(props?.visas.filter((student) => student?.isProcess && student?.stage < 8 && !student?.isClosed));
                  if (isMobile) setSidebarOpen(false);
                  setshowSearchQuery(false);
                }}
                sx={{
                  gap: "1rem",
                  color: "#4361ee",
                  transition: "all 300ms ease-in-out",
                  ":hover": {
                    marginLeft: "0.75rem",
                    backgroundColor: "rgba(203, 208, 212, 0.27)",
                  },
                  borderRadius: "5px",
                }}
              >
                <IconSquareRotated fill="#4361ee" className="shrink-0" />
                {/* In Progress */}
                In Progress
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilteredStudents(props?.visas.filter((student) => student?.isClosed === true));
                  setshowSearchQuery(false);
                  if (isMobile) setSidebarOpen(false);
                }}
                sx={{
                  gap: "1rem",
                  color: "#E2A03F",
                  transition: "all 300ms ease-in-out",
                  ":hover": {
                    marginLeft: "0.75rem",
                    backgroundColor: "rgba(203, 208, 212, 0.27)",
                  },
                  borderRadius: "5px",
                }}
              >
                <IconSquareRotated fill="#E2A03F" className="shrink-0" />
                Closed
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilteredStudents(props?.visas.filter((student) => student?.stage >= 8 && !student?.isClosed));
                  setshowSearchQuery(false);
                  if (isMobile) setSidebarOpen(false);
                }}
                sx={{
                  gap: "0.75rem",
                  color: "#118409de",
                  transition: "all 300ms ease-in-out",
                  borderRadius: "5px",
                  ":hover": {
                    marginLeft: "0.75rem",
                    backgroundColor: "rgba(203, 208, 212, 0.27)",
                  },
                }}
              >
                <IconSquareRotated fill="#118409de" className="shrink-0" />
                {/* Visa Process Completed */}
                Visa Process Completed
              </MenuItem>

              <Box sx={{ marginY: "10px" }}>
                <Divider />
              </Box>
              <List
                sx={{
                  color: "gray",
                }}
              >
                {" "}
                Filters
              </List>

              {/*  search by name dropdown */}
              <MenuItem sx={{ ":hover": { backgroundColor: "transparent" } }}>
                <Autocomplete
                  sx={{
                    width: "230px",
                    "@media (max-width: 1024px)": {
                      width: "250px",
                    },
                  }}
                  value={props?.visas.find((student) => student?.studentName.trim() === searchQuery) || null}
                  onChange={(event, value) => handleSearchChange(value)}
                  options={props?.visas}
                  getOptionLabel={(option) => option.studentName}
                  renderInput={(params) => <TextField {...params} label="Search by Name" />}
                />
              </MenuItem>

              <MenuItem sx={{ ":hover": { backgroundColor: "transparent" } }}>
                <TextField
                  id="outlined-basic"
                  label="Registration ID"
                  variant="outlined"
                  value={registerationId}
                  onChange={handleRegisterationChange}
                  sx={{
                    width: "230px",
                    "@media (max-width: 1024px)": {
                      width: "250px",
                    },
                  }}
                />
              </MenuItem>

              <MenuItem sx={{ ":hover": { backgroundColor: "transparent" } }}>
                <Autocomplete
                  sx={{
                    width: "230px",
                    "@media (max-width: 1024px)": {
                      width: "250px",
                    },
                  }}
                  value={uniqueCounselors.find((counselor) => counselor === counselorFilter) || null}
                  onChange={(event, value) => handleCounselorChange(value)}
                  options={uniqueCounselors}
                  renderInput={(params) => <TextField {...params} label="Counsellor" />}
                />
              </MenuItem>

              <MenuItem sx={{ ":hover": { backgroundColor: "transparent" } }}>
                <Autocomplete
                  sx={{
                    width: "230px",
                    "@media (max-width: 1024px)": {
                      width: "250px",
                    },
                  }}
                  value={branchNames}
                  onChange={handleBranchChange}
                  options={branchesOptions}
                  getOptionLabel={(option) => option.text}
                  renderInput={(params) => <TextField {...params} label="Branch" />}
                />
              </MenuItem>
              <MenuItem sx={{ ":hover": { backgroundColor: "transparent" } }}>
                <Button
                  variant="contained"
                  onClick={handleSearchClick}
                  sx={{
                    height: "40px",
                    width: "100%",
                    borderRadius: "6px",
                  }}
                >
                  <PersonSearchIcon />
                  Search
                </Button>
              </MenuItem>
            </List>
          </Box>
        )}

        {isMobile && (
          <IconButton
            sx={{
              height: 2,
              position: "absolute",
              top: !sidebarOpen ? "1rem" : "5px",

              zIndex: 10,
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            {!sidebarOpen ? (
              <GridMenuIcon titleAccess="Open Sidebar" />
            ) : (
              <CloseOutlinedIcon sx={{ color: "red" }} titleAccess="Close Sidebar" />
            )}
          </IconButton>
        )}

        <div
          style={{
            backgroundColor: "white",
            borderRadius: "1rem",

            padding: isMobile ? "40px 5px" : "20px 5px",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",

            justifyContent: "space-evenly",
            position: "relative",
            rowGap: "10px",
          }}
        >
          <>
            {displayedStudents.map((item, id) => {
              return (
                <VisaCard key={item._id} visas={item} setStdUpdate={setStdUpdate} getVisas={getVisas} updateVisa={props?.updateVisa} />
              );
            })}
          </>
        </div>
      </Box>

      {!showsearchQuery && props?.visas?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2.5rem",
            [`@media (max-width: 459px)`]: {
              flexDirection: "column",
            },
          }}
        >
          <Button
            variant="contained"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            sx={{
              marginRight: {
                xs: "0",
                md: "1rem",
                [`@media (max-width: 459px)`]: {
                  marginBottom: "1rem",
                },
              },
              width: "170px",
            }}
            startIcon={<ArrowLeft />}
          >
            Previous Page
          </Button>
          <Button
            variant="contained"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={endIndex >= props?.visas.length}
            sx={{
              width: "170px",
            }}
            endIcon={<ArrowRight />}
          >
            Next Page
          </Button>
        </Box>
      )}
    </Box>
  );
};

let actionOptions = {
  create: true,
  read: true,
  update: true,
  delete: true,
};

const visaContainer = CRUDFunction(Visa, "visa", actionOptions, null, null, "visa");
export default visaContainer;
