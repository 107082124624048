import { Autocomplete, Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import InputForm from "../../../Components/Common/Form/InputForm";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { useState } from "react";
import {
  repeaterDefaultValueIndex,
  repeaterLoader,
  studentRepeaterLoaderWithFieldIndexes,
} from "../../StudentProfile/ApplicationForm/Forms/repeaterLoader";

const EntranceTest = ({
  setModal,
  eControl,
  handleSubmit,
  goBack,
  nextStep,
  setStudentData,
  setRegistrationFormFiles,
  isEdit,
  existingStudentData: sData,
}) => {
  const handleCancel = () => {
    setModal(false);
  };
  // const [file, setFile] = useState("");
  const [showOtherField, setShowOtherField] = useState("");

  const onSubmit = (data) => {
    let changes = [];

    // if (file) {
    fields.forEach((_, index) => {
      const fieldName = `entranceTest.${index}.entranceTestUploadResult`;
      const fileData = { [fieldName]: _.entranceTestUploadResult };
      changes.push(fileData);
    });
    // }
    setStudentData((prevData) => [...prevData, data, ...changes]);

    nextStep();
  };

  // const {
  //   // control,
  //   // handleSubmit,
  //   formState: { errors },
  //   control,
  //   // setValue,
  // } = useForm(
  //   isEdit
  //     ? {
  //         defaultValues: {
  //           entranceTest: repeaterLoader(!isEdit, "entranceTest", sData, {
  //             entranceTest: "",
  //             entranceTestScore: "",
  //             entranceTestUploadResult: "",
  //             entranceTestDateOfTest: "",
  //             otherEntranceTest: "",
  //           }),
  //         },
  //       }
  //     : {
  //         defaultValues: {
  //           entranceTest: [
  //             {
  //               entranceTest: "",
  //               entranceTestScore: "",
  //               entranceTestUploadResult: "",
  //               entranceTestDateOfTest: "",
  //               otherEntranceTest: "",
  //             },
  //           ],
  //         },
  //       },
  // );

  let entranceTestObj = {
    entranceTest: "",
    entranceTestScore: "",
    entranceTestUploadResult: "",
    entranceTestDateOfTest: "",
    otherEntranceTest: "",
  };
  const defaultValues = {
    entranceTest: isEdit
      ? studentRepeaterLoaderWithFieldIndexes(!isEdit, "entranceTest", sData, null, { ...entranceTestObj }, "entranceTestUploadResult")
      : [{ ...entranceTestObj }],
  };

  const {
    formState: { errors },
    control,
  } = useForm({ defaultValues: defaultValues });

  const entranceTestUploadResult = (e, fieldIndex) => {
    update(fieldIndex, { ...fields[fieldIndex], entranceTestUploadResult: e.target.files[0].name });
    setRegistrationFormFiles((prevData) => {
      return { ...prevData, [e.target.name]: e.target.files[0] };
    });
  };
  const { fields, append, update } = useFieldArray({
    control,
    name: "entranceTest",
  });

  const addOtherField = (data) => {
    if (data?.label === "Other") {
      setShowOtherField(data?.label);
    } else {
      setShowOtherField("");
    }
  };

  const entranceTestOptions = [
    { label: "SAT", level: 1 },
    { label: "SAT I", level: 2 },
    { label: "GRE General", level: 3 },
    { label: "GRE Subjective", level: 4 },
    { label: "GMAT", level: 5 },
    { label: "LNAT", level: 6 },
    { label: "Other", level: 7 },
  ];

  // useEffect(() => {
  //   // Initialize form values with existing data when in edit mode
  //   if (!isEdit) {
  //     sData?.registrationData?.entranceTest?.forEach((existingData, index) => {
  //       Object.keys(existingData).forEach((key) => {
  //         setValue(`entranceTest.${index}.${key}`, existingData[key]);
  //       });
  //     });
  //   } else {
  //     // If creating a new student, append fields based on existing data
  //     sData?.registrationData?.entranceTest?.forEach((existingData, index) => {
  //       // Check if there are more default fields than existing data, and append if needed
  //       if (index >= fields.length) {
  //         append({
  //           entranceTest: "",
  //           entranceTestScore: "",
  //           entranceTestUploadResult: "",
  //           entranceTestDateOfTest: "",
  //           otherEntranceTest: "",
  //         });
  //       }

  //       Object.keys(existingData).forEach((key) => {
  //         setValue(`entranceTest.${index}.${key}`, existingData[key]);
  //       });
  //     });
  //   }
  // }, [isEdit, fields, setValue, sData.registrationData, append]);

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
        <Box>
          <Stack>
            {fields.map((field, index) => {
              return (
                <Stack key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0", mb: "25px" }} spacing={2}>
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "entranceTest", "entranceTest", index, sData) : ""}
                    control={eControl}
                    name={`entranceTest.${index}.entranceTest`}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={entranceTestOptions}
                        onChange={(_, data) => (onChange(data?.label), addOtherField(data))}
                        renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Entrance Test" />}
                        {...field}
                      />
                    )}
                  />
                  {showOtherField === "Other" && (
                    <Controller
                      defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "entranceTest", "otherEntranceTest", index, sData) : ""}
                      control={eControl}
                      name={`entranceTest.${index}.otherEntranceTest`}
                      render={({ field }) => (
                        <TextField
                          id="outlined-basic"
                          type="text"
                          label="Other Entrance Test"
                          variant="outlined"
                          {...field}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  )}
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "entranceTest", "entranceTestScore", index, sData) : ""}
                    control={eControl}
                    name={`entranceTest.${index}.entranceTestScore`}
                    render={({ field }) => <TextField label="Score" id="outlined-basic" type="text" variant="outlined" {...field} />}
                  />
                  {/* <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "entranceTest", "entranceTest", index, sData) : ""}
                    control={eControl}
                    name={`entranceTest.${index}.entranceTestUploadResult`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.entranceTestUploadResult)}
                        helperText="Upload Result"
                        id="outlined-basic"
                        type="file"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  /> */}
                  {/* <TextField
                    type="file"
                    name={`entranceTest.${index}.entranceTestUploadResult`}
                    variant="outlined"
                    onChange={entranceTestUploadResult}
                  /> */}

                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ pb: 1 }}>Upload Result</Typography>
                    <Button
                      sx={{
                        zIndex: 2,
                        py: 1,
                        width: "100%",
                      }}
                      component="label"
                      variant="outlined"
                      color="inherit"
                    >
                      {field.uploadTestResult || sData?.registrationData[`entranceTest.${index}.entranceTestUploadResult`] || "Upload File"}
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          entranceTestUploadResult(e, index);
                        }}
                      />
                    </Button>
                  </Box>
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "entranceTest", "entranceTestDateOfTest", index, sData) : ""}
                    control={eControl}
                    name={`entranceTest.${index}.entranceTestDateOfTest`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.entranceTestDateOfTest)}
                        helperText="Date Of Test"
                        id="outlined-basic"
                        type="date"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  />
                </Stack>
              );
            })}
          </Stack>
          <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              onClick={() =>
                append({
                  entranceTest: "",
                  entranceTestScore: "",
                  entranceTestUploadResult: "",
                  entranceTestDateOfTest: "",
                  otherEntranceTest: "",
                })
              }
            >
              Add More
            </Button>
          </Box>
        </Box>
        <Box
          mt={3}
          sx={{display: "flex", justifyContent: "end"}}
        >
          <Stack spacing={2} direction="row" >
            <Button variant="outlined" onClick={goBack}>
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
             <Button variant="outlined" color="error" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export default EntranceTest;
