import { Autocomplete, Box, Button, Stack, TextField } from '@mui/material'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { getLoggedInUser } from '../../../utils/loggedInUser';

const StudentAssign = ({
  setModal,
  goBack,
  nextStep,
  setStudentData,
  control,
  handleSubmit,
  proceedData: sData,
  users
}) => {

  console.log('sData', sData);
  //{ id: user._id, name: user.name, email: user.email },
  const userLogin = getLoggedInUser();

   const methods = useForm({
      defaultValues: {
        counselor: {
          id: sData?.enquiryData?.counselor?.id || "",
          name: sData?.enquiryData?.counselor?.name || "",
          label: sData?.enquiryData?.counselor?.name || "",
          email: sData?.enquiryData?.counselor?.email || "",
        }
      },
    });
    const assignToOptions = users.filter((user)=> user.role === "Counselor").map((user) => ({
      id: user._id,
      name: user.name,
      label: user.name,
      email: user.email,
    }));

  const handleCancel = () => {
    setModal(false);
  };
  const onSubmit = (data) => {
    const allData = { ...data , branch: userLogin?.branch}
    
    setStudentData((prevData) => [...prevData, allData]);
    nextStep();
  };

  return (
    <Box my={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
          <Stack spacing={2}>
            <Controller
              control={control}
              defaultValue={ sData?.enquiryData?.counselor }
              name="counselor"
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                    options={[
                {
                  label: 'Self Assign',  
                  name: 'Self Assign',
                  id: userLogin._id,
                  email: userLogin.email,
                },
                ...assignToOptions
                  ]}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Assign Counselor" />}
                  {...field}
                />
              )}
            />
           
      
          </Stack>
          <Box
            mt={2}
            sx={{
              width: "70%",
              position: "absolute",
              height: "7%",
              bottom: "3%",
              right: "3%",
            }}
          >
            <Stack spacing={2} direction="row"  sx={{display: "flex", justifyContent: "end"}}>
              <Button variant="outlined" onClick={goBack}>
                Back
              </Button>
              <Button variant="contained" type="submit">
                Next
              </Button>
               <Button variant="outlined" color="error" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}

export default StudentAssign
