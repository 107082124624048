import styled from "@emotion/styled";
import { CloudUpload } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadExcelSheet from "./loadLeadExcelSheet";
import EnhancedTable from "./EnhancedTable";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const FileModel = ({ createLead, dataAllLeads, openModal, handleCloseModal, users }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [bool, setBool] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  //   console.log("tableData", tableData);

  const DocumentUploadBox = styled(Box)(({ theme }) => ({
    border: `2px dashed ${theme.palette.primary.light}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(8),
    textAlign: "center",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.primary.main,
    },
  }));

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    if (selectedFile || bool) {
      LoadExcelSheet({
        createLead,
        dataAllLeads,
        file: selectedFile,
        bool,
        setTableData,
        users,
        handleLeadsCreated
      });
    }
  }, [selectedFile, bool]);

  //   console.log("selectedFile", selectedFile);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // File type validation
    const allowedTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    if (file) {
      if (!allowedTypes.includes(file.type)) {
        setError("Invalid file type. Please upload a valid xlsx file.");
        return;
      }
      setSelectedFile(file);
      setError(null);
    }
  };

  const handleCreateLeads = () => {
    setBool(true);
    setLoading(true);
  };

  // remove leads from table
  const handleLeadsRemove = () => {
    setTableData([]);
    setSelectedFile(null);
    setBool(false);
  };

  const handleLeadsCreated = () => {
    setTableData([]);
    setSelectedFile(null);
    setLoading(false);
    setBool(false);
    handleCloseModal();
  };

  return (
    <>
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth={tableData?.length > 0 ? "xl" : "md"} fullWidth>
        <DialogContent>
          {error && (
            <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <DialogTitle>
              {!tableData.length > 0 ? "Upload CSV Files" : "Leads Data"}
              {tableData.length > 0 && (
                <Box sx={{ mt: 1, fontSize: "0.875rem", color: "gray" }}>
                  The following rows meet the required criteria. Duplicate records have been automatically removed.
                </Box>
              )}
            </DialogTitle>

            <Box sx={{ display: "flex", gap: 2 }}>
              <Button disabled={!tableData.length > 0} variant="contained" onClick={handleCreateLeads}>
                <ModeEditOutlinedIcon /> Create Leads
              </Button>
              <Button disabled={!tableData.length > 0} variant="outlined" color="error" onClick={handleLeadsRemove}>
                <DeleteOutlinedIcon /> Remove Leads
              </Button>
            </Box>
          </Box>
          {!tableData.length > 0 ? (
            <Grid item xs={12}>
              <DocumentUploadBox>
                <Button component="label" variant="outlined" startIcon={<CloudUpload />} color="primary" sx={{ mb: 2 }}>
                  Choose File
                  <VisuallyHiddenInput type="file" accept=".xlsx" onChange={handleFileChange} />
                </Button>
                <Typography variant="body2" color="textSecondary">
                  {selectedFile ? `📂 Selected File: ${selectedFile.name}` : "📂 Only XLSX files are allowed"}
                  {selectedFile && (
                    <Box sx={{ mt: 1, fontSize: "0.875rem", color: "red", fontWeight: "500" }}>
                      The uploaded file contains invalid data. Please verify the file and ensure the data is in the correct format before
                      trying again.
                    </Box>
                  )}
                </Typography>
              </DocumentUploadBox>
            </Grid>
          ) : (
            <EnhancedTable tabalData={tableData} />
          )}
        </DialogContent>
      </Dialog>

      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <Box
            sx={{
              color: "#fff",
              fontSize: "25px",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              // gap: 2,
            }}
          >
            <iframe
              style={{ border: "none" }}
              src="https://lottie.host/embed/fbfc80ad-f425-4a54-ab4f-85e309ab5bdb/1rEnZkvkkp.lottie"
            ></iframe>
            {/* <CircularProgress size={70} thickness={4} color="success" /> */}
            Generating Leads....
          </Box>
        </Box>
      )}
    </>
  );
};

export default FileModel;
