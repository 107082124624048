import * as XLSX from "xlsx";
import { addIfNotExist } from "../../../BussinessLogic/Leads/addIfNotExist";
import { dialingCodeOptions } from "../../../Data/DialingCodes";
import { namePrefixOptions } from "../../../Data/NamePrefix";
async function loadLeadExcelSeed({ createLead, dataAllLeads, file, bool, setTableData, users, handleLeadsCreated }) {
  // console.log("loadLeadExcelSeed called", dataAllLeads);
  // console.log('users', users);
  console.log("file", file);
  if (!file) return;
  console.log("Parsing Lead Excel file and populating database...");

  const reader = new FileReader();

  reader.onload = async (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: "array", dateNF: "yyyy-mm-dd", cellDates: true });

    let first_ws = workbook.Sheets[workbook.SheetNames[0]];
    let resultData = XLSX.utils.sheet_to_json(first_ws);
    // console.log("resultData", resultData);

    if (!resultData.length) return;

    const branchesOptions = [
      { text: "All", value: "", level: 0 },
      { text: "SFK", value: "SFK", level: 1 },
      { text: "SFL", value: "SFL", level: 2 },
      { text: "SFI", value: "SFI", level: 3 },
      { text: "SFS", value: "SFS", level: 4 },
      { text: "SFH", value: "SFH", level: 5 },
      { text: "SFIR", value: "SFIR", level: 6 },
      { text: "SFC", value: "SFC", level: 7 },
    ];

    let newTableData = [];
    // console.log("newTableData", newTableData);

    // Use a Set to track unique phone numbers for faster filtering
    const uniqueNumbers = new Set();

    for (let row of resultData) {
      const foundUser = users.find((user) => user.email.toLowerCase().trim() === row?.["Owner Email"]?.toLowerCase().trim());

      const dialingCodeNo = dialingCodeOptions.find(
        (option) => option.label.toLowerCase().trim() === row?.["Dial Code"]?.toLowerCase().trim(),
      );

      const namePrefixNo = namePrefixOptions.find(
        (option) => option.label.toLowerCase().trim() === row?.["Name Prefix"]?.toLowerCase().trim(),
      );

      const branchName = branchesOptions.find((branch) => branch.text === row?.["Branch Name"]?.toUpperCase().trim());

      const formatDate = (date) => {
        if (!date || !(date instanceof Date) || isNaN(date)) return "";
        return date.toISOString().split("T")[0];
      };

      const formatDob = formatDate(row?.["D.O.B"]);

      let leadObj = {
        namePrefix: namePrefixNo || {},
        firstName: row?.["First Name"] || "",
        middleName: row?.["Middle Name"] || "",
        lastName: row?.["Last Name"] || "",
        infoEmail: row?.["Email"] || "",
        cellNo: row?.["Contact No"] || "",
        homeNo: row?.["House No"] || "",
        dob: formatDob || "",
        dialingCode: dialingCodeNo || {},
        education: row?.["Educational Details"] || "",
        branchName: branchName || {},
        instituteName: row?.["School Or College Name"] || "",
        source: "manual",
        purpose: "Created",
        assignTo: [
          {
            id: row?.["Owner ID"] || foundUser?._id || "",
            name: row?.["Owner Name"] || foundUser?.name || "",
            email: row?.["Owner Email"] || "",
          },
        ],
      };

      if (bool) {
        const alreadyExists = dataAllLeads.find((item) => item.manualForm.cellNo === leadObj.cellNo);
        // console.log("newItem load Excel Sheet", newItem);

        if (alreadyExists) {
          console.log("Lead already exists in the database", leadObj.cellNo);
        } else {
          await addIfNotExist(createLead, leadObj);
        }
        //setLoading when all leads are added
        if (resultData.indexOf(row) === resultData.length - 1) {
          handleLeadsCreated();
        }
      }

      // Only add unique leads based on cellNo
      if (!uniqueNumbers.has(leadObj.cellNo)) {
        uniqueNumbers.add(leadObj.cellNo);
        newTableData.push(leadObj);
      }
    }

    // console.log("Final newTableData before setting state:", newTableData);

    if (!bool) {
      setTableData((prev) => {
        // console.log("Previous Table Data:", prev);
        // console.log("New Table Data Being Added:", newTableData);

        const updatedData = [...prev];

        newTableData.forEach((lead) => {
          if (!updatedData.some((existingLead) => existingLead.cellNo === lead.cellNo)) {
            updatedData.push(lead);
          }
        });

        return updatedData;
      });
    }
  };

  reader.readAsArrayBuffer(file);
}

export default loadLeadExcelSeed;
