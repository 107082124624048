import React from "react";
import { useForm, FormProvider, useFormContext, Controller, useFieldArray } from "react-hook-form";
import { Autocomplete, Box, Stack, Button, Typography, StepLabel, Stepper, TextField } from "@mui/material";

function Employment({ eControl, handleSubmit, goBack, nextStep, handleClose, setEnquiryData, setFormFile }) {
  const onSubmit = (data) => {
    setEnquiryData((prevData) => [...prevData, data]);
    nextStep();
  };
  const {
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      professionalExperience: [
        {
          companyName: "",
          designation: "",
          from: "",
          to: "",
          mainResponsibilities: "",
        },
      ],
    },
  });
  const { fields, append } = useFieldArray({
    control,
    name: "professionalExperience",
  });
  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
        <Box>
          <Stack>
            {fields.map((field, index) => {
              return (
                <Stack key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0" }} spacing={2}>
                  <Stack direction="row">
                    <Controller
                      control={eControl}
                      name={`professionalExperience.${index}.companyName`}
                      render={({ field }) => (
                        <TextField
                          label="Company Name"
                          error={Boolean(errors.companyName)}
                          helperText={errors.companyName?.message}
                          id="outlined-basic"
                          type="text"
                          variant="outlined"
                          {...field}
                          sx={{
                            width: "49.5%",
                            marginRight: "1%",
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={eControl}
                      name={`professionalExperience.${index}.designation`}
                      render={({ field }) => (
                        <TextField
                          label="Designation"
                          error={Boolean(errors.designation)}
                          helperText={errors.designation?.message}
                          id="outlined-basic"
                          type="text"
                          variant="outlined"
                          {...field}
                          sx={{
                            width: "49.5%",
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Box>
                    <Typography>From</Typography>
                    <Controller
                      control={eControl}
                      name={`professionalExperience.${index}.from`}
                      render={({ field }) => (
                        <TextField
                          error={Boolean(errors.from)}
                          helperText={errors.from?.message}
                          id="outlined-basic"
                          type="date"
                          variant="outlined"
                          {...field}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography>To</Typography>
                    <Controller
                      control={eControl}
                      name={`professionalExperience.${index}.to`}
                      render={({ field }) => (
                        <TextField
                          error={Boolean(errors.to)}
                          helperText={errors.to?.message}
                          id="outlined-basic"
                          type="date"
                          variant="outlined"
                          {...field}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </Box>
                  <Controller
                    control={eControl}
                    name={`professionalExperience.${index}.mainResponsibilities`}
                    render={({ field }) => (
                      <TextField
                        label="Main Responsibilities"
                        error={Boolean(errors.mainResponsibilities)}
                        helperText={errors.mainResponsibilities?.message}
                        id="outlined-basic"
                        type="text"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  />
                </Stack>
              );
            })}
          </Stack>
          <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="outlined" onClick={() => append({ companyName: "", designation: "", from: "", mainResponsibilities: "" })}>
              Add More
            </Button>
          </Box>
        </Box>
        <Box
          mt={2}
          sx={{display: "flex",justifyContent: "end", }}>
          <Stack spacing={2} direction="row" sx={{ display: "flex", justifyContent: "end" }}>
            <Button variant="outlined" onClick={goBack}>
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
             <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
}

export default Employment;
