import { Autocomplete, Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputForm from "../../../../Components/Common/Form/InputForm";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { universitySubjects } from "../../../../Data/subjectLists";

const listOfUniversities = [
  { label: "Abilene Christian University", value: "Abilene_Christian_University", level: 1 },
  { label: "Adelphi University", value: "Adelphi_University", level: 2 },
  { label: "Adrian College", value: "Adrian_College", level: 3 },
  { label: "Alabama A&M University", value: "Alabama_A&M_University", level: 4 },
  { label: "Albion College", value: "Albion_College", level: 5 },
  { label: "Albright College", value: "Albright_College", level: 6 },
  { label: "Alfred University", value: "Alfred_University", level: 7 },
  { label: "Allegheny College", value: "Allegheny_College", level: 8 },
  { label: "Alma College", value: "Alma_College", level: 9 },
  { label: "Alvernia University", value: "Alvernia_University", level: 10 },
  { label: "Alverno College", value: "Alverno_College", level: 11 },
  { label: "American University", value: "American_University", level: 12 },
  { label: "Amherst College", value: "Amherst_College", level: 13 },
  { label: "Anderson University (Indiana)", value: "Anderson_University_(Indiana)", level: 14 },
  { label: "Andrews University", value: "Andrews_University", level: 15 },
  { label: "Appalachian State University", value: "Appalachian_State_University", level: 16 },
  { label: "Arcadia University", value: "Arcadia_University", level: 17 },
  { label: "Arizona State University", value: "Arizona_State_University", level: 18 },
  { label: "Arkansas State University", value: "Arkansas_State_University", level: 19 },
  { label: "Arkansas Tech University", value: "Arkansas_Tech_University", level: 20 },
  { label: "Artcenter College Of Design", value: "Artcenter_College_Of_Design", level: 21 },
  { label: "Asbury University", value: "Asbury_University", level: 22 },
  { label: "Ashland University", value: "Ashland_University", level: 23 },
  { label: "Assumption College", value: "Assumption_College", level: 24 },
  { label: "Auburn University", value: "Auburn_University", level: 25 },
  { label: "Augsburg College", value: "Augsburg_College", level: 26 },
  { label: "Augustana College (Illinois)", value: "Augustana_College_(Illinois)", level: 27 },
  { label: "Augustana College (South Dakota)", value: "Augustana_College_(South_Dakota)", level: 28 },
  { label: "Austin College", value: "Austin_College", level: 29 },
  { label: "Avila University", value: "Avila_University", level: 30 },
  { label: "Azusa Pacific University", value: "Azusa_Pacific_University", level: 31 },
  { label: "Babson College", value: "Babson_College", level: 32 },
  { label: "Baldwin Wallace University", value: "Baldwin_Wallace_University", level: 33 },
  { label: "Ball State University", value: "Ball_State_University", level: 34 },
  { label: "Bard College", value: "Bard_College", level: 35 },
  { label: "Barnard College", value: "Barnard_College", level: 36 },
  { label: "Barry University", value: "Barry_University", level: 37 },
  { label: "Bates College", value: "Bates_College", level: 38 },
  { label: "Bay Path University", value: "Bay_Path_University", level: 39 },
  { label: "Baylor University", value: "Baylor_University", level: 40 },
  { label: "Belhaven University", value: "Belhaven_University", level: 41 },
  { label: "Bellarmine University", value: "Bellarmine_University", level: 42 },
  { label: "Belmont University", value: "Belmont_University", level: 43 },
  { label: "Beloit College", value: "Beloit_College", level: 44 },
  { label: "Benedictine College", value: "Benedictine_College", level: 45 },
  { label: "Benedictine University", value: "Benedictine_University", level: 46 },
  { label: "Bentley University", value: "Bentley_University", level: 47 },
  { label: "Berea College", value: "Berea_College", level: 48 },
  { label: "Berklee College Of Music", value: "Berklee_College_Of_Music", level: 49 },
  { label: "Berry College", value: "Berry_College", level: 50 },
  { label: "Bethel College-Indiana", value: "Bethel_College-Indiana", level: 51 },
  { label: "Bethel University", value: "Bethel_University", level: 52 },
  { label: "Biola University", value: "Biola_University", level: 53 },
  { label: "Birmingham-Southern College", value: "Birmingham-Southern_College", level: 54 },
  { label: "Bloomsburg University Of Pennsylvania", value: "Bloomsburg_University_Of_Pennsylvania", level: 55 },
  { label: "Bluffton University", value: "Bluffton_University", level: 56 },
  { label: "Boise State University", value: "Boise_State_University", level: 57 },
  { label: "Boston College", value: "Boston_College", level: 58 },
  { label: "Boston University", value: "Boston_University", level: 59 },
  { label: "Bowdoin College", value: "Bowdoin_College", level: 60 },
  { label: "Bowling Green State University", value: "Bowling_Green_State_University", level: 61 },
  { label: "Bradley University", value: "Bradley_University", level: 62 },
  { label: "Brandeis University", value: "Brandeis_University", level: 63 },
  { label: "Bridgewater College", value: "Bridgewater_College", level: 64 },
  { label: "Brigham Young University", value: "Brigham_Young_University", level: 65 },
  { label: "Brigham Young University-Hawaii", value: "Brigham_Young_University-Hawaii", level: 66 },
  { label: "Brigham Young University-Idaho", value: "Brigham_Young_University-Idaho", level: 67 },
  { label: "Brigham Young University-Provo", value: "Brigham_Young_University-Provo", level: 68 },
  { label: "Brigham Young University–Idaho", value: "Brigham_Young_University–Idaho", level: 69 },
  { label: "Brookdale Community College", value: "Brookdale_Community_College", level: 70 },
  { label: "Brown University", value: "Brown_University", level: 71 },
  { label: "Bryant University", value: "Bryant_University", level: 72 },
  { label: "Bryn Mawr College", value: "Bryn_Mawr_College", level: 73 },
  { label: "Bucknell University", value: "Bucknell_University", level: 74 },
  { label: "Butler University", value: "Butler_University", level: 75 },
  { label: "Cabrini University", value: "Cabrini_University", level: 76 },
  { label: "Caldwell University", value: "Caldwell_University", level: 77 },
  { label: "California Baptist University", value: "California_Baptist_University", level: 78 },
  { label: "California College Of The Arts", value: "California_College_Of_The_Arts", level: 79 },
  { label: "California Institute of Technology", value: "California_Institute_of_Technology", level: 80 },
  { label: "California Institute of Technology (Caltech)", value: "California_Institute_of_Technology_(Caltech)", level: 81 },
  { label: "California Institute Of The Arts", value: "California_Institute_Of_The_Arts", level: 82 },
  { label: "California Lutheran University", value: "California_Lutheran_University", level: 83 },
  {
    label: "California Polytechnic State University, San Luis Obispo",
    value: "California_Polytechnic_State_University,_San_Luis_Obispo",
    level: 84,
  },
  { label: "California State Polytechnic University, Pomona", value: "California_State_Polytechnic_University,_Pomona", level: 85 },
  { label: "California State University, Bakersfield", value: "California_State_University,_Bakersfield", level: 86 },
  { label: "California State University, Chico", value: "California_State_University,_Chico", level: 87 },
  { label: "California State University, East Bay", value: "California_State_University,_East_Bay", level: 88 },
  { label: "California State University, Fresno", value: "California_State_University,_Fresno", level: 89 },
  { label: "California State University, Fullerton", value: "California_State_University,_Fullerton", level: 90 },
  { label: "California State University, Long Beach", value: "California_State_University,_Long_Beach", level: 91 },
  { label: "California State University, Los Angeles", value: "California_State_University,_Los_Angeles", level: 92 },
  { label: "California State University, Monterey Bay", value: "California_State_University,_Monterey_Bay", level: 93 },
  { label: "California State University, Northridge", value: "California_State_University,_Northridge", level: 94 },
  { label: "California State University, Sacramento", value: "California_State_University,_Sacramento", level: 95 },
  { label: "California State University, San Bernardino", value: "California_State_University,_San_Bernardino", level: 96 },
  { label: "California State University, San Marcos", value: "California_State_University,_San_Marcos", level: 97 },
  { label: "California State University, Stanislaus", value: "California_State_University,_Stanislaus", level: 98 },
  { label: "Calvin College", value: "Calvin_College", level: 99 },
  { label: "Campbell University", value: "Campbell_University", level: 100 },
  { label: "Canisius College", value: "Canisius_College", level: 101 },
  { label: "Capital University", value: "Capital_University", level: 102 },
  { label: "Carleton College", value: "Carleton_College", level: 103 },
  { label: "Carlow University", value: "Carlow_University", level: 104 },
  { label: "Carnegie Mellon University", value: "Carnegie_Mellon_University", level: 105 },
  { label: "Carroll College", value: "Carroll_College", level: 106 },
  { label: "Carroll University", value: "Carroll_University", level: 107 },
  { label: "Carson-Newman University", value: "Carson-Newman_University", level: 108 },
  { label: "Carthage College", value: "Carthage_College", level: 109 },
  { label: "Case Western Reserve University", value: "Case_Western_Reserve_University", level: 110 },
  { label: "Catawba College", value: "Catawba_College", level: 111 },
  { label: "Catholic University of America", value: "Catholic_University_of_America", level: 112 },
  { label: "Cazenovia College", value: "Cazenovia_College", level: 113 },
  { label: "Cedar Crest College", value: "Cedar_Crest_College", level: 114 },
  { label: "Cedarville University", value: "Cedarville_University", level: 115 },
  { label: "Centenary University", value: "Centenary_University", level: 116 },
  { label: "Central College", value: "Central_College", level: 117 },
  { label: "Central Connecticut State University", value: "Central_Connecticut_State_University", level: 118 },
  { label: "Central Methodist University", value: "Central_Methodist_University", level: 119 },
  { label: "Central Michigan University", value: "Central_Michigan_University", level: 120 },
  { label: "Central Washington University", value: "Central_Washington_University", level: 121 },
  { label: "Centre College", value: "Centre_College", level: 122 },
  { label: "Chaminade University Of Honolulu", value: "Chaminade_University_Of_Honolulu", level: 123 },
  { label: "Chapman University", value: "Chapman_University", level: 124 },
  { label: "Chatham University", value: "Chatham_University", level: 125 },
  { label: "Chestnut Hill College", value: "Chestnut_Hill_College", level: 126 },
  { label: "Christian Brothers University", value: "Christian_Brothers_University", level: 127 },
  { label: "Christopher Newport University", value: "Christopher_Newport_University", level: 128 },
  { label: "Claflin University", value: "Claflin_University", level: 129 },
  { label: "Claremont Mckenna College", value: "Claremont_Mckenna_College", level: 130 },
  { label: "Clark Atlanta University", value: "Clark_Atlanta_University", level: 131 },
  { label: "Clark University", value: "Clark_University", level: 132 },
  { label: "Clarke University", value: "Clarke_University", level: 133 },
  { label: "Clarkson University", value: "Clarkson_University", level: 134 },
  { label: "Clemson University", value: "Clemson_University", level: 135 },
  { label: "Cleveland State University", value: "Cleveland_State_University", level: 136 },
  { label: "Coe College", value: "Coe_College", level: 137 },
  { label: "Coker College", value: "Coker_College", level: 138 },
  { label: "Colby College", value: "Colby_College", level: 139 },
  { label: "Colby-Sawyer College", value: "Colby-Sawyer_College", level: 140 },
  { label: "Colgate University", value: "Colgate_University", level: 141 },
  { label: "College For Creative Studies", value: "College_For_Creative_Studies", level: 142 },
  { label: "College of Charleston", value: "College_of_Charleston", level: 143 },
  { label: "College Of Idaho", value: "College_Of_Idaho", level: 144 },
  { label: "College Of Mount Saint Vincent", value: "College_Of_Mount_Saint_Vincent", level: 145 },
  { label: "College Of Our Lady Of The Elms", value: "College_Of_Our_Lady_Of_The_Elms", level: 146 },
  { label: "College Of Saint Benedict", value: "College_Of_Saint_Benedict", level: 147 },
  { label: "College Of The Holy Cross", value: "College_Of_The_Holy_Cross", level: 148 },
  { label: "College of William & Mary", value: "College_of_William_&_Mary", level: 149 },
  { label: "Colorado College", value: "Colorado_College", level: 150 },
  { label: "Colorado School Of Mines", value: "Colorado_School_Of_Mines", level: 151 },
  { label: "Colorado State University", value: "Colorado_State_University", level: 152 },
  { label: "Colorado State University-Fort Collins", value: "Colorado_State_University-Fort_Collins", level: 153 },
  { label: "Columbia University", value: "Columbia_University", level: 154 },
  { label: "Columbus College Of Art & Design", value: "Columbus_College_Of_Art_&_Design", level: 155 },
  { label: "Concordia College At Moorhead", value: "Concordia_College_At_Moorhead", level: 156 },
  { label: "Concordia College-New York", value: "Concordia_College-New_York", level: 157 },
  { label: "Concordia University Irvine", value: "Concordia_University_Irvine", level: 158 },
  { label: "Connecticut College", value: "Connecticut_College", level: 159 },
  { label: "Cornell College", value: "Cornell_College", level: 160 },
  { label: "Cornell University", value: "Cornell_University", level: 161 },
  { label: "Cornerstone University", value: "Cornerstone_University", level: 162 },
  { label: "Covenant College", value: "Covenant_College", level: 163 },
  { label: "Creighton University", value: "Creighton_University", level: 164 },
  { label: "Cumberland University", value: "Cumberland_University", level: 165 },
  { label: "Cuny Bernard M. Baruch College", value: "Cuny_Bernard_M._Baruch_College", level: 166 },
  { label: "Cuny Brooklyn College", value: "Cuny_Brooklyn_College", level: 167 },
  { label: "Cuny City College Of New York", value: "Cuny_City_College_Of_New_York", level: 168 },
  { label: "Cuny Hunter College", value: "Cuny_Hunter_College", level: 169 },
  { label: "Cuny John Jay College Of Criminal Justice", value: "Cuny_John_Jay_College_Of_Criminal_Justice", level: 170 },
  { label: "Cuny Lehman College", value: "Cuny_Lehman_College", level: 171 },
  { label: "Cuny Queens College", value: "Cuny_Queens_College", level: 172 },
  { label: "Cuny York College", value: "Cuny_York_College", level: 173 },
  { label: "Curry College", value: "Curry_College", level: 174 },
  { label: "Daemen College", value: "Daemen_College", level: 175 },
  { label: "Dallas Baptist University", value: "Dallas_Baptist_University", level: 176 },
  { label: "Dartmouth College", value: "Dartmouth_College", level: 177 },
  { label: "Davidson College", value: "Davidson_College", level: 178 },
  { label: "Delaware State University", value: "Delaware_State_University", level: 179 },
  { label: "Delaware Valley University", value: "Delaware_Valley_University", level: 180 },
  { label: "Delta State University", value: "Delta_State_University", level: 181 },
  { label: "Denison University", value: "Denison_University", level: 182 },
  { label: "Depaul University", value: "Depaul_University", level: 183 },
  { label: "Depauw University", value: "Depauw_University", level: 184 },
  { label: "Desales University", value: "Desales_University", level: 185 },
  { label: "Dickinson College", value: "Dickinson_College", level: 186 },
  { label: "Doane College-Crete", value: "Doane_College-Crete", level: 187 },
  { label: "Dominican College", value: "Dominican_College", level: 188 },
  { label: "Dominican University Of California", value: "Dominican_University_Of_California", level: 189 },
  { label: "Dordt College", value: "Dordt_College", level: 190 },
  { label: "Drake University", value: "Drake_University", level: 191 },
  { label: "Drew University", value: "Drew_University", level: 192 },
  { label: "Drexel University", value: "Drexel_University", level: 193 },
  { label: "Drury University", value: "Drury_University", level: 194 },
  { label: "Duke University", value: "Duke_University", level: 195 },
  { label: "Duquesne University", value: "Duquesne_University", level: 196 },
  { label: "Earlham College", value: "Earlham_College", level: 197 },
  { label: "East Carolina University", value: "East_Carolina_University", level: 198 },
  { label: "East Central University", value: "East_Central_University", level: 199 },
  { label: "East Stroudsburg University", value: "East_Stroudsburg_University", level: 200 },
  { label: "East Tennessee State University", value: "East_Tennessee_State_University", level: 201 },
  { label: "Eastern Connecticut State University", value: "Eastern_Connecticut_State_University", level: 202 },
  { label: "Eastern Illinois University", value: "Eastern_Illinois_University", level: 203 },
  { label: "Eastern Kentucky University", value: "Eastern_Kentucky_University", level: 204 },
  { label: "Eastern Mennonite University", value: "Eastern_Mennonite_University", level: 205 },
  { label: "Eastern Michigan University", value: "Eastern_Michigan_University", level: 206 },
  { label: "Eastern Nazarene College", value: "Eastern_Nazarene_College", level: 207 },
  { label: "Eastern New Mexico University", value: "Eastern_New_Mexico_University", level: 208 },
  { label: "Eastern University", value: "Eastern_University", level: 209 },
  { label: "Eastern Washington University", value: "Eastern_Washington_University", level: 210 },
  { label: "Eckerd College", value: "Eckerd_College", level: 211 },
  { label: "Edgewood College", value: "Edgewood_College", level: 212 },
  { label: "Edinboro University", value: "Edinboro_University", level: 213 },
  { label: "Elgin Community College", value: "Elgin_Community_College", level: 214 },
  { label: "Elizabethtown College", value: "Elizabethtown_College", level: 215 },
  { label: "Elmhurst College", value: "Elmhurst_College", level: 216 },
  { label: "Elmira College", value: "Elmira_College", level: 217 },
  { label: "Elon University", value: "Elon_University", level: 218 },
  {
    label: "Embry-Riddle Aeronautical University, Daytona Beach",
    value: "Embry-Riddle_Aeronautical_University,_Daytona_Beach",
    level: 219,
  },
  { label: "Embry-Riddle Aeronautical University, Prescott", value: "Embry-Riddle_Aeronautical_University,_Prescott", level: 220 },
  { label: "Emerson College", value: "Emerson_College", level: 221 },
  { label: "Emmanuel College", value: "Emmanuel_College", level: 222 },
  { label: "Emory University", value: "Emory_University", level: 223 },
  { label: "Emporia State University", value: "Emporia_State_University", level: 224 },
  { label: "Endicott College", value: "Endicott_College", level: 225 },
  { label: "Evangel University", value: "Evangel_University", level: 226 },
  { label: "Fairfield University", value: "Fairfield_University", level: 227 },
  { label: "Fairleigh Dickinson University", value: "Fairleigh_Dickinson_University", level: 228 },
  { label: "Fairmont State University", value: "Fairmont_State_University", level: 229 },
  { label: "Faulkner University", value: "Faulkner_University", level: 230 },
  { label: "Fayetteville State University", value: "Fayetteville_State_University", level: 231 },
  { label: "Ferris State University", value: "Ferris_State_University", level: 232 },
  { label: "Ferrum College", value: "Ferrum_College", level: 233 },
  { label: "Fitchburg State University", value: "Fitchburg_State_University", level: 234 },
  { label: "Flagler College", value: "Flagler_College", level: 235 },
  { label: "Florida Agricultural And Mechanical University", value: "Florida_Agricultural_And_Mechanical_University", level: 236 },
  { label: "Florida Atlantic University", value: "Florida_Atlantic_University", level: 237 },
  { label: "Florida Gulf Coast University", value: "Florida_Gulf_Coast_University", level: 238 },
  { label: "Florida Institute Of Technology", value: "Florida_Institute_Of_Technology", level: 239 },
  { label: "Florida International University", value: "Florida_International_University", level: 240 },
  { label: "Florida Southern College", value: "Florida_Southern_College", level: 241 },
  { label: "Florida State University", value: "Florida_State_University", level: 242 },
  { label: "Fordham University", value: "Fordham_University", level: 243 },
  { label: "Fort Valley State University", value: "Fort_Valley_State_University", level: 244 },
  { label: "Framingham State University", value: "Framingham_State_University", level: 245 },
  { label: "Francis Marion University", value: "Francis_Marion_University", level: 246 },
  { label: "Franciscan University Of Steubenville", value: "Franciscan_University_Of_Steubenville", level: 247 },
  { label: "Franklin & Marshall College", value: "Franklin_&_Marshall_College", level: 248 },
  { label: "Franklin College", value: "Franklin_College", level: 249 },
  { label: "Franklin Pierce University", value: "Franklin_Pierce_University", level: 250 },
  { label: "Freed-Hardeman University", value: "Freed-Hardeman_University", level: 251 },
  { label: "Frostburg State University", value: "Frostburg_State_University", level: 252 },
  { label: "Furman University", value: "Furman_University", level: 253 },
  { label: "Gallaudet University", value: "Gallaudet_University", level: 254 },
  { label: "Gannon University", value: "Gannon_University", level: 255 },
  { label: "Geneva College", value: "Geneva_College", level: 256 },
  { label: "George Fox University", value: "George_Fox_University", level: 257 },
  { label: "George Mason University", value: "George_Mason_University", level: 258 },
  { label: "George Washington University", value: "George_Washington_University", level: 259 },
  { label: "Georgetown College", value: "Georgetown_College", level: 260 },
  { label: "Georgetown University", value: "Georgetown_University", level: 261 },
  { label: "Georgia College & State University", value: "Georgia_College_&_State_University", level: 262 },
  { label: "Georgia Institute of Technology", value: "Georgia_Institute_of_Technology", level: 263 },
  { label: "Georgia Southern University", value: "Georgia_Southern_University", level: 264 },
  { label: "Georgia State University", value: "Georgia_State_University", level: 265 },
  { label: "Georgian Court University", value: "Georgian_Court_University", level: 266 },
  { label: "Gettysburg College", value: "Gettysburg_College", level: 267 },
  { label: "Glenville State College", value: "Glenville_State_College", level: 268 },
  { label: "Gonzaga University", value: "Gonzaga_University", level: 269 },
  { label: "Gordon College", value: "Gordon_College", level: 270 },
  { label: "Goucher College", value: "Goucher_College", level: 271 },
  { label: "Grace College And Theological Seminary", value: "Grace_College_And_Theological_Seminary", level: 272 },
  { label: "Graceland University-Lamoni", value: "Graceland_University-Lamoni", level: 273 },
  { label: "Grand Valley State University", value: "Grand_Valley_State_University", level: 274 },
  { label: "Grand View University", value: "Grand_View_University", level: 275 },
  { label: "Greenville College", value: "Greenville_College", level: 276 },
  { label: "Grinnell College", value: "Grinnell_College", level: 277 },
  { label: "Guilford College", value: "Guilford_College", level: 278 },
  { label: "Gustavus Adolphus College", value: "Gustavus_Adolphus_College", level: 279 },
  { label: "Gwynedd Mercy University", value: "Gwynedd_Mercy_University", level: 280 },
  { label: "Hamilton College", value: "Hamilton_College", level: 281 },
  { label: "Hamline University", value: "Hamline_University", level: 282 },
  { label: "Hampden-Sydney College", value: "Hampden-Sydney_College", level: 283 },
  { label: "Hampshire College", value: "Hampshire_College", level: 284 },
  { label: "Hampton University", value: "Hampton_University", level: 285 },
  { label: "Hanover College", value: "Hanover_College", level: 286 },
  { label: "Hardin-Simmons University", value: "Hardin-Simmons_University", level: 287 },
  { label: "Harding University", value: "Harding_University", level: 288 },
  { label: "Hartwick College", value: "Hartwick_College", level: 289 },
  { label: "Harvard University", value: "Harvard_University", level: 290 },
  { label: "Hastings College", value: "Hastings_College", level: 291 },
  { label: "Haverford College", value: "Haverford_College", level: 292 },
  { label: "Hawaii Pacific University", value: "Hawaii_Pacific_University", level: 293 },
  { label: "Heidelberg University", value: "Heidelberg_University", level: 294 },
  { label: "Henderson State University", value: "Henderson_State_University", level: 295 },
  { label: "Hendrix College", value: "Hendrix_College", level: 296 },
  { label: "High Point University", value: "High_Point_University", level: 297 },
  { label: "Hilbert College", value: "Hilbert_College", level: 298 },
  { label: "Hiram College", value: "Hiram_College", level: 299 },
  { label: "Hobart And William Smith Colleges", value: "Hobart_And_William_Smith_Colleges", level: 300 },
  { label: "Hofstra University", value: "Hofstra_University", level: 301 },
  { label: "Holy Family University", value: "Holy_Family_University", level: 302 },
  { label: "Hood College", value: "Hood_College", level: 303 },
  { label: "Hope College", value: "Hope_College", level: 304 },
  { label: "Houghton College", value: "Houghton_College", level: 305 },
  { label: "Howard Payne University", value: "Howard_Payne_University", level: 306 },
  { label: "Howard University", value: "Howard_University", level: 307 },
  { label: "Hult International Business", value: "Hult_International_Business", level: 308 },
  { label: "Humboldt State University", value: "Humboldt_State_University", level: 309 },
  { label: "Huntingdon College", value: "Huntingdon_College", level: 310 },
  { label: "Huntington University", value: "Huntington_University", level: 311 },
  { label: "Husson University", value: "Husson_University", level: 312 },
  { label: "Idaho State University", value: "Idaho_State_University", level: 313 },
  { label: "Illinois College", value: "Illinois_College", level: 314 },
  { label: "Illinois Institute Of Technology", value: "Illinois_Institute_Of_Technology", level: 315 },
  { label: "Illinois State University", value: "Illinois_State_University", level: 316 },
  { label: "Illinois Wesleyan University", value: "Illinois_Wesleyan_University", level: 317 },
  { label: "Immaculata University", value: "Immaculata_University", level: 318 },
  { label: "Indiana State University", value: "Indiana_State_University", level: 319 },
  { label: "Indiana University Bloomington", value: "Indiana_University_Bloomington", level: 320 },
  { label: "Indiana University Kokomo", value: "Indiana_University_Kokomo", level: 321 },
  { label: "Indiana University Northwest", value: "Indiana_University_Northwest", level: 322 },
  { label: "Indiana University Of Pennsylvania", value: "Indiana_University_Of_Pennsylvania", level: 323 },
  { label: "Indiana University South Bend", value: "Indiana_University_South_Bend", level: 324 },
  { label: "Indiana University Southeast", value: "Indiana_University_Southeast", level: 325 },
  { label: "Indiana University-Purdue University Fort Wayne", value: "Indiana_University-Purdue_University_Fort_Wayne", level: 326 },
  { label: "Indiana University-Purdue University Indianapolis", value: "Indiana_University-Purdue_University_Indianapolis", level: 327 },
  { label: "Iona College", value: "Iona_College", level: 328 },
  { label: "Iowa State University", value: "Iowa_State_University", level: 329 },
  { label: "Ithaca College", value: "Ithaca_College", level: 330 },
  { label: "Jackson State University", value: "Jackson_State_University", level: 331 },
  { label: "Jacksonville State University", value: "Jacksonville_State_University", level: 332 },
  { label: "James Madison University", value: "James_Madison_University", level: 333 },
  { label: "John Brown University", value: "John_Brown_University", level: 334 },
  { label: "John Carroll University", value: "John_Carroll_University", level: 335 },
  { label: "Johns Hopkins University", value: "Johns_Hopkins_University", level: 336 },
  { label: "Johnson & Wales University-Charlotte", value: "Johnson_&_Wales_University-Charlotte", level: 337 },
  { label: "Johnson & Wales University-Denver", value: "Johnson_&_Wales_University-Denver", level: 338 },
  { label: "Johnson & Wales University-North Miami", value: "Johnson_&_Wales_University-North_Miami", level: 339 },
  { label: "Johnson & Wales University-Providence", value: "Johnson_&_Wales_University-Providence", level: 340 },
  { label: "Johnson C. Smith University", value: "Johnson_C._Smith_University", level: 341 },
  { label: "Johnson State College", value: "Johnson_State_College", level: 342 },
  { label: "Judson University", value: "Judson_University", level: 343 },
  { label: "Juniata College", value: "Juniata_College", level: 344 },
  { label: "Kalamazoo College", value: "Kalamazoo_College", level: 345 },
  { label: "Kansas State University", value: "Kansas_State_University", level: 346 },
  { label: "Kean University", value: "Kean_University", level: 347 },
  { label: "Keene State College", value: "Keene_State_College", level: 348 },
  { label: "Kennesaw State University", value: "Kennesaw_State_University", level: 349 },
  { label: "Kent State University At Kent", value: "Kent_State_University_At_Kent", level: 350 },
  { label: "Kenyon College", value: "Kenyon_College", level: 351 },
  { label: "Kettering University", value: "Kettering_University", level: 352 },
  { label: "Keuka College", value: "Keuka_College", level: 353 },
  { label: "King’S College", value: "King’S_College", level: 354 },
  { label: "Knox College", value: "Knox_College", level: 355 },
  { label: "Kutztown University Of Pennsylvania", value: "Kutztown_University_Of_Pennsylvania", level: 356 },
  { label: "La Roche College", value: "La_Roche_College", level: 357 },
  { label: "La Salle University", value: "La_Salle_University", level: 358 },
  { label: "La Sierra University", value: "La_Sierra_University", level: 359 },
  { label: "Lafayette College", value: "Lafayette_College", level: 360 },
  { label: "Lake Erie College", value: "Lake_Erie_College", level: 361 },
  { label: "Lake Forest College", value: "Lake_Forest_College", level: 362 },
  { label: "Lake Superior State University", value: "Lake_Superior_State_University", level: 363 },
  { label: "Lakeland University", value: "Lakeland_University", level: 364 },
  { label: "Lancaster Bible College", value: "Lancaster_Bible_College", level: 365 },
  { label: "Lander University", value: "Lander_University", level: 366 },
  { label: "Langston University", value: "Langston_University", level: 367 },
  { label: "Lasell College", value: "Lasell_College", level: 368 },
  { label: "Lawrence Technological University", value: "Lawrence_Technological_University", level: 369 },
  { label: "Lawrence University", value: "Lawrence_University", level: 370 },
  { label: "Le Moyne College", value: "Le_Moyne_College", level: 371 },
  { label: "Lebanon Valley College", value: "Lebanon_Valley_College", level: 372 },
  { label: "Lee University", value: "Lee_University", level: 373 },
  { label: "Lehigh University", value: "Lehigh_University", level: 374 },
  { label: "Lenoir-Rhyne University", value: "Lenoir-Rhyne_University", level: 375 },
  { label: "Lesley University", value: "Lesley_University", level: 376 },
  { label: "Lewis & Clark College", value: "Lewis_&_Clark_College", level: 377 },
  { label: "Lewis University", value: "Lewis_University", level: 378 },
  { label: "Lewis-Clark State College", value: "Lewis-Clark_State_College", level: 379 },
  { label: "Life University", value: "Life_University", level: 380 },
  { label: "Lincoln Memorial University", value: "Lincoln_Memorial_University", level: 381 },
  { label: "Lindenwood University", value: "Lindenwood_University", level: 382 },
  { label: "Lindsey Wilson College", value: "Lindsey_Wilson_College", level: 383 },
  { label: "Lipscomb University", value: "Lipscomb_University", level: 384 },
  { label: "Liu Brooklyn", value: "Liu_Brooklyn", level: 385 },
  { label: "Liu Post", value: "Liu_Post", level: 386 },
  { label: "Lock Haven University", value: "Lock_Haven_University", level: 387 },
  { label: "Longwood University", value: "Longwood_University", level: 388 },
  { label: "Loras College", value: "Loras_College", level: 389 },
  { label: "Louisiana State University", value: "Louisiana_State_University", level: 390 },
  { label: "Louisiana State University Shreveport", value: "Louisiana_State_University_Shreveport", level: 391 },
  { label: "Louisiana Tech University", value: "Louisiana_Tech_University", level: 392 },
  { label: "Loyola Marymount University", value: "Loyola_Marymount_University", level: 393 },
  { label: "Loyola University Chicago", value: "Loyola_University_Chicago", level: 394 },
  { label: "Loyola University Maryland", value: "Loyola_University_Maryland", level: 395 },
  { label: "Loyola University New Orleans", value: "Loyola_University_New_Orleans", level: 396 },
  { label: "Lubbock Christian University", value: "Lubbock_Christian_University", level: 397 },
  { label: "Luther College", value: "Luther_College", level: 398 },
  { label: "Lycoming College", value: "Lycoming_College", level: 399 },
  { label: "Lynchburg College", value: "Lynchburg_College", level: 400 },
  { label: "Lyndon State College", value: "Lyndon_State_College", level: 401 },
  { label: "Lynn University", value: "Lynn_University", level: 402 },
  { label: "Macalester College", value: "Macalester_College", level: 403 },
  { label: "Madonna University", value: "Madonna_University", level: 404 },
  { label: "Malone University", value: "Malone_University", level: 405 },
  { label: "Manhattan College", value: "Manhattan_College", level: 406 },
  { label: "Manhattanville College", value: "Manhattanville_College", level: 407 },
  { label: "Mansfield University Of Pennsylvania", value: "Mansfield_University_Of_Pennsylvania", level: 408 },
  { label: "Marian University (Indiana)", value: "Marian_University_(Indiana)", level: 409 },
  { label: "Marian University (Wisconsin)", value: "Marian_University_(Wisconsin)", level: 410 },
  { label: "Marietta College", value: "Marietta_College", level: 411 },
  { label: "Marist College", value: "Marist_College", level: 412 },
  { label: "Marquette University", value: "Marquette_University", level: 413 },
  { label: "Mars Hill University", value: "Mars_Hill_University", level: 414 },
  { label: "Marshall University", value: "Marshall_University", level: 415 },
  { label: "Martin Luther College", value: "Martin_Luther_College", level: 416 },
  { label: "Maryland Institute College Of Art", value: "Maryland_Institute_College_Of_Art", level: 417 },
  { label: "Marymount Manhattan College", value: "Marymount_Manhattan_College", level: 418 },
  { label: "Marymount University", value: "Marymount_University", level: 419 },
  { label: "Maryville College", value: "Maryville_College", level: 420 },
  { label: "Maryville University", value: "Maryville_University", level: 421 },
  { label: "Marywood University", value: "Marywood_University", level: 422 },
  { label: "Massachusetts College Of Art And Design", value: "Massachusetts_College_Of_Art_And_Design", level: 423 },
  { label: "Massachusetts College Of Liberal Arts", value: "Massachusetts_College_Of_Liberal_Arts", level: 424 },
  { label: "Massachusetts Institute of Technology", value: "Massachusetts_Institute_of_Technology", level: 425 },
  { label: "Massachusetts Institute of Technology (MIT)", value: "Massachusetts_Institute_of_Technology_(MIT)", level: 426 },
  { label: "Massachusetts Maritime Academy", value: "Massachusetts_Maritime_Academy", level: 427 },
  { label: "Mcdaniel College", value: "Mcdaniel_College", level: 428 },
  { label: "Mckendree University", value: "Mckendree_University", level: 429 },
  { label: "Mcneese State University", value: "Mcneese_State_University", level: 430 },
  { label: "Mcphs University", value: "Mcphs_University", level: 431 },
  { label: "Medaille College", value: "Medaille_College", level: 432 },
  { label: "Mercer University", value: "Mercer_University", level: 433 },
  { label: "Mercyhurst University", value: "Mercyhurst_University", level: 434 },
  { label: "Meredith College", value: "Meredith_College", level: 435 },
  { label: "Merrimack College", value: "Merrimack_College", level: 436 },
  { label: "Messiah College", value: "Messiah_College", level: 437 },
  { label: "Methodist University", value: "Methodist_University", level: 438 },
  { label: "Metropolitan State University", value: "Metropolitan_State_University", level: 439 },
  { label: "Metropolitan State University of Denver", value: "Metropolitan_State_University_of_Denver", level: 440 },
  { label: "Miami University", value: "Miami_University", level: 441 },
  { label: "Michigan State University", value: "Michigan_State_University", level: 442 },
  { label: "Michigan Technological University", value: "Michigan_Technological_University", level: 443 },
  { label: "Midamerica Nazarene University", value: "Midamerica_Nazarene_University", level: 444 },
  { label: "Middle Tennessee State University", value: "Middle_Tennessee_State_University", level: 445 },
  { label: "Middlebury College", value: "Middlebury_College", level: 446 },
  { label: "Midland University", value: "Midland_University", level: 447 },
  { label: "Midwestern State University", value: "Midwestern_State_University", level: 448 },
  { label: "Millersville University", value: "Millersville_University", level: 449 },
  { label: "Milligan College", value: "Milligan_College", level: 450 },
  { label: "Millikin University", value: "Millikin_University", level: 451 },
  { label: "Milwaukee School Of Engineering", value: "Milwaukee_School_Of_Engineering", level: 452 },
  { label: "Minnesota State University Moorhead", value: "Minnesota_State_University_Moorhead", level: 453 },
  { label: "Minnesota State University-Mankato", value: "Minnesota_State_University-Mankato", level: 454 },
  { label: "Misericordia University", value: "Misericordia_University", level: 455 },
  { label: "Mississippi College", value: "Mississippi_College", level: 456 },
  { label: "Mississippi State University", value: "Mississippi_State_University", level: 457 },
  { label: "Missouri Baptist University", value: "Missouri_Baptist_University", level: 458 },
  { label: "Missouri Southern State University", value: "Missouri_Southern_State_University", level: 459 },
  { label: "Missouri State University", value: "Missouri_State_University", level: 460 },
  { label: "Missouri State University-Springfield", value: "Missouri_State_University-Springfield", level: 461 },
  { label: "Missouri University Of Science And Technology", value: "Missouri_University_Of_Science_And_Technology", level: 462 },
  { label: "Missouri Valley College", value: "Missouri_Valley_College", level: 463 },
  { label: "Missouri Western State University", value: "Missouri_Western_State_University", level: 464 },
  { label: "Molloy College", value: "Molloy_College", level: 465 },
  { label: "Monmouth College", value: "Monmouth_College", level: 466 },
  { label: "Monmouth University", value: "Monmouth_University", level: 467 },
  { label: "Montana State University", value: "Montana_State_University", level: 468 },
  { label: "Montana State University-Billings", value: "Montana_State_University-Billings", level: 469 },
  { label: "Montana Tech of the University of Montana", value: "Montana_Tech_of_the_University_of_Montana", level: 470 },
  { label: "Montclair State University", value: "Montclair_State_University", level: 471 },
  { label: "Moravian College", value: "Moravian_College", level: 472 },
  { label: "Morehead State University", value: "Morehead_State_University", level: 473 },
  { label: "Morehouse College", value: "Morehouse_College", level: 474 },
  { label: "Morgan State University", value: "Morgan_State_University", level: 475 },
  { label: "Morningside College", value: "Morningside_College", level: 476 },
  { label: "Mount Aloysius College", value: "Mount_Aloysius_College", level: 477 },
  { label: "Mount Holyoke College", value: "Mount_Holyoke_College", level: 478 },
  { label: "Mount Ida College", value: "Mount_Ida_College", level: 479 },
  { label: "Mount Marty College", value: "Mount_Marty_College", level: 480 },
  { label: "Mount Mercy University", value: "Mount_Mercy_University", level: 481 },
  { label: "Mount Saint Mary College", value: "Mount_Saint_Mary_College", level: 482 },
  { label: "Mount Saint Mary’S University", value: "Mount_Saint_Mary’S_University", level: 483 },
  { label: "Mount St Mary’S University", value: "Mount_St_Mary’S_University", level: 484 },
  { label: "Mount Vernon Nazarene University", value: "Mount_Vernon_Nazarene_University", level: 485 },
  { label: "Muhlenberg College", value: "Muhlenberg_College", level: 486 },
  { label: "Murray State University", value: "Murray_State_University", level: 487 },
  { label: "Muskingum University", value: "Muskingum_University", level: 488 },
  { label: "Nazareth College", value: "Nazareth_College", level: 489 },
  { label: "Nebraska Wesleyan University", value: "Nebraska_Wesleyan_University", level: 490 },
  { label: "Neumann University", value: "Neumann_University", level: 491 },
  { label: "New Jersey City University", value: "New_Jersey_City_University", level: 492 },
  { label: "New Jersey Institute of Technology", value: "New_Jersey_Institute_of_Technology", level: 493 },
  { label: "New Mexico State University", value: "New_Mexico_State_University", level: 494 },
  { label: "New York Institute Of Technology", value: "New_York_Institute_Of_Technology", level: 495 },
  { label: "New York University", value: "New_York_University", level: 496 },
  { label: "Newberry College", value: "Newberry_College", level: 497 },
  { label: "Newbury College", value: "Newbury_College", level: 498 },
  { label: "Newman University", value: "Newman_University", level: 499 },
  { label: "Niagara University", value: "Niagara_University", level: 500 },
  { label: "Nicholls State University", value: "Nicholls_State_University", level: 501 },
  { label: "Nichols College", value: "Nichols_College", level: 502 },
  { label: "Norfolk State University", value: "Norfolk_State_University", level: 503 },
  {
    label: "North Carolina Agricultural And Technical State University",
    value: "North_Carolina_Agricultural_And_Technical_State_University",
    level: 504,
  },
  { label: "North Carolina Central University", value: "North_Carolina_Central_University", level: 505 },
  { label: "North Carolina State University", value: "North_Carolina_State_University", level: 506 },
  { label: "North Carolina State University At Raleigh", value: "North_Carolina_State_University_At_Raleigh", level: 507 },
  { label: "North Central College", value: "North_Central_College", level: 508 },
  { label: "North Central University", value: "North_Central_University", level: 509 },
  { label: "North Dakota State University", value: "North_Dakota_State_University", level: 510 },
  { label: "North Greenville University", value: "North_Greenville_University", level: 511 },
  { label: "North Park University", value: "North_Park_University", level: 512 },
  { label: "Northeastern Illinois University", value: "Northeastern_Illinois_University", level: 513 },
  { label: "Northeastern State University", value: "Northeastern_State_University", level: 514 },
  { label: "Northeastern University", value: "Northeastern_University", level: 515 },
  { label: "Northern Arizona University", value: "Northern_Arizona_University", level: 516 },
  { label: "Northern Illinois University", value: "Northern_Illinois_University", level: 517 },
  { label: "Northern Kentucky University", value: "Northern_Kentucky_University", level: 518 },
  { label: "Northern Michigan University", value: "Northern_Michigan_University", level: 519 },
  { label: "Northwest Missouri State University", value: "Northwest_Missouri_State_University", level: 520 },
  { label: "Northwest Nazarene University", value: "Northwest_Nazarene_University", level: 521 },
  { label: "Northwest University", value: "Northwest_University", level: 522 },
  { label: "Northwestern College", value: "Northwestern_College", level: 523 },
  { label: "Northwestern Oklahoma State University", value: "Northwestern_Oklahoma_State_University", level: 524 },
  { label: "Northwestern University", value: "Northwestern_University", level: 525 },
  { label: "Norwich University", value: "Norwich_University", level: 526 },
  { label: "Notre Dame De Namur University", value: "Notre_Dame_De_Namur_University", level: 527 },
  { label: "Notre Dame Of Maryland University", value: "Notre_Dame_Of_Maryland_University", level: 528 },
  { label: "Nova Southeastern University", value: "Nova_Southeastern_University", level: 529 },
  { label: "Nyack College", value: "Nyack_College", level: 530 },
  { label: "Oakland University", value: "Oakland_University", level: 531 },
  { label: "Oakwood University", value: "Oakwood_University", level: 532 },
  { label: "Oberlin College", value: "Oberlin_College", level: 533 },
  { label: "Occidental College", value: "Occidental_College", level: 534 },
  { label: "Oglethorpe University", value: "Oglethorpe_University", level: 535 },
  { label: "Ohio Dominican University", value: "Ohio_Dominican_University", level: 536 },
  { label: "Ohio Northern University", value: "Ohio_Northern_University", level: 537 },
  { label: "Ohio State University", value: "Ohio_State_University", level: 538 },
  { label: "Ohio University", value: "Ohio_University", level: 539 },
  { label: "Ohio Wesleyan University", value: "Ohio_Wesleyan_University", level: 540 },
  { label: "Oklahoma Baptist University", value: "Oklahoma_Baptist_University", level: 541 },
  { label: "Oklahoma Christian University", value: "Oklahoma_Christian_University", level: 542 },
  { label: "Oklahoma City University", value: "Oklahoma_City_University", level: 543 },
  { label: "Oklahoma State University", value: "Oklahoma_State_University", level: 544 },
  { label: "Old Dominion University", value: "Old_Dominion_University", level: 545 },
  { label: "Olivet Nazarene University", value: "Olivet_Nazarene_University", level: 546 },
  { label: "Oral Roberts University", value: "Oral_Roberts_University", level: 547 },
  { label: "Oregon Institute Of Technology", value: "Oregon_Institute_Of_Technology", level: 548 },
  { label: "Oregon State University", value: "Oregon_State_University", level: 549 },
  { label: "Otis College Of Art And Design", value: "Otis_College_Of_Art_And_Design", level: 550 },
  { label: "Otterbein University", value: "Otterbein_University", level: 551 },
  { label: "Ouachita Baptist University", value: "Ouachita_Baptist_University", level: 552 },
  { label: "Our Lady Of The Lake University", value: "Our_Lady_Of_The_Lake_University", level: 553 },
  { label: "Pace University-New York", value: "Pace_University-New_York", level: 554 },
  { label: "Pacific Lutheran University", value: "Pacific_Lutheran_University", level: 555 },
  { label: "Pacific Union College", value: "Pacific_Union_College", level: 556 },
  { label: "Pacific University", value: "Pacific_University", level: 557 },
  { label: "Palm Beach Atlantic University", value: "Palm_Beach_Atlantic_University", level: 558 },
  { label: "Pennsylvania College Of Technology", value: "Pennsylvania_College_Of_Technology", level: 559 },
  { label: "Pennsylvania State University", value: "Pennsylvania_State_University", level: 560 },
  { label: "Pepperdine University", value: "Pepperdine_University", level: 561 },
  { label: "Pfeiffer University", value: "Pfeiffer_University", level: 562 },
  { label: "Philadelphia University", value: "Philadelphia_University", level: 563 },
  { label: "Pittsburg State University", value: "Pittsburg_State_University", level: 564 },
  { label: "Pitzer College", value: "Pitzer_College", level: 565 },
  { label: "Plymouth State University", value: "Plymouth_State_University", level: 566 },
  { label: "Point Loma Nazarene University", value: "Point_Loma_Nazarene_University", level: 567 },
  { label: "Point Park University", value: "Point_Park_University", level: 568 },
  { label: "Pomona College", value: "Pomona_College", level: 569 },
  { label: "Portland State University", value: "Portland_State_University", level: 570 },
  { label: "Prairie View A&M University", value: "Prairie_View_A&M_University", level: 571 },
  { label: "Pratt Institute", value: "Pratt_Institute", level: 572 },
  { label: "Princeton University", value: "Princeton_University", level: 573 },
  { label: "Providence College", value: "Providence_College", level: 574 },
  { label: "Purdue University", value: "Purdue_University", level: 575 },
  { label: "Purdue University Northwest", value: "Purdue_University_Northwest", level: 576 },
  { label: "Purdue University West Lafayette", value: "Purdue_University_West_Lafayette", level: 577 },
  { label: "Queens University Of Charlotte", value: "Queens_University_Of_Charlotte", level: 578 },
  { label: "Quincy University", value: "Quincy_University", level: 579 },
  { label: "Quinnipiac University", value: "Quinnipiac_University", level: 580 },
  { label: "Radford University", value: "Radford_University", level: 581 },
  { label: "Ramapo College Of New Jersey", value: "Ramapo_College_Of_New_Jersey", level: 582 },
  { label: "Randolph-Macon College", value: "Randolph-Macon_College", level: 583 },
  { label: "Reed College", value: "Reed_College", level: 584 },
  { label: "Regis College", value: "Regis_College", level: 585 },
  { label: "Rensselaer Polytechnic Institute", value: "Rensselaer_Polytechnic_Institute", level: 586 },
  { label: "Rhode Island College", value: "Rhode_Island_College", level: 587 },
  { label: "Rhode Island School of Design", value: "Rhode_Island_School_of_Design", level: 588 },
  { label: "Rhodes College", value: "Rhodes_College", level: 589 },
  { label: "Rice University", value: "Rice_University", level: 590 },
  { label: "Rider University", value: "Rider_University", level: 591 },
  { label: "Roanoke College", value: "Roanoke_College", level: 592 },
  { label: "Robert Morris University", value: "Robert_Morris_University", level: 593 },
  { label: "Roberts Wesleyan College", value: "Roberts_Wesleyan_College", level: 594 },
  { label: "Rochester College", value: "Rochester_College", level: 595 },
  { label: "Rochester Institute of Technology", value: "Rochester_Institute_of_Technology", level: 596 },
  { label: "Rockford University", value: "Rockford_University", level: 597 },
  { label: "Rocky Mountain College", value: "Rocky_Mountain_College", level: 598 },
  { label: "Roger Williams University", value: "Roger_Williams_University", level: 599 },
  { label: "Rollins College", value: "Rollins_College", level: 600 },
  { label: "Roosevelt University", value: "Roosevelt_University", level: 601 },
  { label: "Rose-Hulman Institute Of Technology", value: "Rose-Hulman_Institute_Of_Technology", level: 602 },
  { label: "Rowan University", value: "Rowan_University", level: 603 },
  { label: "Rutgers University", value: "Rutgers_University", level: 604 },
  { label: "Rutgers University-Camden", value: "Rutgers_University-Camden", level: 605 },
  { label: "Rutgers University-New Brunswick", value: "Rutgers_University-New_Brunswick", level: 606 },
  { label: "Rutgers University-Newark", value: "Rutgers_University-Newark", level: 607 },
  { label: "Sacred Heart University", value: "Sacred_Heart_University", level: 608 },
  { label: "Saginaw Valley State University", value: "Saginaw_Valley_State_University", level: 609 },
  { label: "Saint Anselm College", value: "Saint_Anselm_College", level: 610 },
  { label: "Saint Francis University", value: "Saint_Francis_University", level: 611 },
  { label: "Saint John’S University", value: "Saint_John’S_University", level: 612 },
  { label: "Saint Joseph’S University", value: "Saint_Joseph’S_University", level: 613 },
  { label: "Saint Louis University", value: "Saint_Louis_University", level: 614 },
  { label: "Saint Martin’S University", value: "Saint_Martin’S_University", level: 615 },
  { label: "Saint Mary’S College", value: "Saint_Mary’S_College", level: 616 },
  { label: "Saint Mary’S College Of California", value: "Saint_Mary’S_College_Of_California", level: 617 },
  { label: "Saint Mary’S University Of Minnesota", value: "Saint_Mary’S_University_Of_Minnesota", level: 618 },
  { label: "Saint Michael’S College", value: "Saint_Michael’S_College", level: 619 },
  { label: "Saint Peter’S University", value: "Saint_Peter’S_University", level: 620 },
  { label: "Saint Vincent College", value: "Saint_Vincent_College", level: 621 },
  { label: "Saint Xavier University", value: "Saint_Xavier_University", level: 622 },
  { label: "Salem College", value: "Salem_College", level: 623 },
  { label: "Salem State University", value: "Salem_State_University", level: 624 },
  { label: "Salisbury University", value: "Salisbury_University", level: 625 },
  { label: "Salve Regina University", value: "Salve_Regina_University", level: 626 },
  { label: "Sam Houston State University", value: "Sam_Houston_State_University", level: 627 },
  { label: "Samford University", value: "Samford_University", level: 628 },
  { label: "San Diego State University", value: "San_Diego_State_University", level: 629 },
  { label: "San Francisco State University", value: "San_Francisco_State_University", level: 630 },
  { label: "San Jose City College", value: "San_Jose_City_College", level: 631 },
  { label: "San Jose State University", value: "San_Jose_State_University", level: 632 },
  { label: "Santa Clara University", value: "Santa_Clara_University", level: 633 },
  { label: "Sarah Lawrence College", value: "Sarah_Lawrence_College", level: 634 },
  { label: "Savannah College Of Art And Design", value: "Savannah_College_Of_Art_And_Design", level: 635 },
  { label: "Savannah State University", value: "Savannah_State_University", level: 636 },
  { label: "School Of The Art Institute Of Chicago", value: "School_Of_The_Art_Institute_Of_Chicago", level: 637 },
  { label: "Schreiner University", value: "Schreiner_University", level: 638 },
  { label: "Scripps College", value: "Scripps_College", level: 639 },
  { label: "Seattle Colleges", value: "Seattle_Colleges", level: 640 },
  { label: "Seattle Pacific University", value: "Seattle_Pacific_University", level: 641 },
  { label: "Seattle University", value: "Seattle_University", level: 642 },
  { label: "Seton Hall University", value: "Seton_Hall_University", level: 643 },
  { label: "Seton Hill University", value: "Seton_Hill_University", level: 644 },
  { label: "Sewanee-The University Of The South", value: "Sewanee-The_University_Of_The_South", level: 645 },
  { label: "Shaw University", value: "Shaw_University", level: 646 },
  { label: "Shawnee State University", value: "Shawnee_State_University", level: 647 },
  { label: "Shenandoah University", value: "Shenandoah_University", level: 648 },
  { label: "Shepherd University", value: "Shepherd_University", level: 649 },
  { label: "Shippensburg University", value: "Shippensburg_University", level: 650 },
  { label: "Siena College", value: "Siena_College", level: 651 },
  { label: "Simmons College", value: "Simmons_College", level: 652 },
  { label: "Simpson College", value: "Simpson_College", level: 653 },
  { label: "Skidmore College", value: "Skidmore_College", level: 654 },
  { label: "Slippery Rock University", value: "Slippery_Rock_University", level: 655 },
  { label: "Smith College", value: "Smith_College", level: 656 },
  { label: "Sonoma State University", value: "Sonoma_State_University", level: 657 },
  { label: "South Carolina State University", value: "South_Carolina_State_University", level: 658 },
  { label: "South Dakota School Of Mines And Technology", value: "South_Dakota_School_Of_Mines_And_Technology", level: 659 },
  { label: "South Dakota State University", value: "South_Dakota_State_University", level: 660 },
  { label: "South Orange County Community College", value: "South_Orange_County_Community_College", level: 661 },
  { label: "Southeast Missouri State University", value: "Southeast_Missouri_State_University", level: 662 },
  { label: "Southeastern Louisiana University", value: "Southeastern_Louisiana_University", level: 663 },
  { label: "Southeastern Oklahoma State University", value: "Southeastern_Oklahoma_State_University", level: 664 },
  { label: "Southern Adventist University", value: "Southern_Adventist_University", level: 665 },
  { label: "Southern Arkansas University", value: "Southern_Arkansas_University", level: 666 },
  { label: "Southern Connecticut State University", value: "Southern_Connecticut_State_University", level: 667 },
  { label: "Southern Illinois University Carbondale", value: "Southern_Illinois_University_Carbondale", level: 668 },
  { label: "Southern Illinois University Edwardsville", value: "Southern_Illinois_University_Edwardsville", level: 669 },
  { label: "Southern Methodist University", value: "Southern_Methodist_University", level: 670 },
  { label: "Southern Nazarene University", value: "Southern_Nazarene_University", level: 671 },
  { label: "Southern Oregon University", value: "Southern_Oregon_University", level: 672 },
  { label: "Southern University and A&M College", value: "Southern_University_and_A&M_College", level: 673 },
  { label: "Southern Utah University", value: "Southern_Utah_University", level: 674 },
  { label: "Southern Wesleyan University", value: "Southern_Wesleyan_University", level: 675 },
  { label: "Southwest Baptist University", value: "Southwest_Baptist_University", level: 676 },
  { label: "Southwest Minnesota State University", value: "Southwest_Minnesota_State_University", level: 677 },
  { label: "Southwestern Oklahoma State University", value: "Southwestern_Oklahoma_State_University", level: 678 },
  { label: "Southwestern University", value: "Southwestern_University", level: 679 },
  { label: "Spalding University", value: "Spalding_University", level: 680 },
  { label: "Spelman College", value: "Spelman_College", level: 681 },
  { label: "Spring Arbor University", value: "Spring_Arbor_University", level: 682 },
  { label: "Spring Hill College", value: "Spring_Hill_College", level: 683 },
  { label: "St Ambrose University", value: "St_Ambrose_University", level: 684 },
  { label: "St Bonaventure University", value: "St_Bonaventure_University", level: 685 },
  { label: "St Catherine University", value: "St_Catherine_University", level: 686 },
  { label: "St Cloud State University", value: "St_Cloud_State_University", level: 687 },
  { label: "St Edward’S University", value: "St_Edward’S_University", level: 688 },
  { label: "St Francis College", value: "St_Francis_College", level: 689 },
  { label: "St John Fisher College", value: "St_John_Fisher_College", level: 690 },
  { label: "St John’S University-New York", value: "St_John’S_University-New_York", level: 691 },
  { label: "St Joseph’S College-New York", value: "St_Joseph’S_College-New_York", level: 692 },
  { label: "St Lawrence University", value: "St_Lawrence_University", level: 693 },
  { label: "St Mary’S College Of Maryland", value: "St_Mary’S_College_Of_Maryland", level: 694 },
  { label: "St Mary’S University", value: "St_Mary’S_University", level: 695 },
  { label: "St Norbert College", value: "St_Norbert_College", level: 696 },
  { label: "St Olaf College", value: "St_Olaf_College", level: 697 },
  { label: "St Thomas Aquinas College", value: "St_Thomas_Aquinas_College", level: 698 },
  { label: "St. Cloud State University", value: "St._Cloud_State_University", level: 699 },
  { label: "Stanford University", value: "Stanford_University", level: 700 },
  { label: "State University of New York at Albany", value: "State_University_of_New_York_at_Albany", level: 701 },
  { label: "State University of New York at Binghamton", value: "State_University_of_New_York_at_Binghamton", level: 702 },
  { label: "Stephen F. Austin State University", value: "Stephen_F._Austin_State_University", level: 703 },
  { label: "Stetson University", value: "Stetson_University", level: 704 },
  { label: "Stevens Institute Of Technology", value: "Stevens_Institute_Of_Technology", level: 705 },
  { label: "Stevenson University", value: "Stevenson_University", level: 706 },
  { label: "Stockton University", value: "Stockton_University", level: 707 },
  { label: "Stonehill College", value: "Stonehill_College", level: 708 },
  { label: "Stony Brook University", value: "Stony_Brook_University", level: 709 },
  { label: "Suffolk University", value: "Suffolk_University", level: 710 },
  { label: "Suny At Fredonia", value: "Suny_At_Fredonia", level: 711 },
  { label: "Suny Binghamton University", value: "Suny_Binghamton_University", level: 712 },
  { label: "Suny Buffalo State", value: "Suny_Buffalo_State", level: 713 },
  { label: "Suny College At Brockport", value: "Suny_College_At_Brockport", level: 714 },
  { label: "Suny College At Old Westbury", value: "Suny_College_At_Old_Westbury", level: 715 },
  { label: "Suny College Of Environmental Science And Forestry", value: "Suny_College_Of_Environmental_Science_And_Forestry", level: 716 },
  { label: "Suny Cortland", value: "Suny_Cortland", level: 717 },
  { label: "Suny Farmingdale State College", value: "Suny_Farmingdale_State_College", level: 718 },
  { label: "Suny Geneseo", value: "Suny_Geneseo", level: 719 },
  { label: "Suny Maritime College", value: "Suny_Maritime_College", level: 720 },
  { label: "Suny New Paltz", value: "Suny_New_Paltz", level: 721 },
  { label: "Suny Oneonta", value: "Suny_Oneonta", level: 722 },
  { label: "Suny Oswego", value: "Suny_Oswego", level: 723 },
  { label: "Suny Plattsburgh", value: "Suny_Plattsburgh", level: 724 },
  { label: "Suny Potsdam", value: "Suny_Potsdam", level: 725 },
  { label: "Suny Purchase College", value: "Suny_Purchase_College", level: 726 },
  { label: "Suny University At Albany", value: "Suny_University_At_Albany", level: 727 },
  { label: "Suolk University", value: "Suolk_University", level: 728 },
  { label: "Susquehanna University", value: "Susquehanna_University", level: 729 },
  { label: "Swarthmore College", value: "Swarthmore_College", level: 730 },
  { label: "Syracuse University", value: "Syracuse_University", level: 731 },
  { label: "Tarleton State University", value: "Tarleton_State_University", level: 732 },
  { label: "Taylor University", value: "Taylor_University", level: 733 },
  { label: "Temple University", value: "Temple_University", level: 734 },
  { label: "Tennessee State University", value: "Tennessee_State_University", level: 735 },
  { label: "Tennessee Technological University", value: "Tennessee_Technological_University", level: 736 },
  { label: "Texas A&M International University", value: "Texas_A&M_International_University", level: 737 },
  { label: "Texas A&M University", value: "Texas_A&M_University", level: 738 },
  { label: "Texas A&M University-College Station", value: "Texas_A&M_University-College_Station", level: 739 },
  { label: "Texas A&M University-Commerce", value: "Texas_A&M_University-Commerce", level: 740 },
  { label: "Texas A&M University-Corpus Christi", value: "Texas_A&M_University-Corpus_Christi", level: 741 },
  { label: "Texas A&M University-Kingsville", value: "Texas_A&M_University-Kingsville", level: 742 },
  { label: "Texas Christian University", value: "Texas_Christian_University", level: 743 },
  { label: "Texas Lutheran University", value: "Texas_Lutheran_University", level: 744 },
  { label: "Texas Southern University", value: "Texas_Southern_University", level: 745 },
  { label: "Texas State University", value: "Texas_State_University", level: 746 },
  { label: "Texas Tech University", value: "Texas_Tech_University", level: 747 },
  { label: "Texas Woman’S University", value: "Texas_Woman’S_University", level: 748 },
  {
    label: "The Citadel, The Military College Of South Carolina",
    value: "The_Citadel,_The_Military_College_Of_South_Carolina",
    level: 749,
  },
  { label: "The College Of New Jersey", value: "The_College_Of_New_Jersey", level: 750 },
  { label: "The College Of Saint Rose", value: "The_College_Of_Saint_Rose", level: 751 },
  { label: "The College Of St Scholastica", value: "The_College_Of_St_Scholastica", level: 752 },
  { label: "The College Of Wooster", value: "The_College_Of_Wooster", level: 753 },
  { label: "The Evergreen State College", value: "The_Evergreen_State_College", level: 754 },
  { label: "The Master’S University", value: "The_Master’S_University", level: 755 },
  { label: "The New School", value: "The_New_School", level: 756 },
  { label: "The University Of Montana-Western", value: "The_University_Of_Montana-Western", level: 757 },
  { label: "The University Of Tennessee-Chattanooga", value: "The_University_Of_Tennessee-Chattanooga", level: 758 },
  { label: "The University Of Tennessee-Knoxville", value: "The_University_Of_Tennessee-Knoxville", level: 759 },
  { label: "The University Of Tennessee-Martin", value: "The_University_Of_Tennessee-Martin", level: 760 },
  { label: "The University Of The Arts", value: "The_University_Of_The_Arts", level: 761 },
  { label: "Thomas College", value: "Thomas_College", level: 762 },
  { label: "Thomas More College", value: "Thomas_More_College", level: 763 },
  { label: "Towson University", value: "Towson_University", level: 764 },
  { label: "Transylvania University", value: "Transylvania_University", level: 765 },
  { label: "Trevecca Nazarene University", value: "Trevecca_Nazarene_University", level: 766 },
  { label: "Trine University", value: "Trine_University", level: 767 },
  { label: "Trinity Christian College", value: "Trinity_Christian_College", level: 768 },
  { label: "Trinity College", value: "Trinity_College", level: 769 },
  { label: "Trinity University", value: "Trinity_University", level: 770 },
  { label: "Trinity Washington University", value: "Trinity_Washington_University", level: 771 },
  { label: "Truman State University", value: "Truman_State_University", level: 772 },
  { label: "Tufts University", value: "Tufts_University", level: 773 },
  { label: "Tulane University", value: "Tulane_University", level: 774 },
  { label: "Tusculum College", value: "Tusculum_College", level: 775 },
  { label: "Tuskegee University", value: "Tuskegee_University", level: 776 },
  { label: "Union College", value: "Union_College", level: 777 },
  { label: "Union University", value: "Union_University", level: 778 },
  { label: "University at Buffalo", value: "University_at_Buffalo", level: 779 },
  { label: "University of Alabama", value: "University_of_Alabama", level: 780 },
  { label: "University of Alabama at Birmingham", value: "University_of_Alabama_at_Birmingham", level: 781 },
  { label: "University of Alabama in Huntsville", value: "University_of_Alabama_in_Huntsville", level: 782 },
  { label: "University of Alabama, Birmingham", value: "University_of_Alabama,_Birmingham", level: 783 },
  { label: "University of Alaska Anchorage", value: "University_of_Alaska_Anchorage", level: 784 },
  { label: "University of Alaska Fairbanks", value: "University_of_Alaska_Fairbanks", level: 785 },
  { label: "University of Alaska Southeast", value: "University_of_Alaska_Southeast", level: 786 },
  { label: "University of Arizona", value: "University_of_Arizona", level: 787 },
  { label: "University of Arkansas", value: "University_of_Arkansas", level: 788 },
  { label: "University Of Arkansas At Little Rock", value: "University_Of_Arkansas_At_Little_Rock", level: 789 },
  { label: "University of Baltimore", value: "University_of_Baltimore", level: 790 },
  { label: "University of Bridgeport", value: "University_of_Bridgeport", level: 791 },
  { label: "University of California, Berkeley", value: "University_of_California,_Berkeley", level: 792 },
  { label: "University of California, Davis", value: "University_of_California,_Davis", level: 793 },
  { label: "University of California, Irvine", value: "University_of_California,_Irvine", level: 794 },
  { label: "University of California, Irvine (UCI)", value: "University_of_California,_Irvine_(UCI)", level: 795 },
  { label: "University of California, Los Angeles", value: "University_of_California,_Los_Angeles", level: 796 },
  { label: "University of California, Los Angeles (UCLA)", value: "University_of_California,_Los_Angeles_(UCLA)", level: 797 },
  { label: "University of California, Riverside", value: "University_of_California,_Riverside", level: 798 },
  { label: "University of California, San Diego", value: "University_of_California,_San_Diego", level: 799 },
  { label: "University of California, San Diego (UCSD)", value: "University_of_California,_San_Diego_(UCSD)", level: 800 },
  { label: "University of California, Santa Barbara", value: "University_of_California,_Santa_Barbara", level: 801 },
  { label: "University of California, Santa Cruz", value: "University_of_California,_Santa_Cruz", level: 802 },
  { label: "University of Central Arkansas", value: "University_of_Central_Arkansas", level: 803 },
  { label: "University of Central Florida", value: "University_of_Central_Florida", level: 804 },
  { label: "University of Central Missouri", value: "University_of_Central_Missouri", level: 805 },
  { label: "University Of Central Oklahoma", value: "University_Of_Central_Oklahoma", level: 806 },
  { label: "University Of Charleston", value: "University_Of_Charleston", level: 807 },
  { label: "University of Chicago", value: "University_of_Chicago", level: 808 },
  { label: "University Of Cincinnati-Uptown", value: "University_Of_Cincinnati-Uptown", level: 809 },
  { label: "University of Colorado Boulder", value: "University_of_Colorado_Boulder", level: 810 },
  { label: "University of Colorado Colorado Springs", value: "University_of_Colorado_Colorado_Springs", level: 811 },
  { label: "University of Colorado Denver", value: "University_of_Colorado_Denver", level: 812 },
  { label: "University of Connecticut", value: "University_of_Connecticut", level: 813 },
  { label: "University Of Dallas", value: "University_Of_Dallas", level: 814 },
  { label: "University Of Dayton", value: "University_Of_Dayton", level: 815 },
  { label: "University of Delaware", value: "University_of_Delaware", level: 816 },
  { label: "University of Denver", value: "University_of_Denver", level: 817 },
  { label: "University Of Detroit Mercy", value: "University_Of_Detroit_Mercy", level: 818 },
  { label: "University of District of Columbia", value: "University_of_District_of_Columbia", level: 819 },
  { label: "University Of Dubuque", value: "University_Of_Dubuque", level: 820 },
  { label: "University Of Evansville", value: "University_Of_Evansville", level: 821 },
  { label: "University Of Findlay", value: "University_Of_Findlay", level: 822 },
  { label: "University of Florida", value: "University_of_Florida", level: 823 },
  { label: "University of Georgia", value: "University_of_Georgia", level: 824 },
  { label: "University of Hartford", value: "University_of_Hartford", level: 825 },
  { label: "University Of Hawai‘I At Hilo", value: "University_Of_Hawai‘I_At_Hilo", level: 826 },
  { label: "University Of Hawai‘I At M¯Anoa", value: "University_Of_Hawai‘I_At_M¯Anoa", level: 827 },
  { label: "University of Hawaii at Manoa", value: "University_of_Hawaii_at_Manoa", level: 828 },
  { label: "University of Houston", value: "University_of_Houston", level: 829 },
  { label: "University Of Houston-Downtown", value: "University_Of_Houston-Downtown", level: 830 },
  { label: "University of Idaho", value: "University_of_Idaho", level: 831 },
  { label: "University Of Illinois At Chicago", value: "University_Of_Illinois_At_Chicago", level: 832 },
  { label: "University of Illinois at Urbana-Champaign", value: "University_of_Illinois_at_Urbana-Champaign", level: 833 },
  { label: "University of Illinois at Urbana–Champaign", value: "University_of_Illinois_at_Urbana–Champaign", level: 834 },
  { label: "University Of Indianapolis", value: "University_Of_Indianapolis", level: 835 },
  { label: "University of Iowa", value: "University_of_Iowa", level: 836 },
  { label: "University Of Jamestown", value: "University_Of_Jamestown", level: 837 },
  { label: "University of Kansas", value: "University_of_Kansas", level: 838 },
  { label: "University of Kentucky", value: "University_of_Kentucky", level: 839 },
  { label: "University Of La Verne", value: "University_Of_La_Verne", level: 840 },
  { label: "University of Louisiana at Lafayette", value: "University_of_Louisiana_at_Lafayette", level: 841 },
  { label: "University Of Louisiana At Monroe", value: "University_Of_Louisiana_At_Monroe", level: 842 },
  { label: "University of Louisville", value: "University_of_Louisville", level: 843 },
  { label: "University of Maine", value: "University_of_Maine", level: 844 },
  { label: "University Of Maine At Farmington", value: "University_Of_Maine_At_Farmington", level: 845 },
  { label: "University Of Mary", value: "University_Of_Mary", level: 846 },
  { label: "University Of Mary Hardin-Baylor", value: "University_Of_Mary_Hardin-Baylor", level: 847 },
  { label: "University of Mary Washington", value: "University_of_Mary_Washington", level: 848 },
  { label: "University of Maryland", value: "University_of_Maryland", level: 849 },
  { label: "University Of Maryland Eastern Shore", value: "University_Of_Maryland_Eastern_Shore", level: 850 },
  { label: "University of Maryland, Baltimore County", value: "University_of_Maryland,_Baltimore_County", level: 851 },
  { label: "University of Maryland, College Park", value: "University_of_Maryland,_College_Park", level: 852 },
  { label: "University of Massachusetts Amherst", value: "University_of_Massachusetts_Amherst", level: 853 },
  { label: "University of Massachusetts Amherst Campus", value: "University_of_Massachusetts_Amherst_Campus", level: 854 },
  { label: "University Of Massachusetts Boston", value: "University_Of_Massachusetts_Boston", level: 855 },
  { label: "University Of Massachusetts Dartmouth", value: "University_Of_Massachusetts_Dartmouth", level: 856 },
  { label: "University Of Massachusetts Lowell", value: "University_Of_Massachusetts_Lowell", level: 857 },
  { label: "University of Massachusetts Mt. Ida Campus", value: "University_of_Massachusetts_Mt._Ida_Campus", level: 858 },
  { label: "University of Memphis", value: "University_of_Memphis", level: 859 },
  { label: "University of Miami", value: "University_of_Miami", level: 860 },
  { label: "University of Michigan", value: "University_of_Michigan", level: 861 },
  { label: "University Of Michigan-Ann Arbor", value: "University_Of_Michigan-Ann_Arbor", level: 862 },
  { label: "University Of Michigan-Dearborn", value: "University_Of_Michigan-Dearborn", level: 863 },
  { label: "University Of Michigan-Flint", value: "University_Of_Michigan-Flint", level: 864 },
  { label: "University of Minnesota Duluth", value: "University_of_Minnesota_Duluth", level: 865 },
  { label: "University Of Minnesota Morris", value: "University_Of_Minnesota_Morris", level: 866 },
  { label: "University of Minnesota Twin Cities", value: "University_of_Minnesota_Twin_Cities", level: 867 },
  { label: "University of Mississippi", value: "University_of_Mississippi", level: 868 },
  { label: "University of Missouri", value: "University_of_Missouri", level: 869 },
  { label: "University Of Missouri-Columbia", value: "University_Of_Missouri-Columbia", level: 870 },
  { label: "University of Missouri-Kansas City", value: "University_of_Missouri-Kansas_City", level: 871 },
  { label: "University Of Missouri-St Louis", value: "University_Of_Missouri-St_Louis", level: 872 },
  { label: "University Of Mobile", value: "University_Of_Mobile", level: 873 },
  { label: "University of Montana", value: "University_of_Montana", level: 874 },
  { label: "University of Montana Western", value: "University_of_Montana_Western", level: 875 },
  { label: "University of Montevallo", value: "University_of_Montevallo", level: 876 },
  { label: "University Of Mount Olive", value: "University_Of_Mount_Olive", level: 877 },
  { label: "University Of Mount Union", value: "University_Of_Mount_Union", level: 878 },
  { label: "University Of Nebraska At Kearney", value: "University_Of_Nebraska_At_Kearney", level: 879 },
  { label: "University Of Nebraska Omaha", value: "University_Of_Nebraska_Omaha", level: 880 },
  { label: "University of Nebraska-Lincoln", value: "University_of_Nebraska-Lincoln", level: 881 },
  { label: "University of Nevada, Las Vegas", value: "University_of_Nevada,_Las_Vegas", level: 882 },
  { label: "University of Nevada, Reno", value: "University_of_Nevada,_Reno", level: 883 },
  { label: "University Of New England", value: "University_Of_New_England", level: 884 },
  { label: "University of New Hampshire", value: "University_of_New_Hampshire", level: 885 },
  { label: "University Of New Hampshire At Manchester", value: "University_Of_New_Hampshire_At_Manchester", level: 886 },
  { label: "University Of New Haven", value: "University_Of_New_Haven", level: 887 },
  { label: "University of New Mexico", value: "University_of_New_Mexico", level: 888 },
  { label: "University of New Orleans", value: "University_of_New_Orleans", level: 889 },
  { label: "University of North Alabama", value: "University_of_North_Alabama", level: 890 },
  { label: "University Of North Carolina At Asheville", value: "University_Of_North_Carolina_At_Asheville", level: 891 },
  { label: "University of North Carolina at Chapel Hill", value: "University_of_North_Carolina_at_Chapel_Hill", level: 892 },
  { label: "University of North Carolina at Charlotte", value: "University_of_North_Carolina_at_Charlotte", level: 893 },
  { label: "University Of North Carolina At Greensboro", value: "University_Of_North_Carolina_At_Greensboro", level: 894 },
  { label: "University Of North Carolina At Pembroke", value: "University_Of_North_Carolina_At_Pembroke", level: 895 },
  { label: "University of North Carolina Wilmington", value: "University_of_North_Carolina_Wilmington", level: 896 },
  { label: "University of North Dakota", value: "University_of_North_Dakota", level: 897 },
  { label: "University of North Florida", value: "University_of_North_Florida", level: 898 },
  { label: "University of North Texas", value: "University_of_North_Texas", level: 899 },
  { label: "University of Northern Colorado", value: "University_of_Northern_Colorado", level: 900 },
  { label: "University Of Northern Iowa", value: "University_Of_Northern_Iowa", level: 901 },
  { label: "University of Notre Dame", value: "University_of_Notre_Dame", level: 902 },
  { label: "University of Oklahoma", value: "University_of_Oklahoma", level: 903 },
  { label: "University of Oregon", value: "University_of_Oregon", level: 904 },
  { label: "University of Pennsylvania", value: "University_of_Pennsylvania", level: 905 },
  { label: "University of Pittsburgh", value: "University_of_Pittsburgh", level: 906 },
  { label: "University Of Pittsburgh-Bradford", value: "University_Of_Pittsburgh-Bradford", level: 907 },
  { label: "University Of Pittsburgh-Greensburg", value: "University_Of_Pittsburgh-Greensburg", level: 908 },
  { label: "University Of Pittsburgh-Johnstown", value: "University_Of_Pittsburgh-Johnstown", level: 909 },
  { label: "University Of Pittsburgh-Pittsburgh Campus", value: "University_Of_Pittsburgh-Pittsburgh_Campus", level: 910 },
  { label: "University of Portland", value: "University_of_Portland", level: 911 },
  { label: "University Of Puget Sound", value: "University_Of_Puget_Sound", level: 912 },
  { label: "University Of Redlands", value: "University_Of_Redlands", level: 913 },
  { label: "University of Rhode Island", value: "University_of_Rhode_Island", level: 914 },
  { label: "University of Richmond", value: "University_of_Richmond", level: 915 },
  { label: "University of Rochester", value: "University_of_Rochester", level: 916 },
  { label: "University Of Saint Francis", value: "University_Of_Saint_Francis", level: 917 },
  { label: "University Of Saint Joseph", value: "University_Of_Saint_Joseph", level: 918 },
  { label: "University Of San Diego", value: "University_Of_San_Diego", level: 919 },
  { label: "University of San Francisco", value: "University_of_San_Francisco", level: 920 },
  { label: "University Of Science And Arts Of Oklahoma", value: "University_Of_Science_And_Arts_Of_Oklahoma", level: 921 },
  { label: "University Of Scranton", value: "University_Of_Scranton", level: 922 },
  { label: "University Of Sioux Falls", value: "University_Of_Sioux_Falls", level: 923 },
  { label: "University of South Alabama", value: "University_of_South_Alabama", level: 924 },
  { label: "University of South Carolina", value: "University_of_South_Carolina", level: 925 },
  { label: "University Of South Carolina-Aiken", value: "University_Of_South_Carolina-Aiken", level: 926 },
  { label: "University Of South Carolina-Columbia", value: "University_Of_South_Carolina-Columbia", level: 927 },
  { label: "University Of South Carolina-Upstate", value: "University_Of_South_Carolina-Upstate", level: 928 },
  { label: "University of South Dakota", value: "University_of_South_Dakota", level: 929 },
  { label: "University of South Florida", value: "University_of_South_Florida", level: 930 },
  { label: "University Of South Florida-St Petersburg", value: "University_Of_South_Florida-St_Petersburg", level: 931 },
  { label: "University of Southern California", value: "University_of_Southern_California", level: 932 },
  { label: "University Of Southern Indiana", value: "University_Of_Southern_Indiana", level: 933 },
  { label: "University Of Southern Maine", value: "University_Of_Southern_Maine", level: 934 },
  { label: "University of Southern Mississippi", value: "University_of_Southern_Mississippi", level: 935 },
  { label: "University Of St Francis", value: "University_Of_St_Francis", level: 936 },
  { label: "University Of St Thomas", value: "University_Of_St_Thomas", level: 937 },
  { label: "University of St. Thomas", value: "University_of_St._Thomas", level: 938 },
  { label: "University Of Tampa", value: "University_Of_Tampa", level: 939 },
  { label: "University of Tennessee", value: "University_of_Tennessee", level: 940 },
  { label: "University of Texas at Arlington", value: "University_of_Texas_at_Arlington", level: 941 },
  { label: "University of Texas at Austin", value: "University_of_Texas_at_Austin", level: 942 },
  { label: "University Of Texas At Dallas", value: "University_Of_Texas_At_Dallas", level: 943 },
  { label: "University Of Texas At El Paso", value: "University_Of_Texas_At_El_Paso", level: 944 },
  { label: "University of Texas at San Antonio", value: "University_of_Texas_at_San_Antonio", level: 945 },
  { label: "University of Texas Rio Grande Valley", value: "University_of_Texas_Rio_Grande_Valley", level: 946 },
  { label: "University Of The Cumberlands", value: "University_Of_The_Cumberlands", level: 947 },
  { label: "University Of The District Of Columbia", value: "University_Of_The_District_Of_Columbia", level: 948 },
  { label: "University Of The Incarnate Word", value: "University_Of_The_Incarnate_Word", level: 949 },
  { label: "University Of The Pacific", value: "University_Of_The_Pacific", level: 950 },
  { label: "University Of The Sciences", value: "University_Of_The_Sciences", level: 951 },
  { label: "University Of Toledo", value: "University_Of_Toledo", level: 952 },
  { label: "University of Tulsa", value: "University_of_Tulsa", level: 953 },
  { label: "University of Utah", value: "University_of_Utah", level: 954 },
  { label: "University of Vermont", value: "University_of_Vermont", level: 955 },
  { label: "University of Virginia", value: "University_of_Virginia", level: 956 },
  { label: "University of Washington", value: "University_of_Washington", level: 957 },
  { label: "University Of Washington-Bothell", value: "University_Of_Washington-Bothell", level: 958 },
  { label: "University Of Washington-Seattle", value: "University_Of_Washington-Seattle", level: 959 },
  { label: "University Of Washington-Tacoma", value: "University_Of_Washington-Tacoma", level: 960 },
  { label: "University Of West Alabama", value: "University_Of_West_Alabama", level: 961 },
  { label: "University of West Florida", value: "University_of_West_Florida", level: 962 },
  { label: "University Of West Georgia", value: "University_Of_West_Georgia", level: 963 },
  { label: "University of Wisconsin-Eau Claire", value: "University_of_Wisconsin-Eau_Claire", level: 964 },
  { label: "University Of Wisconsin-Green Bay", value: "University_Of_Wisconsin-Green_Bay", level: 965 },
  { label: "University Of Wisconsin-La Crosse", value: "University_Of_Wisconsin-La_Crosse", level: 966 },
  { label: "University of Wisconsin-Madison", value: "University_of_Wisconsin-Madison", level: 967 },
  { label: "University of Wisconsin-Milwaukee", value: "University_of_Wisconsin-Milwaukee", level: 968 },
  { label: "University of Wisconsin-Oshkosh", value: "University_of_Wisconsin-Oshkosh", level: 969 },
  { label: "University Of Wisconsin-Parkside", value: "University_Of_Wisconsin-Parkside", level: 970 },
  { label: "University Of Wisconsin-Platteville", value: "University_Of_Wisconsin-Platteville", level: 971 },
  { label: "University Of Wisconsin-River Falls", value: "University_Of_Wisconsin-River_Falls", level: 972 },
  { label: "University Of Wisconsin-Stevens Point", value: "University_Of_Wisconsin-Stevens_Point", level: 973 },
  { label: "University Of Wisconsin-Stout", value: "University_Of_Wisconsin-Stout", level: 974 },
  { label: "University Of Wisconsin-Whitewater", value: "University_Of_Wisconsin-Whitewater", level: 975 },
  { label: "University of Wyoming", value: "University_of_Wyoming", level: 976 },
  { label: "Ursinus College", value: "Ursinus_College", level: 977 },
  { label: "Utah State University", value: "Utah_State_University", level: 978 },
  { label: "Utah Valley University", value: "Utah_Valley_University", level: 979 },
  { label: "Valdosta State University", value: "Valdosta_State_University", level: 980 },
  { label: "Valparaiso University", value: "Valparaiso_University", level: 981 },
  { label: "Vanderbilt University", value: "Vanderbilt_University", level: 982 },
  { label: "Vanguard University", value: "Vanguard_University", level: 983 },
  { label: "Vassar College", value: "Vassar_College", level: 984 },
  { label: "Villanova University", value: "Villanova_University", level: 985 },
  { label: "Virginia Commonwealth University", value: "Virginia_Commonwealth_University", level: 986 },
  { label: "Virginia Military Institute", value: "Virginia_Military_Institute", level: 987 },
  {
    label: "Virginia Polytechnic Institute And State University",
    value: "Virginia_Polytechnic_Institute_And_State_University",
    level: 988,
  },
  { label: "Virginia State University", value: "Virginia_State_University", level: 989 },
  { label: "Virginia Tech", value: "Virginia_Tech", level: 990 },
  { label: "Virginia Wesleyan College", value: "Virginia_Wesleyan_College", level: 991 },
  { label: "Viterbo University", value: "Viterbo_University", level: 992 },
  { label: "Wagner College", value: "Wagner_College", level: 993 },
  { label: "Wake Forest University", value: "Wake_Forest_University", level: 994 },
  {
    label: "Wall Street Journal/Times Higher Education Us College Rankings: Top 801+",
    value: "Wall_Street_Journal/Times_Higher_Education_Us_College_Rankings:_Top_801+",
    level: 995,
  },
  { label: "Walla Walla University", value: "Walla_Walla_University", level: 996 },
  { label: "Walsh University", value: "Walsh_University", level: 997 },
  { label: "Wartburg College", value: "Wartburg_College", level: 998 },
  { label: "Washburn University", value: "Washburn_University", level: 999 },
  { label: "Washington & Jefferson College", value: "Washington_&_Jefferson_College", level: 1000 },
  { label: "Washington And Lee University", value: "Washington_And_Lee_University", level: 1001 },
  { label: "Washington College", value: "Washington_College", level: 1002 },
  { label: "Washington State University", value: "Washington_State_University", level: 1003 },
  { label: "Washington University In St Louis", value: "Washington_University_In_St_Louis", level: 1004 },
  { label: "Wayne State College", value: "Wayne_State_College", level: 1005 },
  { label: "Wayne State University", value: "Wayne_State_University", level: 1006 },
  { label: "Waynesburg University", value: "Waynesburg_University", level: 1007 },
  { label: "Weber State University", value: "Weber_State_University", level: 1008 },
  { label: "Webster University", value: "Webster_University", level: 1009 },
  { label: "Wellesley College", value: "Wellesley_College", level: 1010 },
  { label: "Wentworth Institute Of Technology", value: "Wentworth_Institute_Of_Technology", level: 1011 },
  { label: "Wesley College", value: "Wesley_College", level: 1012 },
  { label: "Wesleyan University", value: "Wesleyan_University", level: 1013 },
  { label: "West Chester University", value: "West_Chester_University", level: 1014 },
  { label: "West Liberty University", value: "West_Liberty_University", level: 1015 },
  { label: "West Texas A&M University", value: "West_Texas_A&M_University", level: 1016 },
  { label: "West Virginia State University", value: "West_Virginia_State_University", level: 1017 },
  { label: "West Virginia University", value: "West_Virginia_University", level: 1018 },
  { label: "West Virginia University At Parkersburg", value: "West_Virginia_University_At_Parkersburg", level: 1019 },
  { label: "Western Carolina University", value: "Western_Carolina_University", level: 1020 },
  { label: "Western Connecticut State University", value: "Western_Connecticut_State_University", level: 1021 },
  { label: "Western Illinois University", value: "Western_Illinois_University", level: 1022 },
  { label: "Western Kentucky University", value: "Western_Kentucky_University", level: 1023 },
  { label: "Western Michigan University", value: "Western_Michigan_University", level: 1024 },
  { label: "Western New England University", value: "Western_New_England_University", level: 1025 },
  { label: "Western New Mexico University", value: "Western_New_Mexico_University", level: 1026 },
  { label: "Western Oregon University", value: "Western_Oregon_University", level: 1027 },
  { label: "Western State Colorado University", value: "Western_State_Colorado_University", level: 1028 },
  { label: "Western Washington University", value: "Western_Washington_University", level: 1029 },
  { label: "Westfield State University", value: "Westfield_State_University", level: 1030 },
  { label: "Westminster College (Pennsylvania)", value: "Westminster_College_(Pennsylvania)", level: 1031 },
  { label: "Westminster College (Utah)", value: "Westminster_College_(Utah)", level: 1032 },
  { label: "Westmont College", value: "Westmont_College", level: 1033 },
  { label: "Wheaton College (Illinois)", value: "Wheaton_College_(Illinois)", level: 1034 },
  { label: "Wheaton College (Massachusetts)", value: "Wheaton_College_(Massachusetts)", level: 1035 },
  { label: "Whitman College", value: "Whitman_College", level: 1036 },
  { label: "Whittier College", value: "Whittier_College", level: 1037 },
  { label: "Whitworth University", value: "Whitworth_University", level: 1038 },
  { label: "Wichita State University", value: "Wichita_State_University", level: 1039 },
  { label: "Widener University", value: "Widener_University", level: 1040 },
  { label: "Wiley College", value: "Wiley_College", level: 1041 },
  { label: "Wilkes University", value: "Wilkes_University", level: 1042 },
  { label: "Willamette University", value: "Willamette_University", level: 1043 },
  { label: "William & Mary", value: "William_&_Mary", level: 1044 },
  { label: "William Carey University", value: "William_Carey_University", level: 1045 },
  { label: "William Jessup University", value: "William_Jessup_University", level: 1046 },
  { label: "William Jewell College", value: "William_Jewell_College", level: 1047 },
  { label: "William Paterson University Of New Jersey", value: "William_Paterson_University_Of_New_Jersey", level: 1048 },
  { label: "William Penn University", value: "William_Penn_University", level: 1049 },
  { label: "William Woods University", value: "William_Woods_University", level: 1050 },
  { label: "Williams College", value: "Williams_College", level: 1051 },
  { label: "Wilmington College", value: "Wilmington_College", level: 1052 },
  { label: "Wingate University", value: "Wingate_University", level: 1053 },
  { label: "Winona State University", value: "Winona_State_University", level: 1054 },
  { label: "Winston-Salem State University", value: "Winston-Salem_State_University", level: 1055 },
  { label: "Winthrop University", value: "Winthrop_University", level: 1056 },
  { label: "Wisconsin Lutheran College", value: "Wisconsin_Lutheran_College", level: 1057 },
  { label: "Wittenberg University", value: "Wittenberg_University", level: 1058 },
  { label: "Wofford College", value: "Wofford_College", level: 1059 },
  { label: "Worcester Polytechnic Institute", value: "Worcester_Polytechnic_Institute", level: 1060 },
  { label: "Worcester State University", value: "Worcester_State_University", level: 1061 },
  { label: "Wright State University", value: "Wright_State_University", level: 1062 },
  { label: "Wyoming Catholic College", value: "Wyoming_Catholic_College", level: 1063 },
  { label: "Xavier University", value: "Xavier_University", level: 1064 },
  { label: "Xavier University of Louisiana", value: "Xavier_University_of_Louisiana", level: 1065 },
  { label: "Yale University", value: "Yale_University", level: 1066 },
  { label: "Yeshiva University", value: "Yeshiva_University", level: 1067 },
  { label: "York College Of Pennsylvania", value: "York_College_Of_Pennsylvania", level: 1068 },
  { label: "Youngstown State University", value: "Youngstown_State_University", level: 1069 },
  { label: "INTO Colorado State University", value: "INTO_Colorado_State_University", level: 1070 },
  { label: "INTO Drew University", value: "INTO_Drew_University", level: 1071 },
  { label: "INTO George Mason University", value: "INTO_George_Mason_University", level: 1072 },
  { label: "INTO Hofstra University", value: "INTO_Hofstra_University", level: 1073 },
  { label: "INTO Illinois State University", value: "INTO_Illinois_State_University", level: 1074 },
  { label: "INTO Oregon State University", value: "INTO_Oregon_State_University", level: 1075 },
  { label: "INTO St. Louis University", value: "INTO_St._Louis_University", level: 1076 },
  { label: "INTO Suolk University", value: "INTO_Suolk_University", level: 1077 },
  { label: "INTO University of Alabama- Birmingham", value: "INTO_University_of_Alabama-_Birmingham", level: 1078 },
  { label: "INTO University of Arizona", value: "INTO_University_of_Arizona", level: 1079 },
  { label: "Shorelight Adelphi University", value: "Shorelight_Adelphi_University", level: 1080 },
  { label: "Shorelight American University DC", value: "Shorelight_American_University_DC", level: 1081 },
  { label: "Shorelight American University LA", value: "Shorelight_American_University_LA", level: 1082 },
  { label: "Shorelight Auburn University", value: "Shorelight_Auburn_University", level: 1083 },
  { label: "Shorelight Auburn University at Montgomery", value: "Shorelight_Auburn_University_at_Montgomery", level: 1084 },
  { label: "Shorelight Austin College", value: "Shorelight_Austin_College", level: 1085 },
  { label: "Shorelight Barton College", value: "Shorelight_Barton_College", level: 1086 },
  { label: "Shorelight Belmont University", value: "Shorelight_Belmont_University", level: 1087 },
  { label: "Shorelight Blackburn College", value: "Shorelight_Blackburn_College", level: 1088 },
  { label: "Shorelight Bridgewater College", value: "Shorelight_Bridgewater_College", level: 1089 },
  { label: "Shorelight Central Methodist University", value: "Shorelight_Central_Methodist_University", level: 1090 },
  { label: "Shorelight Cleveland State University", value: "Shorelight_Cleveland_State_University", level: 1091 },
  { label: "Shorelight Cornish College of the Arts", value: "Shorelight_Cornish_College_of_the_Arts", level: 1092 },
  { label: "Shorelight Edgewood College", value: "Shorelight_Edgewood_College", level: 1093 },
  { label: "Shorelight Eureka College", value: "Shorelight_Eureka_College", level: 1094 },
  { label: "Shorelight Florida International", value: "Shorelight_Florida_International", level: 1095 },
  { label: "Shorelight Gonzaga University", value: "Shorelight_Gonzaga_University", level: 1096 },
  { label: "Shorelight Jacksonville University", value: "Shorelight_Jacksonville_University", level: 1097 },
  { label: "Shorelight Lakeland University", value: "Shorelight_Lakeland_University", level: 1098 },
  { label: "Shorelight Louisiana State University", value: "Shorelight_Louisiana_State_University", level: 1099 },
  { label: "Shorelight Lycoming College", value: "Shorelight_Lycoming_College", level: 1100 },
  { label: "Shorelight Oklahoma City University", value: "Shorelight_Oklahoma_City_University", level: 1101 },
  { label: "Shorelight Palm Beach Atlantic University", value: "Shorelight_Palm_Beach_Atlantic_University", level: 1102 },
  { label: "Shorelight Randolph College", value: "Shorelight_Randolph_College", level: 1103 },
  { label: "Shorelight Robert Morris University", value: "Shorelight_Robert_Morris_University", level: 1104 },
  { label: "Shorelight Roosevelt University", value: "Shorelight_Roosevelt_University", level: 1105 },
  { label: "Shorelight Schreiner University", value: "Shorelight_Schreiner_University", level: 1106 },
  { label: "Shorelight Southwestern University", value: "Shorelight_Southwestern_University", level: 1107 },
  { label: "Shorelight St. Thomas Aquinas College", value: "Shorelight_St._Thomas_Aquinas_College", level: 1108 },
  { label: "Shorelight Towson University", value: "Shorelight_Towson_University", level: 1109 },
  { label: "Shorelight Tulane University", value: "Shorelight_Tulane_University", level: 1110 },
  { label: "Shorelight Tusculum University", value: "Shorelight_Tusculum_University", level: 1111 },
  { label: "Shorelight University of Central Florida", value: "Shorelight_University_of_Central_Florida", level: 1112 },
  { label: "Shorelight University of Dayton", value: "Shorelight_University_of_Dayton", level: 1113 },
  { label: "Shorleight University of Illinois at Chicago", value: "Shorleight_University_of_Illinois_at_Chicago", level: 1114 },
  { label: "Shorelight University of Illinois Springfield", value: "Shorelight_University_of_Illinois_Springfield", level: 1115 },
  { label: "Shorelight University of Kansas", value: "Shorelight_University_of_Kansas", level: 1116 },
  { label: "Shorelight University of Lynchburg", value: "Shorelight_University_of_Lynchburg", level: 1117 },
  { label: "Shorelight University of Massachusetts - Amherst", value: "Shorelight_University_of_Massachusetts_-_Amherst", level: 1118 },
  { label: "Shorleight University of Massachusetts Boston", value: "Shorleight_University_of_Massachusetts_Boston", level: 1119 },
  { label: "Shorelight University of the Pacific", value: "Shorelight_University_of_the_Pacific", level: 1120 },
  { label: "Shorelight University of Saint Mary", value: "Shorelight_University_of_Saint_Mary", level: 1121 },
  { label: "Shorelight University of South Carolina", value: "Shorelight_University_of_South_Carolina", level: 1122 },
  { label: "Shorelight University of Utah", value: "Shorelight_University_of_Utah", level: 1123 },
  { label: "Shorelight Utah Tech University", value: "Shorelight_Utah_Tech_University", level: 1124 },
  { label: "Shorelight Virginia Wesleyan University", value: "Shorelight_Virginia_Wesleyan_University", level: 1125 },
  { label: "Shorelight Washington & Jefferson College", value: "Shorelight_Washington_&_Jefferson_College", level: 1126 },
  { label: "Shorelight Wentworth Institute of Technology", value: "Shorelight_Wentworth_Institute_of_Technology", level: 1127 },
  { label: "Shorelight Western New England University", value: "Shorelight_Western_New_England_University", level: 1128 },
  { label: "Shorelight Western Oregon University", value: "Shorelight_Western_Oregon_University", level: 1129 },
  { label: "Shorelight Wilson College", value: "Shorelight_Wilson_College", level: 1130 },
  { label: "Shorelight York College of Pennsylvania", value: "Shorelight_York_College_of_Pennsylvania", level: 1131 },
  { label: "Study Group Baylor University", value: "Study_Group_Baylor_University", level: 1132 },
  { label: "Study Group DePaul University", value: "Study_Group_DePaul_University", level: 1133 },
  { label: "Study Group Florida Atlantic University", value: "Study_Group_Florida_Atlantic_University", level: 1134 },
  { label: "Study Group University of Hartford", value: "Study_Group_University_of_Hartford", level: 1135 },
  { label: "Study Group James Madison University", value: "Study_Group_James_Madison_University", level: 1136 },
  { label: "Study Group Lipscomb University", value: "Study_Group_Lipscomb_University", level: 1137 },
  { label: "Study Group Long Island University", value: "Study_Group_Long_Island_University", level: 1138 },
  { label: "Study Group Texas A&M University-Corpus Christi", value: "Study_Group_Texas_A&M_University-Corpus_Christi", level: 1139 },
  { label: "Study Group Western Washington University", value: "Study_Group_Western_Washington_University", level: 1140 },
  { label: "KAPLAN Arizona State University", value: "KAPLAN_Arizona_State_University", level: 1141 },
  { label: "KAPLAN Pace University", value: "KAPLAN_Pace_University", level: 1142 },
  { label: "KAPLAN Simmons University", value: "KAPLAN_Simmons_University", level: 1143 },
  { label: "KAPLAN University of Connecticut", value: "KAPLAN_University_of_Connecticut", level: 1144 },
  { label: "OnCampus Illinois Institute of Technology", value: "OnCampus_Illinois_Institute_of_Technology", level: 1145 },
  { label: "OnCampus Boston Curry College", value: "OnCampus_Boston_Curry_College", level: 1146 },
  { label: "OIEG San Francisco State University", value: "OIEG_San_Francisco_State_University", level: 1147 },
  { label: "Navitas Queens College", value: "Navitas_Queens_College", level: 1148 },
  { label: "Navitas Richard Bland College of William & Mary", value: "Navitas_Richard_Bland_College_of_William_&_Mary", level: 1149 },
  { label: "Navitas Umass-Boston", value: "Navitas_Umass-Boston", level: 1150 },
  { label: "Educo Duke University", value: "Educo_Duke_University", level: 1151 },
  { label: "Educo Duquesne University", value: "Educo_Duquesne_University", level: 1152 },
  { label: "Educo Seattle Pacific University", value: "Educo_Seattle_Pacific_University", level: 1153 },
  { label: "Educo Rose-Hulman Institute of Technology", value: "Educo_Rose-Hulman_Institute_of_Technology", level: 1154 },
  { label: "Educo Stevens Institute of Technology", value: "Educo_Stevens_Institute_of_Technology", level: 1155 },
  { label: "Educo University of Tulsa", value: "Educo_University_of_Tulsa", level: 1156 },
  { label: "Educo University of Maryland, Baltimore County", value: "Educo_University_of_Maryland,_Baltimore_County", level: 1157 },
  { label: "Educo University of Nebraska–Lincoln", value: "Educo_University_of_Nebraska–Lincoln", level: 1158 },
  { label: "Adelaide College of the Arts", value: "Adelaide_College_of_the_Arts", level: 1159 },
  { label: "Australian Catholic University", value: "Australian_Catholic_University", level: 1160 },
  { label: "Australian College of Applied Psychology", value: "Australian_College_of_Applied_Psychology", level: 1161 },
  {
    label: "Australian College of Information Technology and Institute of Film and Television",
    value: "Australian_College_of_Information_Technology_and_Institute_of_Film_and_Television",
    level: 1162,
  },
  { label: "Australian Correspondence Schools", value: "Australian_Correspondence_Schools", level: 1163 },
  { label: "Australian Film, Television and Radio School", value: "Australian_Film,_Television_and_Radio_School", level: 1164 },
  { label: "Australian Lutheran College", value: "Australian_Lutheran_College", level: 1165 },
  { label: "Australian Maritime College", value: "Australian_Maritime_College", level: 1166 },
  { label: "Australian National College of Beauty", value: "Australian_National_College_of_Beauty", level: 1167 },
  { label: "Australian National University", value: "Australian_National_University", level: 1168 },
  { label: "Avondale College", value: "Avondale_College", level: 1169 },
  {
    label: "Batchelor Institute of Indigenous Tertiary Education",
    value: "Batchelor_Institute_of_Indigenous_Tertiary_Education",
    level: 1170,
  },
  { label: "Billy Blue College of Design", value: "Billy_Blue_College_of_Design", level: 1171 },
  {
    label: "Blue Mountains International Hotel Management School",
    value: "Blue_Mountains_International_Hotel_Management_School",
    level: 1172,
  },
  {
    label: "Blue Mountains International Hotel Management School, Torrens University",
    value: "Blue_Mountains_International_Hotel_Management_School,_Torrens_University",
    level: 1173,
  },
  { label: "Bond University", value: "Bond_University", level: 1174 },
  { label: "Box Hill Institute", value: "Box_Hill_Institute", level: 1175 },
  { label: "Cambridge International College", value: "Cambridge_International_College", level: 1176 },
  { label: "Campion College Australia", value: "Campion_College_Australia", level: 1177 },
  { label: "Canberra Institute of Technology", value: "Canberra_Institute_of_Technology", level: 1178 },
  { label: "Central Queensland University", value: "Central_Queensland_University", level: 1179 },
  { label: "Charles Darwin University", value: "Charles_Darwin_University", level: 1180 },
  { label: "Charles Sturt University", value: "Charles_Sturt_University", level: 1181 },
  { label: "Chisholm Institute", value: "Chisholm_Institute", level: 1182 },
  { label: "Curtin University", value: "Curtin_University", level: 1183 },
  { label: "Curtin University of Technology", value: "Curtin_University_of_Technology", level: 1184 },
  { label: "Deakin University", value: "Deakin_University", level: 1185 },
  { label: "Edith Cowan University", value: "Edith_Cowan_University", level: 1186 },
  { label: "Federation University Australia", value: "Federation_University_Australia", level: 1187 },
  { label: "Flinders University", value: "Flinders_University", level: 1188 },
  { label: "Flinders University of South Australia", value: "Flinders_University_of_South_Australia", level: 1189 },
  { label: "Griffith University", value: "Griffith_University", level: 1190 },
  { label: "Holmes Institute", value: "Holmes_Institute", level: 1191 },
  { label: "Holmesglen Institute", value: "Holmesglen_Institute", level: 1192 },
  { label: "Institute of Technology, Australia", value: "Institute_of_Technology,_Australia", level: 1193 },
  { label: "International College of Hotel Management", value: "International_College_of_Hotel_Management", level: 1194 },
  { label: "James Cook University", value: "James_Cook_University", level: 1195 },
  { label: "James Cook University of North Queensland", value: "James_Cook_University_of_North_Queensland", level: 1196 },
  { label: "Jansen Newman Institute", value: "Jansen_Newman_Institute", level: 1197 },
  { label: "Kaplan Business School", value: "Kaplan_Business_School", level: 1198 },
  { label: "La Trobe University", value: "La_Trobe_University", level: 1199 },
  { label: "Le Cordon Bleu Australia", value: "Le_Cordon_Bleu_Australia", level: 1200 },
  { label: "Macquarie University", value: "Macquarie_University", level: 1201 },
  { label: "Marcus Oldham College", value: "Marcus_Oldham_College", level: 1202 },
  { label: "MEGT Institute", value: "MEGT_Institute", level: 1203 },
  { label: "Melbourne Polytechnic", value: "Melbourne_Polytechnic", level: 1204 },
  { label: "Monash University", value: "Monash_University", level: 1205 },
  { label: "Murdoch University", value: "Murdoch_University", level: 1206 },
  { label: "Nan Tien Institute of Higher Education", value: "Nan_Tien_Institute_of_Higher_Education", level: 1207 },
  { label: "Northern Sydney Institute", value: "Northern_Sydney_Institute", level: 1208 },
  { label: "Queensland University of Technology", value: "Queensland_University_of_Technology", level: 1209 },
  { label: "RMIT University", value: "RMIT_University", level: 1210 },
  { label: "Royal Melbourne Institute of Technology", value: "Royal_Melbourne_Institute_of_Technology", level: 1211 },
  { label: "SAE Institute", value: "SAE_Institute", level: 1212 },
  { label: "South Metropolitan TAFE", value: "South_Metropolitan_TAFE", level: 1213 },
  { label: "Southern Cross University", value: "Southern_Cross_University", level: 1214 },
  { label: "Strathfield College", value: "Strathfield_College", level: 1215 },
  { label: "Swinburne University of Technology", value: "Swinburne_University_of_Technology", level: 1216 },
  { label: "TAFE NSW", value: "TAFE_NSW", level: 1217 },
  { label: "TAFE Queensland", value: "TAFE_Queensland", level: 1218 },
  { label: "Taylors College", value: "Taylors_College", level: 1219 },
  { label: "The Australian National University", value: "The_Australian_National_University", level: 1220 },
  { label: "The University of Adelaide", value: "The_University_of_Adelaide", level: 1221 },
  { label: "The University of Melbourne", value: "The_University_of_Melbourne", level: 1222 },
  { label: "The University of New South Wales", value: "The_University_of_New_South_Wales", level: 1223 },
  { label: "The University of Queensland", value: "The_University_of_Queensland", level: 1224 },
  { label: "The University of Sydney", value: "The_University_of_Sydney", level: 1225 },
  { label: "The University of Western Australia", value: "The_University_of_Western_Australia", level: 1226 },
  { label: "Torrens University Australia", value: "Torrens_University_Australia", level: 1227 },
  { label: "University of Adelaide", value: "University_of_Adelaide", level: 1228 },
  { label: "University of Canberra", value: "University_of_Canberra", level: 1229 },
  { label: "University of Divinity", value: "University_of_Divinity", level: 1230 },
  { label: "University of Melbourne", value: "University_of_Melbourne", level: 1231 },
  { label: "University of New England", value: "University_of_New_England", level: 1232 },
  { label: "University of New South Wales", value: "University_of_New_South_Wales", level: 1233 },
  { label: "University of Newcastle", value: "University_of_Newcastle", level: 1234 },
  { label: "University of Notre Dame Australia", value: "University_of_Notre_Dame_Australia", level: 1235 },
  { label: "University of Queensland", value: "University_of_Queensland", level: 1236 },
  { label: "University of South Australia", value: "University_of_South_Australia", level: 1237 },
  { label: "University of Southern Queensland", value: "University_of_Southern_Queensland", level: 1238 },
  { label: "University of Sydney", value: "University_of_Sydney", level: 1239 },
  { label: "University of Tasmania", value: "University_of_Tasmania", level: 1240 },
  { label: "University of Technology Sydney", value: "University_of_Technology_Sydney", level: 1241 },
  { label: "University of the Sunshine Coast", value: "University_of_the_Sunshine_Coast", level: 1242 },
  { label: "University of Western Australia", value: "University_of_Western_Australia", level: 1243 },
  { label: "University of Western Sydney", value: "University_of_Western_Sydney", level: 1244 },
  { label: "University of Wollongong", value: "University_of_Wollongong", level: 1245 },
  { label: "Victoria University", value: "Victoria_University", level: 1246 },
  { label: "Victoria University of Wellington in Australia", value: "Victoria_University_of_Wellington_in_Australia", level: 1247 },
  { label: "Western Australian Academy of Performing Arts", value: "Western_Australian_Academy_of_Performing_Arts", level: 1248 },
  { label: "Western Sydney University", value: "Western_Sydney_University", level: 1249 },
  { label: "William Angliss Institute", value: "William_Angliss_Institute", level: 1250 },
  {
    label: "Navitas Australian College of Applied Professions - Adelaide Campus",
    value: "Navitas_Australian_College_of_Applied_Professions_-_Adelaide_Campus",
    level: 1251,
  },
  {
    label: "Navitas Australian College of Applied Professions - Brisbane Campus",
    value: "Navitas_Australian_College_of_Applied_Professions_-_Brisbane_Campus",
    level: 1252,
  },
  {
    label: "Navitas Australian College of Applied Professions - Byron Bay Campus",
    value: "Navitas_Australian_College_of_Applied_Professions_-_Byron_Bay_Campus",
    level: 1253,
  },
  {
    label: "Navitas Australian College of Applied Professions - Melbourne Campus",
    value: "Navitas_Australian_College_of_Applied_Professions_-_Melbourne_Campus",
    level: 1254,
  },
  {
    label: "Navitas Australian College of Applied Professions - Perth Campus",
    value: "Navitas_Australian_College_of_Applied_Professions_-_Perth_Campus",
    level: 1255,
  },
  {
    label: "Navitas Australian College of Applied Professions - Sydney Campus",
    value: "Navitas_Australian_College_of_Applied_Professions_-_Sydney_Campus",
    level: 1256,
  },
  { label: "Navitas Curtin College", value: "Navitas_Curtin_College", level: 1257 },
  { label: "Navitas Deakin College - Geelong Campus", value: "Navitas_Deakin_College_-_Geelong_Campus", level: 1258 },
  { label: "Navitas Deakin College - Melbourne Campus", value: "Navitas_Deakin_College_-_Melbourne_Campus", level: 1259 },
  { label: "Navitas Edith Cowan College", value: "Navitas_Edith_Cowan_College", level: 1260 },
  { label: "Navitas Eynesbury College", value: "Navitas_Eynesbury_College", level: 1261 },
  { label: "Navitas Griffith College - Brisbane Campus", value: "Navitas_Griffith_College_-_Brisbane_Campus", level: 1262 },
  { label: "Navitas Griffith College - Gold Coast Campus", value: "Navitas_Griffith_College_-_Gold_Coast_Campus", level: 1263 },
  { label: "Navitas LaTrobe University", value: "Navitas_LaTrobe_University", level: 1264 },
  { label: "Navitas La Trobe University Sydney Campus", value: "Navitas_La_Trobe_University_Sydney_Campus", level: 1265 },
  { label: "Navitas SAE Institute - Adelaide Campus", value: "Navitas_SAE_Institute_-_Adelaide_Campus", level: 1266 },
  { label: "Navitas SAE Institute - Brisbane Campus", value: "Navitas_SAE_Institute_-_Brisbane_Campus", level: 1267 },
  { label: "Navitas SAE Institute - Byron Bay Campus", value: "Navitas_SAE_Institute_-_Byron_Bay_Campus", level: 1268 },
  { label: "Navitas SAE Institute - Melbourne Campus", value: "Navitas_SAE_Institute_-_Melbourne_Campus", level: 1269 },
  { label: "Navitas SAE Institute - Perth Campus", value: "Navitas_SAE_Institute_-_Perth_Campus", level: 1270 },
  { label: "Navitas SAE Institute - Sydney Campus", value: "Navitas_SAE_Institute_-_Sydney_Campus", level: 1271 },
  {
    label: "Navitas SAIBT - South Australian Institute of Business and Technology",
    value: "Navitas_SAIBT_-_South_Australian_Institute_of_Business_and_Technology",
    level: 1272,
  },
  {
    label: "Navitas Sydney Institute of Business and Technology (SIBT)",
    value: "Navitas_Sydney_Institute_of_Business_and_Technology_(SIBT)",
    level: 1273,
  },
  {
    label: "Navitas University of Canberra College (UC College)",
    value: "Navitas_University_of_Canberra_College_(UC_College)",
    level: 1274,
  },
  {
    label: "Navitas University of Plymouth International College (UPIC)",
    value: "Navitas_University_of_Plymouth_International_College_(UPIC)",
    level: 1275,
  },
  {
    label: "Navitas Western Sydney University International College (WSUIC)",
    value: "Navitas_Western_Sydney_University_International_College_(WSUIC)",
    level: 1276,
  },
  {
    label: "Navitas Western Sydney University Sydney City Campu",
    value: "Navitas_Western_Sydney_University_Sydney_City_Campu",
    level: 1277,
  },
  { label: "Study Group Taylors College Sydney", value: "Study_Group_Taylors_College_Sydney", level: 1278 },
  { label: "Study Group The University of Sydney", value: "Study_Group_The_University_of_Sydney", level: 1279 },
  { label: "Educo Southern Cross University - Melbourne", value: "Educo_Southern_Cross_University_-_Melbourne", level: 1280 },
  { label: "Educo Southern Cross University - Perth", value: "Educo_Southern_Cross_University_-_Perth", level: 1281 },
  { label: "Educo Southern Cross University - Sydney", value: "Educo_Southern_Cross_University_-_Sydney", level: 1282 },
  { label: "Educo CIC Higher Education, Melbourne", value: "Educo_CIC_Higher_Education,_Melbourne", level: 1283 },
  { label: "INTO The University of Western Australia", value: "INTO_The_University_of_Western_Australia", level: 1284 },
  { label: "Acadia University", value: "Acadia_University", level: 1285 },
  { label: "Algonquin College", value: "Algonquin_College", level: 1286 },
  {
    label: "All Saints University College of Medicine St Vincent",
    value: "All_Saints_University_College_of_Medicine_St_Vincent",
    level: 1287,
  },
  { label: "Ashton College", value: "Ashton_College", level: 1288 },
  { label: "Assumption University", value: "Assumption_University", level: 1289 },
  { label: "Athabasca University", value: "Athabasca_University", level: 1290 },
  { label: "Augustana University College", value: "Augustana_University_College", level: 1291 },
  { label: "Bishop's University", value: "Bishop's_University", level: 1292 },
  { label: "Booth University College", value: "Booth_University_College", level: 1293 },
  { label: "Bow Valley College", value: "Bow_Valley_College", level: 1294 },
  { label: "Brandon University", value: "Brandon_University", level: 1295 },
  { label: "Brescia College", value: "Brescia_College", level: 1296 },
  { label: "British Columbia Institute of Technology", value: "British_Columbia_Institute_of_Technology", level: 1297 },
  { label: "Brock University", value: "Brock_University", level: 1298 },
  { label: "Camosun College", value: "Camosun_College", level: 1299 },
  { label: "Campion College", value: "Campion_College", level: 1300 },
  { label: "Canadian College of Business & Computers", value: "Canadian_College_of_Business_&_Computers", level: 1301 },
  { label: "Canadian Mennonite University", value: "Canadian_Mennonite_University", level: 1302 },
  { label: "Cape Breton University", value: "Cape_Breton_University", level: 1303 },
  { label: "Capilano University", value: "Capilano_University", level: 1304 },
  { label: "Carleton University", value: "Carleton_University", level: 1305 },
  { label: "Centennial College", value: "Centennial_College", level: 1306 },
  { label: "Collège Boréal", value: "Collège_Boréal", level: 1307 },
  { label: "College of New Caledonia", value: "College_of_New_Caledonia", level: 1308 },
  { label: "College of the Rockies", value: "College_of_the_Rockies", level: 1309 },
  { label: "Columbia College", value: "Columbia_College", level: 1310 },
  { label: "Concordia University", value: "Concordia_University", level: 1311 },
  { label: "Concordia University College of Alberta", value: "Concordia_University_College_of_Alberta", level: 1312 },
  { label: "Conestoga College", value: "Conestoga_College", level: 1313 },
  { label: "Dalhousie University", value: "Dalhousie_University", level: 1314 },
  { label: "DeVry Institute of Technology", value: "DeVry_Institute_of_Technology", level: 1315 },
  { label: "Dominican University College", value: "Dominican_University_College", level: 1316 },
  { label: "Douglas College", value: "Douglas_College", level: 1317 },
  { label: "Durham College", value: "Durham_College", level: 1318 },
  {
    label: "École de technologie supérieure, Université du Québec",
    value: "École_de_technologie_supérieure,_Université_du_Québec",
    level: 1319,
  },
  { label: "École des Hautes Études Commerciales", value: "École_des_Hautes_Études_Commerciales", level: 1320 },
  {
    label: "École nationale d'administration publique, Université du Québec",
    value: "École_nationale_d'administration_publique,_Université_du_Québec",
    level: 1321,
  },
  {
    label: "École Polytechnique de Montréal, Université de Montréal",
    value: "École_Polytechnique_de_Montréal,_Université_de_Montréal",
    level: 1322,
  },
  { label: "Emily Carr University of Art + Design", value: "Emily_Carr_University_of_Art_+_Design", level: 1323 },
  { label: "Fanshawe College", value: "Fanshawe_College", level: 1324 },
  { label: "First Nations University of Canada", value: "First_Nations_University_of_Canada", level: 1325 },
  {
    label: "Fisheries and Marine Institute of Memorial University of Newfoundland",
    value: "Fisheries_and_Marine_Institute_of_Memorial_University_of_Newfoundland",
    level: 1326,
  },
  { label: "George Brown College", value: "George_Brown_College", level: 1327 },
  { label: "Humber College", value: "Humber_College", level: 1328 },
  { label: "Huron University College", value: "Huron_University_College", level: 1329 },
  { label: "Institut Armand-Frappier, Université du Québec", value: "Institut_Armand-Frappier,_Université_du_Québec", level: 1330 },
  {
    label: "Institut National de la Recherche Scientifique (INRS)",
    value: "Institut_National_de_la_Recherche_Scientifique_(INRS)",
    level: 1331,
  },
  {
    label: "Institut National de la Recherche Scientifique, Université du Québec",
    value: "Institut_National_de_la_Recherche_Scientifique,_Université_du_Québec",
    level: 1332,
  },
  { label: "King's College", value: "King's_College", level: 1333 },
  { label: "King's University College", value: "King's_University_College", level: 1334 },
  { label: "Kingston College", value: "Kingston_College", level: 1335 },
  { label: "Kwantleen University College", value: "Kwantleen_University_College", level: 1336 },
  { label: "Lakehead University", value: "Lakehead_University", level: 1337 },
  { label: "Langara College", value: "Langara_College", level: 1338 },
  { label: "Laurentian University of Sudbury", value: "Laurentian_University_of_Sudbury", level: 1339 },
  { label: "Luther College", value: "Luther_College", level: 1340 },
  { label: "MacEwan University", value: "MacEwan_University", level: 1341 },
  { label: "McGill University", value: "McGill_University", level: 1342 },
  { label: "McMaster University", value: "McMaster_University", level: 1343 },
  { label: "Memorial University of Newfoundland", value: "Memorial_University_of_Newfoundland", level: 1344 },
  { label: "Mount Allison University", value: "Mount_Allison_University", level: 1345 },
  { label: "Mount Royal College", value: "Mount_Royal_College", level: 1346 },
  { label: "Mount Saint Vincent University", value: "Mount_Saint_Vincent_University", level: 1347 },
  { label: "Nicola Valley Institute of Technology", value: "Nicola_Valley_Institute_of_Technology", level: 1348 },
  { label: "Nipissing University", value: "Nipissing_University", level: 1349 },
  { label: "North Island College", value: "North_Island_College", level: 1350 },
  { label: "Northern Alberta Institute of Technology", value: "Northern_Alberta_Institute_of_Technology", level: 1351 },
  { label: "Northern Lights College", value: "Northern_Lights_College", level: 1352 },
  { label: "Nova Scotia College of Art and Design", value: "Nova_Scotia_College_of_Art_and_Design", level: 1353 },
  { label: "OCAD University", value: "OCAD_University", level: 1354 },
  { label: "Okanagan University College", value: "Okanagan_University_College", level: 1355 },
  { label: "Pacific International College", value: "Pacific_International_College", level: 1356 },
  { label: "Queen's University", value: "Queen's_University", level: 1357 },
  { label: "Quest University", value: "Quest_University", level: 1358 },
  { label: "Redeemer College", value: "Redeemer_College", level: 1359 },
  { label: "Regent College", value: "Regent_College", level: 1360 },
  { label: "Royal Military College of Canada", value: "Royal_Military_College_of_Canada", level: 1361 },
  { label: "Royal Roads University", value: "Royal_Roads_University", level: 1362 },
  { label: "Ryerson Polytechnic University", value: "Ryerson_Polytechnic_University", level: 1363 },
  { label: "Saskatchewan Indian Federated College", value: "Saskatchewan_Indian_Federated_College", level: 1364 },
  { label: "Sauder School of Business", value: "Sauder_School_of_Business", level: 1365 },
  { label: "Selkirk College", value: "Selkirk_College", level: 1366 },
  { label: "Seneca College", value: "Seneca_College", level: 1367 },
  { label: "Sheridan College", value: "Sheridan_College", level: 1368 },
  { label: "Simon Fraser University", value: "Simon_Fraser_University", level: 1369 },
  { label: "Southern Alberta Institute of Technology", value: "Southern_Alberta_Institute_of_Technology", level: 1370 },
  { label: "St Vincent Medical School", value: "St_Vincent_Medical_School", level: 1371 },
  { label: "St. Anne University", value: "St._Anne_University", level: 1372 },
  { label: "St. Clair College", value: "St._Clair_College", level: 1373 },
  { label: "St. Francis Xavier University", value: "St._Francis_Xavier_University", level: 1374 },
  { label: "St. Jerome's College University", value: "St._Jerome's_College_University", level: 1375 },
  { label: "St. Mary's University", value: "St._Mary's_University", level: 1376 },
  { label: "St. Paul University", value: "St._Paul_University", level: 1377 },
  { label: "St. Thomas University", value: "St._Thomas_University", level: 1378 },
  { label: "Thompson Rivers University", value: "Thompson_Rivers_University", level: 1379 },
  { label: "Trent University", value: "Trent_University", level: 1380 },
  { label: "Trinity Western University", value: "Trinity_Western_University", level: 1381 },
  { label: "Université de Montréal", value: "Université_de_Montréal", level: 1382 },
  { label: "Université de Saint-Boniface", value: "Université_de_Saint-Boniface", level: 1383 },
  { label: "Université de Sherbrooke", value: "Université_de_Sherbrooke", level: 1384 },
  { label: "Université du Québec à Chicoutimi", value: "Université_du_Québec_à_Chicoutimi", level: 1385 },
  { label: "Université du Québec à Montréal", value: "Université_du_Québec_à_Montréal", level: 1386 },
  { label: "Université du Québec à Rimouski", value: "Université_du_Québec_à_Rimouski", level: 1387 },
  { label: "Université du Québec à Trois-Rivières", value: "Université_du_Québec_à_Trois-Rivières", level: 1388 },
  { label: "Université du Québec en Abitibi-Témiscamingue", value: "Université_du_Québec_en_Abitibi-Témiscamingue", level: 1389 },
  { label: "Université du Québec en Outaouais", value: "Université_du_Québec_en_Outaouais", level: 1390 },
  { label: "Université Laval", value: "Université_Laval", level: 1391 },
  { label: "Université TÉLUQ", value: "Université_TÉLUQ", level: 1392 },
  { label: "University Canada West", value: "University_Canada_West", level: 1393 },
  { label: "University of Alberta", value: "University_of_Alberta", level: 1394 },
  { label: "University of British Columbia", value: "University_of_British_Columbia", level: 1395 },
  { label: "University of Calgary", value: "University_of_Calgary", level: 1396 },
  { label: "University of Fredericton", value: "University_of_Fredericton", level: 1397 },
  { label: "University of Guelph", value: "University_of_Guelph", level: 1398 },
  { label: "University of King's College", value: "University_of_King's_College", level: 1399 },
  { label: "University of Lethbridge", value: "University_of_Lethbridge", level: 1400 },
  { label: "University of Manitoba", value: "University_of_Manitoba", level: 1401 },
  { label: "University of Moncton", value: "University_of_Moncton", level: 1402 },
  { label: "University of Moncton, Edmundston", value: "University_of_Moncton,_Edmundston", level: 1403 },
  { label: "University of Moncton, Shippagan", value: "University_of_Moncton,_Shippagan", level: 1404 },
  { label: "University of New Brunswick", value: "University_of_New_Brunswick", level: 1405 },
  { label: "University of New Brunswick, Saint John College", value: "University_of_New_Brunswick,_Saint_John_College", level: 1406 },
  { label: "University of Northern British Columbia", value: "University_of_Northern_British_Columbia", level: 1407 },
  { label: "University of Ontario Institute of Technology", value: "University_of_Ontario_Institute_of_Technology", level: 1408 },
  { label: "University of Ottawa", value: "University_of_Ottawa", level: 1409 },
  { label: "University of Phoenix", value: "University_of_Phoenix", level: 1410 },
  { label: "University of Prince Edward Island", value: "University_of_Prince_Edward_Island", level: 1411 },
  { label: "University of Québec", value: "University_of_Québec", level: 1412 },
  { label: "University of Regina", value: "University_of_Regina", level: 1413 },
  { label: "University of Saskatchewan", value: "University_of_Saskatchewan", level: 1414 },
  { label: "University of St. Michael's College", value: "University_of_St._Michael's_College", level: 1415 },
  { label: "University of Sudbury", value: "University_of_Sudbury", level: 1416 },
  { label: "University of the Fraser Valley", value: "University_of_the_Fraser_Valley", level: 1417 },
  { label: "University of Toronto", value: "University_of_Toronto", level: 1418 },
  { label: "University of Toronto, Mississauga", value: "University_of_Toronto,_Mississauga", level: 1419 },
  { label: "University of Toronto, Scarborough", value: "University_of_Toronto,_Scarborough", level: 1420 },
  { label: "University of Trinity College", value: "University_of_Trinity_College", level: 1421 },
  { label: "University of Victoria", value: "University_of_Victoria", level: 1422 },
  { label: "University of Waterloo", value: "University_of_Waterloo", level: 1423 },
  { label: "University of Western Ontario", value: "University_of_Western_Ontario", level: 1424 },
  { label: "University of Windsor", value: "University_of_Windsor", level: 1425 },
  { label: "University of Winnipeg", value: "University_of_Winnipeg", level: 1426 },
  { label: "Vancouver Community College", value: "Vancouver_Community_College", level: 1427 },
  { label: "Vancouver Island University", value: "Vancouver_Island_University", level: 1428 },
  { label: "Victoria University Toronto, University of Toronto", value: "Victoria_University_Toronto,_University_of_Toronto", level: 1429 },
  { label: "Wilfrid Laurier University", value: "Wilfrid_Laurier_University", level: 1430 },
  { label: "York University", value: "York_University", level: 1431 },
  { label: "Yukon College", value: "Yukon_College", level: 1432 },
  { label: "Navitas FIC Fraser International College", value: "Navitas_FIC_Fraser_International_College", level: 1433 },
  { label: "Navitas ICM International College of Manitoba", value: "Navitas_ICM_International_College_of_Manitoba", level: 1434 },
  {
    label: "Navitas Toronto Metropolitan University International College (TMUIC)",
    value: "Navitas_Toronto_Metropolitan_University_International_College_(TMUIC)",
    level: 1435,
  },
  {
    label: "Navitas Wilfried Laurier International College (WLIC)",
    value: "Navitas_Wilfried_Laurier_International_College_(WLIC)",
    level: 1436,
  },
  { label: "Abertay University", value: "Abertay_University", level: 1437 },
  { label: "Aga Khan University", value: "Aga_Khan_University", level: 1438 },
  { label: "American InterContinental University - London", value: "American_InterContinental_University_-_London", level: 1439 },
  { label: "Anglia Ruskin University", value: "Anglia_Ruskin_University", level: 1440 },
  { label: "Arts University Bournemouth", value: "Arts_University_Bournemouth", level: 1441 },
  { label: "Aston University", value: "Aston_University", level: 1442 },
  { label: "Bath Spa University", value: "Bath_Spa_University", level: 1443 },
  { label: "Birkbeck College, University of London", value: "Birkbeck_College,_University_of_London", level: 1444 },
  { label: "Birmingham City University", value: "Birmingham_City_University", level: 1445 },
  { label: "Bishop Grosseteste University", value: "Bishop_Grosseteste_University", level: 1446 },
  { label: "Bournemouth University", value: "Bournemouth_University", level: 1447 },
  { label: "BPP University", value: "BPP_University", level: 1448 },
  { label: "British College of Osteopathic Medicine", value: "British_College_of_Osteopathic_Medicine", level: 1449 },
  { label: "Brunel University Uxbridge", value: "Brunel_University_Uxbridge", level: 1450 },
  { label: "Buckinghamshire New University", value: "Buckinghamshire_New_University", level: 1451 },
  { label: "Camberwell College of Arts", value: "Camberwell_College_of_Arts", level: 1452 },
  { label: "Canterbury Christ Church University", value: "Canterbury_Christ_Church_University", level: 1453 },
  { label: "Cardiff Metropolitan University", value: "Cardiff_Metropolitan_University", level: 1454 },
  { label: "Cardiff University", value: "Cardiff_University", level: 1455 },
  { label: "Central Saint Martins College of Art & Design", value: "Central_Saint_Martins_College_of_Art_&_Design", level: 1456 },
  { label: "Chelsea College of Art and Design", value: "Chelsea_College_of_Art_and_Design", level: 1457 },
  { label: "City University", value: "City_University", level: 1458 },
  { label: "Courtauld Institute of Art, University of London", value: "Courtauld_Institute_of_Art,_University_of_London", level: 1459 },
  { label: "Coventry University", value: "Coventry_University", level: 1460 },
  { label: "Cranfield University", value: "Cranfield_University", level: 1461 },
  { label: "De Montfort University", value: "De_Montfort_University", level: 1462 },
  { label: "De Montfort University Leicester", value: "De_Montfort_University_Leicester", level: 1463 },
  { label: "Edge Hill University", value: "Edge_Hill_University", level: 1464 },
  { label: "Edinburgh Napier University", value: "Edinburgh_Napier_University", level: 1465 },
  { label: "European School of Economics", value: "European_School_of_Economics", level: 1466 },
  { label: "Falmouth University", value: "Falmouth_University", level: 1467 },
  { label: "Glasgow Caledonian University", value: "Glasgow_Caledonian_University", level: 1468 },
  { label: "Glasgow School of Art", value: "Glasgow_School_of_Art", level: 1469 },
  { label: "Goldsmiths College, University of London", value: "Goldsmiths_College,_University_of_London", level: 1470 },
  { label: "Heriot-Watt University", value: "Heriot-Watt_University", level: 1471 },
  { label: "Heythrop College, University of London", value: "Heythrop_College,_University_of_London", level: 1472 },
  { label: "ifs University College", value: "ifs_University_College", level: 1473 },
  { label: "Imperial College London", value: "Imperial_College_London", level: 1474 },
  { label: "Imperial College School of Medicine", value: "Imperial_College_School_of_Medicine", level: 1475 },
  {
    label: "Institue of Historical Research, University of London",
    value: "Institue_of_Historical_Research,_University_of_London",
    level: 1476,
  },
  {
    label: "Institute of Advanced Legal Studies, University of London",
    value: "Institute_of_Advanced_Legal_Studies,_University_of_London",
    level: 1477,
  },
  {
    label: "Institute of Classical Studies, University of London",
    value: "Institute_of_Classical_Studies,_University_of_London",
    level: 1478,
  },
  {
    label: "Institute of Commonwealth Studies, University of London",
    value: "Institute_of_Commonwealth_Studies,_University_of_London",
    level: 1479,
  },
  { label: "Institute of Education, University of London", value: "Institute_of_Education,_University_of_London", level: 1480 },
  {
    label: "Institute of Germanic Studies, University of London",
    value: "Institute_of_Germanic_Studies,_University_of_London",
    level: 1481,
  },
  {
    label: "Institute of Latin American Studies, University of London",
    value: "Institute_of_Latin_American_Studies,_University_of_London",
    level: 1482,
  },
  { label: "International Centre for Isclamic Science", value: "International_Centre_for_Isclamic_Science", level: 1483 },
  { label: "International Colleges of Islamic Science", value: "International_Colleges_of_Islamic_Science", level: 1484 },
  { label: "Keele University", value: "Keele_University", level: 1485 },
  { label: "King's College London", value: "King's_College_London", level: 1486 },
  { label: "King's College London, University of London", value: "King's_College_London,_University_of_London", level: 1487 },
  { label: "Kingston University", value: "Kingston_University", level: 1488 },
  { label: "Lancaster University", value: "Lancaster_University", level: 1489 },
  { label: "Leeds Arts University", value: "Leeds_Arts_University", level: 1490 },
  { label: "Leeds Beckett University", value: "Leeds_Beckett_University", level: 1491 },
  { label: "Leeds Metropolitan University", value: "Leeds_Metropolitan_University", level: 1492 },
  { label: "Leeds Trinity University", value: "Leeds_Trinity_University", level: 1493 },
  { label: "Liverpool Hope University", value: "Liverpool_Hope_University", level: 1494 },
  { label: "Liverpool Hope University College", value: "Liverpool_Hope_University_College", level: 1495 },
  { label: "Liverpool Institute for Performing Arts (LIPA)", value: "Liverpool_Institute_for_Performing_Arts_(LIPA)", level: 1496 },
  { label: "Liverpool John Moores University", value: "Liverpool_John_Moores_University", level: 1497 },
  { label: "London Business School", value: "London_Business_School", level: 1498 },
  { label: "London Guildhall University", value: "London_Guildhall_University", level: 1499 },
  { label: "London Institute of Management and Technology", value: "London_Institute_of_Management_and_Technology", level: 1500 },
  { label: "London Metropolitan University", value: "London_Metropolitan_University", level: 1501 },
  { label: "London School of Business & Finance", value: "London_School_of_Business_&_Finance", level: 1502 },
  {
    label: "London School of Economics and Political Science, University of London",
    value: "London_School_of_Economics_and_Political_Science,_University_of_London",
    level: 1503,
  },
  {
    label: "London School of Hygiene & Tropical Medicine, University of London",
    value: "London_School_of_Hygiene_&_Tropical_Medicine,_University_of_London",
    level: 1504,
  },
  { label: "London School of Jewish Studies", value: "London_School_of_Jewish_Studies", level: 1505 },
  { label: "London School of Science & Technology", value: "London_School_of_Science_&_Technology", level: 1506 },
  { label: "London South Bank University", value: "London_South_Bank_University", level: 1507 },
  { label: "Loughborough University", value: "Loughborough_University", level: 1508 },
  { label: "Manchester Business School", value: "Manchester_Business_School", level: 1509 },
  { label: "Manchester Metropolitan University", value: "Manchester_Metropolitan_University", level: 1510 },
  { label: "Manchester University", value: "Manchester_University", level: 1511 },
  { label: "Middlesex University", value: "Middlesex_University", level: 1512 },
  { label: "Napier University", value: "Napier_University", level: 1513 },
  { label: "Newcastle University", value: "Newcastle_University", level: 1514 },
  { label: "Newman University", value: "Newman_University", level: 1515 },
  { label: "Newport International University", value: "Newport_International_University", level: 1516 },
  { label: "Northumbria University", value: "Northumbria_University", level: 1517 },
  { label: "Norwich University of the Arts", value: "Norwich_University_of_the_Arts", level: 1518 },
  { label: "Nottingham Trent University", value: "Nottingham_Trent_University", level: 1519 },
  { label: "Open University", value: "Open_University", level: 1520 },
  { label: "Oxford Brookes University", value: "Oxford_Brookes_University", level: 1521 },
  { label: "Plymouth Marjon University", value: "Plymouth_Marjon_University", level: 1522 },
  { label: "Plymouth University", value: "Plymouth_University", level: 1523 },
  { label: "Queen Margaret University", value: "Queen_Margaret_University", level: 1524 },
  { label: "Queen Margaret University, Edinburgh", value: "Queen_Margaret_University,_Edinburgh", level: 1525 },
  {
    label: "Queen Mary and Westfield College, University of London",
    value: "Queen_Mary_and_Westfield_College,_University_of_London",
    level: 1526,
  },
  { label: "Queen Mary University of London", value: "Queen_Mary_University_of_London", level: 1527 },
  { label: "Queen Mary, University of London", value: "Queen_Mary,_University_of_London", level: 1528 },
  { label: "Queen's University Belfast", value: "Queen's_University_Belfast", level: 1529 },
  { label: "Regent's University London", value: "Regent's_University_London", level: 1530 },
  {
    label: "Richmond University - The American International University in London",
    value: "Richmond_University_-_The_American_International_University_in_London",
    level: 1531,
  },
  { label: "Robert Gordon University", value: "Robert_Gordon_University", level: 1532 },
  { label: "Roehampton University", value: "Roehampton_University", level: 1533 },
  { label: "Roehampton University of Surrey", value: "Roehampton_University_of_Surrey", level: 1534 },
  { label: "Royal Academy of Music", value: "Royal_Academy_of_Music", level: 1535 },
  { label: "Royal Academy of Music, University of London", value: "Royal_Academy_of_Music,_University_of_London", level: 1536 },
  { label: "Royal Agricultural University", value: "Royal_Agricultural_University", level: 1537 },
  { label: "Royal Central School of Speech and Drama", value: "Royal_Central_School_of_Speech_and_Drama", level: 1538 },
  { label: "Royal College of Art", value: "Royal_College_of_Art", level: 1539 },
  { label: "Royal College of Music", value: "Royal_College_of_Music", level: 1540 },
  { label: "Royal College of Music, University of London", value: "Royal_College_of_Music,_University_of_London", level: 1541 },
  { label: "Royal Conservatoire of Scotland", value: "Royal_Conservatoire_of_Scotland", level: 1542 },
  { label: "Royal Free London NHS", value: "Royal_Free_London_NHS", level: 1543 },
  { label: "Royal Holloway and Bedford New College", value: "Royal_Holloway_and_Bedford_New_College", level: 1544 },
  { label: "Royal Holloway, University of London", value: "Royal_Holloway,_University_of_London", level: 1545 },
  { label: "Royal Northern College of Music", value: "Royal_Northern_College_of_Music", level: 1546 },
  { label: "Royal Veterinary College, University of London", value: "Royal_Veterinary_College,_University_of_London", level: 1547 },
  {
    label: "Saint George's Hospital Medical School, University of London",
    value: "Saint_George's_Hospital_Medical_School,_University_of_London",
    level: 1548,
  },
  {
    label: "School of Oriental and African Studies, University of London",
    value: "School_of_Oriental_and_African_Studies,_University_of_London",
    level: 1549,
  },
  { label: "School of Pharmacy, University of London", value: "School_of_Pharmacy,_University_of_London", level: 1550 },
  {
    label: "School of Slavonic and East European Studies, University of London",
    value: "School_of_Slavonic_and_East_European_Studies,_University_of_London",
    level: 1551,
  },
  { label: "Sheffield Hallam University", value: "Sheffield_Hallam_University", level: 1552 },
  { label: "SOAS University of London", value: "SOAS_University_of_London", level: 1553 },
  { label: "Solent University", value: "Solent_University", level: 1554 },
  { label: "Sotheby´s Institute of Art - London", value: "Sotheby´s_Institute_of_Art_-_London", level: 1555 },
  { label: "Southampton Solent University", value: "Southampton_Solent_University", level: 1556 },
  { label: "St George's, University of London", value: "St_George's,_University_of_London", level: 1557 },
  { label: "St Mary's University, Twickenham", value: "St_Mary's_University,_Twickenham", level: 1558 },
  { label: "St.Patrick's International College, London", value: "St.Patrick's_International_College,_London", level: 1559 },
  { label: "Staffordshire University", value: "Staffordshire_University", level: 1560 },
  { label: "Stratford College London", value: "Stratford_College_London", level: 1561 },
  { label: "Swansea Metropolitan University", value: "Swansea_Metropolitan_University", level: 1562 },
  { label: "Swansea University", value: "Swansea_University", level: 1563 },
  { label: "Teesside University", value: "Teesside_University", level: 1564 },
  { label: "Thames Valley University", value: "Thames_Valley_University", level: 1565 },
  { label: "The American University in London", value: "The_American_University_in_London", level: 1566 },
  { label: "The Manchester Metropolitan University", value: "The_Manchester_Metropolitan_University", level: 1567 },
  { label: "The Queen's University Belfast", value: "The_Queen's_University_Belfast", level: 1568 },
  { label: "The Robert Gordon University", value: "The_Robert_Gordon_University", level: 1569 },
  { label: "Trinity Laban Conservatoire of Music and Dance", value: "Trinity_Laban_Conservatoire_of_Music_and_Dance", level: 1570 },
  { label: "Ulster University", value: "Ulster_University", level: 1571 },
  {
    label: "United Medical and Dental Schools, University of London",
    value: "United_Medical_and_Dental_Schools,_University_of_London",
    level: 1572,
  },
  { label: "University Campus Suffolk", value: "University_Campus_Suffolk", level: 1573 },
  { label: "University College London, University of London", value: "University_College_London,_University_of_London", level: 1574 },
  { label: "University for the Creative Arts", value: "University_for_the_Creative_Arts", level: 1575 },
  { label: "University of Aberdeen", value: "University_of_Aberdeen", level: 1576 },
  { label: "University of Abertay Dundee", value: "University_of_Abertay_Dundee", level: 1577 },
  { label: "University of Bath", value: "University_of_Bath", level: 1578 },
  { label: "University of Bedfordshire", value: "University_of_Bedfordshire", level: 1579 },
  { label: "University of Birmingham", value: "University_of_Birmingham", level: 1580 },
  { label: "University of Bolton", value: "University_of_Bolton", level: 1581 },
  { label: "University of Bradford", value: "University_of_Bradford", level: 1582 },
  { label: "University of Brighton", value: "University_of_Brighton", level: 1583 },
  { label: "University of Bristol", value: "University_of_Bristol", level: 1584 },
  { label: "University of Buckingham", value: "University_of_Buckingham", level: 1585 },
  { label: "University of Cambridge", value: "University_of_Cambridge", level: 1586 },
  { label: "University of Central Arkansas", value: "University_of_Central_Arkansas", level: 1587 },
  { label: "University of Central Lancashire", value: "University_of_Central_Lancashire", level: 1588 },
  { label: "University of Chester", value: "University_of_Chester", level: 1589 },
  { label: "University of Chichester", value: "University_of_Chichester", level: 1590 },
  { label: "University of Cumbria", value: "University_of_Cumbria", level: 1591 },
  { label: "University of Derby", value: "University_of_Derby", level: 1592 },
  { label: "University of Dundee", value: "University_of_Dundee", level: 1593 },
  { label: "University of Durham", value: "University_of_Durham", level: 1594 },
  { label: "University of East Anglia", value: "University_of_East_Anglia", level: 1595 },
  { label: "University of East London", value: "University_of_East_London", level: 1596 },
  { label: "University of Edinburgh", value: "University_of_Edinburgh", level: 1597 },
  { label: "University of Essex", value: "University_of_Essex", level: 1598 },
  { label: "University of Exeter", value: "University_of_Exeter", level: 1599 },
  { label: "University of Glasgow", value: "University_of_Glasgow", level: 1600 },
  { label: "University of Gloucestershire", value: "University_of_Gloucestershire", level: 1601 },
  { label: "University of Greenwich", value: "University_of_Greenwich", level: 1602 },
  { label: "University of Halifax, Birmingham Campus", value: "University_of_Halifax,_Birmingham_Campus", level: 1603 },
  { label: "University of Hertfordshire", value: "University_of_Hertfordshire", level: 1604 },
  { label: "University of Huddersfield", value: "University_of_Huddersfield", level: 1605 },
  { label: "University of Hull", value: "University_of_Hull", level: 1606 },
  { label: "University of Kent", value: "University_of_Kent", level: 1607 },
  { label: "University of Kent at Canterbury", value: "University_of_Kent_at_Canterbury", level: 1608 },
  { label: "University of Lancaster", value: "University_of_Lancaster", level: 1609 },
  { label: "University of Law", value: "University_of_Law", level: 1610 },
  { label: "University of Leeds", value: "University_of_Leeds", level: 1611 },
  { label: "University of Leicester", value: "University_of_Leicester", level: 1612 },
  { label: "University of Lincoln", value: "University_of_Lincoln", level: 1613 },
  { label: "University of Liverpool", value: "University_of_Liverpool", level: 1614 },
  { label: "University of London", value: "University_of_London", level: 1615 },
  { label: "University of Manchester", value: "University_of_Manchester", level: 1616 },
  { label: "University of Newcastle", value: "University_of_Newcastle", level: 1617 },
  { label: "University of Newcastle-upon-Tyne", value: "University_of_Newcastle-upon-Tyne", level: 1618 },
  { label: "University of North London", value: "University_of_North_London", level: 1619 },
  { label: "University of Northampton", value: "University_of_Northampton", level: 1620 },
  { label: "University of Northumbria at Newcastle", value: "University_of_Northumbria_at_Newcastle", level: 1621 },
  { label: "University of Nottingham", value: "University_of_Nottingham", level: 1622 },
  { label: "University of Oxford", value: "University_of_Oxford", level: 1623 },
  { label: "University of Plymouth", value: "University_of_Plymouth", level: 1624 },
  { label: "University of Portsmouth", value: "University_of_Portsmouth", level: 1625 },
  { label: "University of Reading", value: "University_of_Reading", level: 1626 },
  { label: "University of Roehampton", value: "University_of_Roehampton", level: 1627 },
  { label: "University of Salford", value: "University_of_Salford", level: 1628 },
  { label: "University of Sheffield", value: "University_of_Sheffield", level: 1629 },
  { label: "University of South Wales", value: "University_of_South_Wales", level: 1630 },
  { label: "University of Southampton", value: "University_of_Southampton", level: 1631 },
  { label: "University of St Andrews", value: "University_of_St_Andrews", level: 1632 },
  { label: "University of St Mark & St John", value: "University_of_St_Mark_&_St_John", level: 1633 },
  { label: "University of St. Andrews", value: "University_of_St._Andrews", level: 1634 },
  { label: "University of Stirling", value: "University_of_Stirling", level: 1635 },
  { label: "University of Strathclyde", value: "University_of_Strathclyde", level: 1636 },
  { label: "University of Suffolk", value: "University_of_Suffolk", level: 1637 },
  { label: "University of Sunderland", value: "University_of_Sunderland", level: 1638 },
  { label: "University of Surrey", value: "University_of_Surrey", level: 1639 },
  { label: "University of Sussex", value: "University_of_Sussex", level: 1640 },
  { label: "University of Teesside", value: "University_of_Teesside", level: 1641 },
  { label: "University of the Arts London", value: "University_of_the_Arts_London", level: 1642 },
  { label: "University of the Highlands and Islands", value: "University_of_the_Highlands_and_Islands", level: 1643 },
  { label: "University of the West of England", value: "University_of_the_West_of_England", level: 1644 },
  { label: "University of the West of England, Bristol", value: "University_of_the_West_of_England,_Bristol", level: 1645 },
  { label: "University of the West of Scotland", value: "University_of_the_West_of_Scotland", level: 1646 },
  { label: "University of Ulster", value: "University_of_Ulster", level: 1647 },
  { label: "University of Victoria - London", value: "University_of_Victoria_-_London", level: 1648 },
  { label: "University of Wales", value: "University_of_Wales", level: 1649 },
  { label: "University of Wales Trinity Saint David", value: "University_of_Wales_Trinity_Saint_David", level: 1650 },
  { label: "University of Wales, Aberystwyth", value: "University_of_Wales,_Aberystwyth", level: 1651 },
  { label: "University of Wales, Bangor", value: "University_of_Wales,_Bangor", level: 1652 },
  { label: "University of Wales, Carmarthen Campus", value: "University_of_Wales,_Carmarthen_Campus", level: 1653 },
  { label: "University of Wales, Lampeter", value: "University_of_Wales,_Lampeter", level: 1654 },
  { label: "University of Wales, Swansea", value: "University_of_Wales,_Swansea", level: 1655 },
  { label: "University of Warwick", value: "University_of_Warwick", level: 1656 },
  { label: "University of West London", value: "University_of_West_London", level: 1657 },
  { label: "University of Westminster", value: "University_of_Westminster", level: 1658 },
  { label: "University of Winchester", value: "University_of_Winchester", level: 1659 },
  { label: "University of Wolverhampton", value: "University_of_Wolverhampton", level: 1660 },
  { label: "University of Worcester", value: "University_of_Worcester", level: 1661 },
  { label: "University of York", value: "University_of_York", level: 1662 },
  { label: "Warburg Institute, University of London", value: "Warburg_Institute,_University_of_London", level: 1663 },
  { label: "Warnborough University", value: "Warnborough_University", level: 1664 },
  { label: "Wimbledon School of Art", value: "Wimbledon_School_of_Art", level: 1665 },
  { label: "York St. John University", value: "York_St._John_University", level: 1666 },
  { label: "INTO City University of London", value: "INTO_City_University_of_London", level: 1667 },
  { label: "INTO London", value: "INTO_London", level: 1668 },
  { label: "INTO Manchester", value: "INTO_Manchester", level: 1669 },
  { label: "INTO Manchester Metropolitan University", value: "INTO_Manchester_Metropolitan_University", level: 1670 },
  { label: "INTO Newcastle University", value: "INTO_Newcastle_University", level: 1671 },
  { label: "INTO Queen Univesity Belfast", value: "INTO_Queen_Univesity_Belfast", level: 1672 },
  { label: "INTO University of Manchester", value: "INTO_University_of_Manchester", level: 1673 },
  { label: "INTO University of East Anglia", value: "INTO_University_of_East_Anglia", level: 1674 },
  { label: "INTO University of Exeter", value: "INTO_University_of_Exeter", level: 1675 },
  { label: "INTO University of Stirling", value: "INTO_University_of_Stirling", level: 1676 },
  { label: "Study Group Cardiff University", value: "Study_Group_Cardiff_University", level: 1677 },
  { label: "Study Group Durham University", value: "Study_Group_Durham_University", level: 1678 },
  { label: "Study Group Kingston University", value: "Study_Group_Kingston_University", level: 1679 },
  { label: "Study Group Lancaster University", value: "Study_Group_Lancaster_University", level: 1680 },
  { label: "Study Group Leeds International Study Centre", value: "Study_Group_Leeds_International_Study_Centre", level: 1681 },
  { label: "Study Group Liverpool John Moores University", value: "Study_Group_Liverpool_John_Moores_University", level: 1682 },
  { label: "Study Group Royal Holloway University of London", value: "Study_Group_Royal_Holloway_University_of_London", level: 1683 },
  { label: "Study Group Teesside University", value: "Study_Group_Teesside_University", level: 1684 },
  { label: "Study Group The University of Sheffield", value: "Study_Group_The_University_of_Sheffield", level: 1685 },
  { label: "Study Group University of Aberdeen", value: "Study_Group_University_of_Aberdeen", level: 1686 },
  { label: "Study Group University of Huddersfield", value: "Study_Group_University_of_Huddersfield", level: 1687 },
  { label: "Study Group University of Huddersfield London", value: "Study_Group_University_of_Huddersfield_London", level: 1688 },
  { label: "Study Group University of Strathclyde", value: "Study_Group_University_of_Strathclyde", level: 1689 },
  { label: "Study Group University of Surrey", value: "Study_Group_University_of_Surrey", level: 1690 },
  { label: "Study Group University of Sussex", value: "Study_Group_University_of_Sussex", level: 1691 },
  { label: "OnCampus London Birkbeck University of London", value: "OnCampus_London_Birkbeck_University_of_London", level: 1692 },
  { label: "OnCampus London South Bank University", value: "OnCampus_London_South_Bank_University", level: 1693 },
  { label: "OnCampus Royal Holloway University of London", value: "OnCampus_Royal_Holloway_University_of_London", level: 1694 },
  { label: "OnCampus Queen Mary University of London", value: "OnCampus_Queen_Mary_University_of_London", level: 1695 },
  {
    label: "OnCamous Royal Veterinary College University of London",
    value: "OnCamous_Royal_Veterinary_College_University_of_London",
    level: 1696,
  },
  { label: "OnCampus Goldsmiths", value: "OnCampus_Goldsmiths", level: 1697 },
  { label: "OnCampus St George’s University of London", value: "OnCampus_St_George’s_University_of_London", level: 1698 },
  { label: "OnCampus Sunderland", value: "OnCampus_Sunderland", level: 1699 },
  { label: "OnCampus University of Southampton", value: "OnCampus_University_of_Southampton", level: 1700 },
  { label: "OnCampus Aston University", value: "OnCampus_Aston_University", level: 1701 },
  { label: "OnCampus University of Hull", value: "OnCampus_University_of_Hull", level: 1702 },
  { label: "OnCampus UCLAN", value: "OnCampus_UCLAN", level: 1703 },
  { label: "OnCampus Reading", value: "OnCampus_Reading", level: 1704 },
  { label: "OnCampus Loughborough University", value: "OnCampus_Loughborough_University", level: 1705 },
  { label: "OnCampus RCSI (Royal College of Surgeons, Ireland)", value: "OnCampus_RCSI_(Royal_College_of_Surgeons,_Ireland)", level: 1706 },
  { label: "OnCampus University of NICOSIA", value: "OnCampus_University_of_NICOSIA", level: 1707 },
  {
    label: "Navitas ARUC Anglia Ruskin University College, Cambridge & Chelmsford",
    value: "Navitas_ARUC_Anglia_Ruskin_University_College,_Cambridge_&_Chelmsford",
    level: 1708,
  },
  { label: "Navitas HIC Hertfordshire International College", value: "Navitas_HIC_Hertfordshire_International_College", level: 1709 },
  { label: "Navitas LBIC London Brunel International College", value: "Navitas_LBIC_London_Brunel_International_College", level: 1710 },
  {
    label: "Navitas PUIC Plymouth University International College",
    value: "Navitas_PUIC_Plymouth_University_International_College",
    level: 1711,
  },
  {
    label: "Navitas BCUIC Birmingham City University International College",
    value: "Navitas_BCUIC_Birmingham_City_University_International_College",
    level: 1712,
  },
  {
    label: "Navitas ICRGU International College at Robert Gordon University",
    value: "Navitas_ICRGU_International_College_at_Robert_Gordon_University",
    level: 1713,
  },
  { label: "Navitas ICP International College Portsmouth", value: "Navitas_ICP_International_College_Portsmouth", level: 1714 },
  { label: "Navitas TCSU The College Swansea University", value: "Navitas_TCSU_The_College_Swansea_University", level: 1715 },
  {
    label: "Navitas UNIC University Of Northampton International College",
    value: "Navitas_UNIC_University_Of_Northampton_International_College",
    level: 1716,
  },
  {
    label: "Navitas KUIC Keele University International College",
    value: "Navitas_KUIC_Keele_University_International_College",
    level: 1717,
  },
  { label: "Navitas UA92 University Academy 92", value: "Navitas_UA92_University_Academy_92", level: 1718 },
  {
    label: "Navitas University of Leicester Global Study Centre",
    value: "Navitas_University_of_Leicester_Global_Study_Centre",
    level: 1719,
  },
  { label: "KAPLAN Bournemouth University", value: "KAPLAN_Bournemouth_University", level: 1720 },
  { label: "KAPLAN City, University of London", value: "KAPLAN_City,_University_of_London", level: 1721 },
  { label: "KAPLAN Cranfield University", value: "KAPLAN_Cranfield_University", level: 1722 },
  { label: "KAPLAN Nottingham Trent University", value: "KAPLAN_Nottingham_Trent_University", level: 1723 },
  { label: "KAPLAN Queen Mary University of London", value: "KAPLAN_Queen_Mary_University_of_London", level: 1724 },
  { label: "KAPLAN TEDI-London", value: "KAPLAN_TEDI-London", level: 1725 },
  { label: "KAPLAN University of Birmingham", value: "KAPLAN_University_of_Birmingham", level: 1726 },
  { label: "KAPLAN University of Brighton", value: "KAPLAN_University_of_Brighton", level: 1727 },
  { label: "KAPLAN University of Bristol", value: "KAPLAN_University_of_Bristol", level: 1728 },
  { label: "KAPLAN University of Essex", value: "KAPLAN_University_of_Essex", level: 1729 },
  { label: "KAPLAN University of Glasgow", value: "KAPLAN_University_of_Glasgow", level: 1730 },
  { label: "KAPLAN University of Liverpool", value: "KAPLAN_University_of_Liverpool", level: 1731 },
  { label: "KAPLAN University of Nottingham", value: "KAPLAN_University_of_Nottingham", level: 1732 },
  { label: "KAPLAN University of Westminster", value: "KAPLAN_University_of_Westminster", level: 1733 },
  { label: "KAPLAN University of York", value: "KAPLAN_University_of_York", level: 1734 },
  { label: "KAPLAN UWE Bristol", value: "KAPLAN_UWE_Bristol", level: 1735 },
  { label: "OIEG Bangor University", value: "OIEG_Bangor_University", level: 1736 },
  { label: "OIEG De Montfort University", value: "OIEG_De_Montfort_University", level: 1737 },
  { label: "OIEG University of Dundee", value: "OIEG_University_of_Dundee", level: 1738 },
  { label: "OIEG University of Greenwich", value: "OIEG_University_of_Greenwich", level: 1739 },
  { label: "OIEG University of Bradford", value: "OIEG_University_of_Bradford", level: 1740 },
  { label: "Oxford International Pathway College", value: "Oxford_International_Pathway_College", level: 1741 },
  { label: "Abu Dhabi University", value: "Abu_Dhabi_University", level: 1742 },
  { label: "Ajman University of Science & Technology", value: "Ajman_University_of_Science_&_Technology", level: 1743 },
  { label: "Al Falah University", value: "Al_Falah_University", level: 1744 },
  { label: "Al Ghurair University", value: "Al_Ghurair_University", level: 1745 },
  { label: "Al Khawarizmi International College", value: "Al_Khawarizmi_International_College", level: 1746 },
  { label: "Alain University of Science and Technology", value: "Alain_University_of_Science_and_Technology", level: 1747 },
  { label: "Alhosn University", value: "Alhosn_University", level: 1748 },
  { label: "American College of Dubai", value: "American_College_of_Dubai", level: 1749 },
  { label: "American University in Dubai", value: "American_University_in_Dubai", level: 1750 },
  { label: "American University in the Emirates", value: "American_University_in_the_Emirates", level: 1751 },
  { label: "American University of Ras Al Khaimah", value: "American_University_of_Ras_Al_Khaimah", level: 1752 },
  { label: "American University of Sharjah", value: "American_University_of_Sharjah", level: 1753 },
  { label: "British University in Dubai", value: "British_University_in_Dubai", level: 1754 },
  { label: "Curtin University Dubai", value: "Curtin_University_Dubai", level: 1755 },
  { label: "Dubai Medical College for Girls", value: "Dubai_Medical_College_for_Girls", level: 1756 },
  { label: "Dubai Pharmacy College", value: "Dubai_Pharmacy_College", level: 1757 },
  { label: "Emirates College of Technology", value: "Emirates_College_of_Technology", level: 1758 },
  { label: "European University College", value: "European_University_College", level: 1759 },
  { label: "Gulf Medical University", value: "Gulf_Medical_University", level: 1760 },
  { label: "Hamdan Bin Mohammed Smart University", value: "Hamdan_Bin_Mohammed_Smart_University", level: 1761 },
  { label: "Hariot Watt University Dubai", value: "Hariot_Watt_University_Dubai", level: 1762 },
  { label: "Higher Colleges of Technology", value: "Higher_Colleges_of_Technology", level: 1763 },
  { label: "Jumeira University", value: "Jumeira_University", level: 1764 },
  { label: "Khalifa University", value: "Khalifa_University", level: 1765 },
  {
    label: "Khalifa University of Science, Technology and Research",
    value: "Khalifa_University_of_Science,_Technology_and_Research",
    level: 1766,
  },
  { label: "Mena College of Management", value: "Mena_College_of_Management", level: 1767 },
  { label: "Middlesex University Dubai", value: "Middlesex_University_Dubai", level: 1768 },
  { label: "Navitas Murdoch University Dubai", value: "Navitas_Murdoch_University_Dubai", level: 1769 },
  { label: "New York University, Abu Dhabi", value: "New_York_University,_Abu_Dhabi", level: 1770 },
  { label: "Paris-Sorbonne University Abu Dhabi", value: "Paris-Sorbonne_University_Abu_Dhabi", level: 1771 },
  { label: "Rak Medical & Health Sciences University", value: "Rak_Medical_&_Health_Sciences_University", level: 1772 },
  { label: "Rochester Institute of Technology, Dubai", value: "Rochester_Institute_of_Technology,_Dubai", level: 1773 },
  { label: "Skyline University College", value: "Skyline_University_College", level: 1774 },
  { label: "Synergy University, Dubai Campus", value: "Synergy_University,_Dubai_Campus", level: 1775 },
  { label: "The Emirates Academy of Hotel Managment", value: "The_Emirates_Academy_of_Hotel_Managment", level: 1776 },
  { label: "The Petroleum Institute", value: "The_Petroleum_Institute", level: 1777 },
  { label: "United Arab Emirates University", value: "United_Arab_Emirates_University", level: 1778 },
  { label: "University of Birmingham Dubai", value: "University_of_Birmingham_Dubai", level: 1779 },
  { label: "University Of Dubai", value: "University_Of_Dubai", level: 1780 },
  { label: "University of Jazeera", value: "University_of_Jazeera", level: 1781 },
  { label: "University of Sharjah", value: "University_of_Sharjah", level: 1782 },
  { label: "University of Wollongong - Dubai Campus", value: "University_of_Wollongong_-_Dubai_Campus", level: 1783 },
  { label: "Zayed University", value: "Zayed_University", level: 1784 },
];

const intendedCoursesOptions = [
  { label: "Foundation", id: 1 },
  { label: "Top-Up Year", id: 2 },
  { label: "International Year 1", id: 3 },
  { label: "Undergraduate", id: 4 },
  { label: "Postgraduate", id: 5 },
  { label: "Research", id: 6 },
  { label: "Post Graduate Diploma/Pre-Master's Program", id: 7 },
  { label: "Transfer Application", id: 8 },
  { label: "Other", id: 9 },
];
const pointOfEntryOptions = [
  { label: "0", id: 1 },
  { label: "1", id: 2 },
  { label: "2", id: 3 },
  { label: "3", id: 4 },
  { label: "4", id: 5 },
];
const listOfUniversitiesOptions = listOfUniversities.map((degree, index) => {
  return { label: degree, value: degree, level: index + 1 };
});

const Universities = ({
  setShowForm,
  edit,
  student,
  control,
  handleSubmit,
  data,
  nextStep,
  goBack,
  setApplicationFormData,
  sheetEditData,
  setSheetEditData,
  applicationFormData,
}) => {
  const [listOfCountries, setListOfCountries] = useState([]);
  const handleCancel = () => {
    setShowForm(false);
    setSheetEditData({});
  };
  const methods = useForm({
    defaultValues: {
      // institution: "",
      // courseTitle: "",
      // courseName: "",
      // courseLink: "",
      // otherCourse: "",
      // levelOfStudy: "",
      // otherLevelOfStudy: "",
      // pointOfEntry: "",

      university: "",
      country: "",
      course: "",
      courseLink: "",
      otherCourse: "",
    },
  });
  const onSubmit = (data) => {
    // console.log("data", data);
    setApplicationFormData((prevData) => [...prevData, data]);
    nextStep();
  };
  // console.log("sheetEditData", sheetEditData);
  // console.log("ApplicationFormData", applicationFormData);
  const [showOtherField, setShowOtherField] = useState(
    !edit ? student?.registrationData?.intendedCourse?.label : "" || edit ? data?.appData?.intendedCourse?.label : "",
  );
  const [otherCourseCheck, setOtherCourseCheck] = useState(edit ? data?.appData?.courseTitle?.label : "");

  const addOtherCourse = (data) => {
    if (data.label === "Other") {
      setOtherCourseCheck("Other");
    } else {
      setOtherCourseCheck("");
    }
  };
  const addOtherField = (data) => {
    if (data?.label === "Other") {
      setShowOtherField(data?.label);
    } else {
      setShowOtherField("");
    }
  };

  useEffect(() => {
    const headers = new Headers();
    headers.append("X-CSCAPI-KEY", "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg==");

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((data) =>
        setListOfCountries(
          data.map((item) => ({
            label: item.name,
            value: item.name,
            level: item.id,
          })),
        ),
      )
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <Box mt={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
          <Stack spacing={2}>
            <Controller
              control={control}
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.university : ""}
              name="university"
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listOfUniversities}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="University" />}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.country : ""}
              name="country"
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listOfCountries}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Country" />}
                  {...field}
                />
              )}
            />
            <Controller
              name="course"
              control={control}
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.course : ""}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={intendedCoursesOptions}
                  onChange={(_, data) => (onChange(data), addOtherField(data))}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Course" />}
                  {...field}
                  sx={{ mb: 2 }}
                />
              )}
            />
            {showOtherField === "Other" || sheetEditData?.workingSheet?.otherCourse ? (
              <Controller
                defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.otherCourse : ""}
                control={control}
                name="otherCourse"
                render={({ field }) => (
                  <TextField id="outlined-basic" type="text" label="Other Course" variant="outlined" {...field} sx={{ width: "100%" }} />
                )}
              />
            ) : (
              ""
            )}
            {/* <Controller
              control={control}
              defaultValue={edit ? data?.appData?.courseTitle : ""}
              name="courseTitle"
              rules={{ required: true }}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={universitySubjects}
                  onChange={(_, data) => (onChange(data), addOtherCourse(data))}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Area of Study" />}
                  {...field}
                />
              )}
            /> */}
            {/* <Controller
              control={control}
              defaultValue={edit ? data?.appData?.courseTitle : ""}
              name="courseTitle"
              render={({ field }) => (
                <TextField
                  label="Course Title"
                  error={Boolean(methods.formState.errors.courseTitle)}
                  helperText={methods.formState.errors.courseTitle?.message}
                  id="outlined-basic"
                  type="text"
                  variant="outlined"
                  {...field}
                />
              )}
            /> */}
            {otherCourseCheck === "Other" && (
              <Controller
                control={control}
                defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.otherCourse : ""}
                name="otherCourse"
                render={({ field }) => (
                  <TextField
                    label="Other Course"
                    error={Boolean(methods.formState.errors.otherCourse)}
                    helperText={methods.formState.errors.otherCourse?.message}
                    id="outlined-basic"
                    type="text"
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            )}
            {/* <Controller
              control={control}
              defaultValue={edit ? data?.appData?.courseName : ""}
              rules={{ required: true }}
              name="courseName"
              render={({ field }) => (
                <TextField
                  label="Course Name"
                  error={Boolean(methods.formState.errors.courseName)}
                  helperText={methods.formState.errors.courseName?.message}
                  id="outlined-basic"
                  type="text"
                  variant="outlined"
                  {...field}
                />
              )}
            /> */}
            <Controller
              control={control}
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.courseLink : ""}
              name="courseLink"
              // rules={{ pattern: pattern }}
              render={({ field }) => (
                <TextField
                  label="Course Link"
                  error={Boolean(methods.formState.errors.courseLink)}
                  helperText={methods.formState.errors.courseLink?.message}
                  id="outlined-basic"
                  type="text"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            {/* <Controller
              control={control}
              defaultValue={edit ? data?.appData?.pointOfEntry : ""}
              name="pointOfEntry"
              rules={{ required: true }}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={pointOfEntryOptions}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Point of Entry" />}
                  {...field}
                />
              )}
            /> */}
            <Typography></Typography>
            <Typography></Typography>
            <Typography></Typography>
            <Typography></Typography>
            <Typography></Typography>
          </Stack>
          <Box
            mt={2}
            sx={{
              width: "70%",
              position: "absolute",
              height: "7%",
              bottom: "3%",
              right: "3%",
            }}
          >
            <Stack spacing={2} direction="row" sx={{display: "flex", justifyContent: "end"}}>
              <Button variant="outlined" onClick={goBack}>
                Back
              </Button>
              <Button variant="contained" type="submit">
                Next
              </Button>
              <Button variant="outlined"  color="error" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default Universities;
