import React from "react";
import { Box, Modal, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Personal from "./EnquiryForm/Personal";
import Education from "./EnquiryForm/Education";
import Employment from "./EnquiryForm/Employment";
import OtherForm from "./EnquiryForm/OtherForm";
import Intake from "./EnquiryForm/Intake";
import University from "./EnquiryForm/University";

const steps = ["Personal", "Qualification", "Experience", "Intake", "Comments"];

const AddQuery = ({ handleCloseModal, createEnquiry, leads, proceedData }) => {
  const [activeSteps, setActiveSteps] = useState(0);

  const [enquiryData, setEnquiryData] = useState([]);
  const [formFile, setFormFile] = useState({});

  const { control, handleSubmit } = useForm();

  const nextStep = () => {
    setActiveSteps(activeSteps + 1);
  };

  const goBack = () => {
    setActiveSteps(activeSteps - 1);
  };

  const handleStepClick = (step) => {
    setActiveSteps(step);
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <Personal
            handleSubmit={handleSubmit}
            control={control}
            handleClose={handleCloseModal}
            goBack={goBack}
            nextStep={nextStep}
            setEnquiryData={setEnquiryData}
            setFormFile={setFormFile}
            leads={leads}
            proceedData={proceedData}
          />
        );
      case 1:
        return (
          <Education
            handleSubmit={handleSubmit}
            control={control}
            handleClose={handleCloseModal}
            goBack={goBack}
            nextStep={nextStep}
            proceedData={proceedData}
            setEnquiryData={setEnquiryData}
            setFormFile={setFormFile}
          />
        );
      case 2:
        return (
          <Employment
            handleSubmit={handleSubmit}
            eControl={control}
            handleClose={handleCloseModal}
            goBack={goBack}
            nextStep={nextStep}
            setEnquiryData={setEnquiryData}
            setFormFile={setFormFile}
          />
        );
      case 3:
        return (
          <Intake
            handleSubmit={handleSubmit}
            control={control}
            handleClose={handleCloseModal}
            goBack={goBack}
            nextStep={nextStep}
            setEnquiryData={setEnquiryData}
            setFormFile={setFormFile}
            enquiryData={enquiryData}
          />
        );
      // case 4:
      //   return (
      //     <University
      //       handleSubmit={handleSubmit}
      //       control={control}
      //       handleClose={handleCloseModal}
      //       goBack={goBack}
      //       nextStep={nextStep}
      //       setEnquiryData={setEnquiryData}
      //       setFormFile={setFormFile}
      //       enquiryData={enquiryData}
      //     />
      //   );
      case 4:
        return (
          <OtherForm
            handleSubmit={handleSubmit}
            control={control}
            handleClose={handleCloseModal}
            goBack={goBack}
            nextStep={nextStep}
            setEnquiryData={setEnquiryData}
            setFormFile={setFormFile}
            enquiryData={enquiryData}
            createEnquiry={createEnquiry}
            proceedData={proceedData}
          />
        );
      default:
        return handleCloseModal();
    }
  };

  return (
    <Box
      // sx={{ bgcolor: "white", p: 2, width: "75rem", height: "35rem", borderRadius: ".8rem", overflowY: "scroll", position: "relative" }}
      sx={{
        background: "white",
        width: "75rem",
        height: "70%",
        position: "relative",
        borderRadius: "1rem",
        overflowY: "auto",
        p: 2,
      }}
    >
      <Box>
        <Typography fontSize="1.5rem" mb={2}>
          New Enquiry Form
        </Typography>
        {/* Form Steps */}
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeSteps} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}
              onClick={() => handleStepClick(index)}
              title={label}
              sx={{cursor: "pointer"}}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {/* Form */}
        {handleSteps(activeSteps)}
      </Box>
    </Box>
  );
};

export default AddQuery;
