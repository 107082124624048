import React, { useState } from "react";
import DataWrapper from "../../StudentProfile/ProfileData/DataWrapper";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { hasAnswers } from "../../../BussinessLogic/Leads/OverView";
import { guid } from "../../../utils/cart";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Overview = ({ leadData, EmailComment, allLeadData , CallSchedule }) => {
  // console.log(leadData);
  const hasLeadAnswers = hasAnswers(leadData);

  function generateId() {
    return guid();
  }

  // Modal For Email Purpose
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [message, setMessage] = useState("");
  const [isEmailDone, setisEmailDone] = useState();

  function getCurrentTime() {
    const now = new Date();
    const formatTime12Hour = (date) => {
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      if (hours > 12) {
        hours -= 12;
      } else if (hours === 0) {
        hours = 12;
      }
      return `${hours}:${minutes}`;
    };
    const formattedTime = formatTime12Hour(now);
    return formattedTime;
  }

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  }

  const onSendEmail = () => {
    const time = getCurrentTime();
    const date = getCurrentDate();
    const uId = generateId();
    const data = {
      time: time,
      date: date,
      purpose: "Email",
      content: message,
      email: leadData?.email,
      callDone: isEmailDone,
      mode: "Email",
    };
    EmailComment(data, allLeadData?._id, uId);
    setMessage("");
    handleClose();
  };

  // Modal For Call Purpose
  const [OpenCallModal, setOpenCallModal] = useState(false);
  const [CallMessage, setCallMessage] = useState("");
  const [isCallDone, setisCallDone] = useState("");

  const CallModalOpen = () => setOpenCallModal(true);
  const CallModalClose = () => setOpenCallModal(false);

  const onCallMessage = () => {
    const time = getCurrentTime();
    const date = getCurrentDate();
    const uId = generateId();
    const data = {
      time: time,
      date: date,
      mode: "Call",
      purpose: "Call",
      callDone: isCallDone,
      content: CallMessage,
      email: leadData?.email,
    };
    CallSchedule(data, allLeadData?._id, uId);
    setMessage("");
    CallModalClose();
  };

  return (
    <>
      <DataWrapper heading={"Overview"}>
        {hasLeadAnswers ? (
          leadData?.data?.googleForm?.answers?.map((answer, index) => {
            const questionItem = leadData?.data?.googleForm?.items?.find(
              (item) => item?.questionItem?.question?.questionId === answer?.questionId,
            );
            // console.log("qi", questionItem);

            return (
              <Typography
                key={index}
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  {questionItem?.title}:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{answer.textAnswers?.answers[0]?.value || "N/A"}</span>
                </span>
              </Typography>
            );
          })
        ) : (
          <>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                First Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{leadData?.fname || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Middle Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{leadData?.mname || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Last Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{leadData?.lname || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Email:
                <a href={`mailto:${leadData.email}`} className="link-1">
                  <span style={{ fontWeight: "lighter", marginLeft: "5px", color: "rgb(25,104,173)" }} onClick={handleOpen}>
                    {leadData.email || "N/A"}
                  </span>
                </a>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Education: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{leadData?.education || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Institute Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{leadData?.institute || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Date of Birth: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{leadData?.dob || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Score: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{leadData?.leadScore || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Source: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{leadData?.source || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Mobile Number: <span style={{ fontWeight: "lighter", marginLeft: "5px", color: "rgb(25,104,173)", cursor: "pointer" }} onClick={CallModalOpen}>{leadData?.mobile || "N/A"}</span>
              </span>
            </Typography>
          </>
        )}
      </DataWrapper>

      {/* Modal For Define Purpose for Email  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Email Attended Response
          </Typography>
          <TextField
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            label="Response"
            placeholder="Enter Response"
            variant="outlined"
            sx={{ marginBottom: "15px", marginTop: "10px" }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-name-label">Attend</InputLabel>
            <Select
              value={isEmailDone}
              sx={{ marginBottom: "15px" }}
              onChange={(e) => setisEmailDone(e.target.value)}
              input={<OutlinedInput label="Attend" />}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onSendEmail}
          >
            Add Response
          </Button>
        </Box>
      </Modal>

      {/* Modal For Define Purpose for Call  */}
      <Modal
        open={OpenCallModal}
        onClose={CallModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Call Attended Response
          </Typography>
          <TextField
            fullWidth
            value={CallMessage}
            onChange={(e) => setCallMessage(e.target.value)}
            label="Response"
            placeholder="Enter Response"
            variant="outlined"
            sx={{ marginBottom: "15px", marginTop: "10px" }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-name-label">Attend</InputLabel>
            <Select
              value={isCallDone}
              sx={{ marginBottom: "15px" }}
              onChange={(e) => setisCallDone(e.target.value)}
              input={<OutlinedInput label="Attend" />}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onCallMessage}
          >
            Add Response
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Overview;
