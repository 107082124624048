import { Autocomplete, Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputForm from "../../../../Components/Common/Form/InputForm";
import { useForm, FormProvider, Controller } from "react-hook-form";

const RequirementsForm = ({
  setShowForm,
  nextStep,
  goBack,
  control,
  handleSubmit,
  student,
  edit,
  data,
  setApplicationFormData,
  sheetEditData,
  setSheetEditData,
}) => {
  const [listOfCountries, setListOfCountries] = useState([]);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => {
    const year = currentYear + i;
    return { label: year.toString(), value: year.toString(), level: i + 2 };
  });
  // console.log("data of year", data);
  const handleCancel = () => {
    setShowForm(false);
    setSheetEditData({});
  };
  useEffect(() => {
    const headers = new Headers();
    headers.append("X-CSCAPI-KEY", "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg==");

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((data) =>
        setListOfCountries(
          data.map((item) => ({
            label: item.name,
            value: item.name,
            level: item.id,
          })),
        ),
      )
      .catch((error) => console.log("error", error));
  }, []);
  const methods = useForm({
    defaultValues: {
      // requirement: "",
      applicationDeadline: "",
      additionalRequirement: "",
      // additionalInformation: "",
      scholarship: "",
      sessionStartDate: "",
      // scholarshipDeadline: "",
    },
  });

  const onSubmit = (data) => {
    setApplicationFormData((prevData) => [...prevData, data]);
    nextStep();
  };
  const intakeOptions = [
    { label: "Fall", level: 1 },
    { label: "Spring", level: 2 },
    { label: "Summer", level: 3 },
  ];

  const errors = methods.formState.errors;

  const planOfActions = [
    { label: "A", level: 1 },
    { label: "B", level: 2 },
    { label: "C", level: 3 },
    { label: "D", level: 4 },
  ];

  return (
    <Box mt={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
          <Stack spacing={2}>
            {/* <Controller
              control={control}
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.requirement : ""}
              name="requirement"
              render={({ field }) => (
                <TextField id="outlined-basic" type="text" label="Requirement" variant="outlined" {...field} sx={{ width: "100%" }} />
              )}
            /> */}

            <Controller
              control={control}
              name="applicationDeadline"
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.applicationDeadline : ""}
              render={({ field }) => (
                <TextField
                  error={Boolean(errors.date)}
                  helperText={"Application Deadline"}
                  id="outlined-basic"
                  type="date"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="additionalRequirement"
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.additionalRequirement : ""}
              render={({ field }) => (
                <TextField
                  id="outlined-basic"
                  type="text"
                  label="Additional Requirement"
                  variant="outlined"
                  {...field}
                  sx={{ width: "100%" }}
                />
              )}
            />
            {/* <Controller
              control={control}
              name="additionalInformation"
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.additionalInformation : ""}
              render={({ field }) => (
                <TextField
                  id="outlined-basic"
                  type="text"
                  label="Additional Information"
                  variant="outlined"
                  {...field}
                  sx={{ width: "100%" }}
                />
              )}
            /> */}
            <Controller
              control={control}
              name="scholarship"
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.scholarship : ""}
              render={({ field }) => (
                <TextField id="outlined-basic" type="text" label="Scholarship" variant="outlined" {...field} sx={{ width: "100%" }} />
              )}
            />
            <Controller
              control={control}
              name="sessionStartDate"
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.sessionStartDate : ""}
              render={({ field }) => (
                <TextField
                  id="outlined-basic"
                  helperText={"Session Start Date"}
                  type="date"
                  variant="outlined"
                  {...field}
                  sx={{ width: "100%" }}
                />
              )}
            />
            {/* <Controller
              control={control}
              name="scholarshipDeadline"
              defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.scholarshipDeadline : ""}
              render={({ field }) => (
                <TextField
                  error={Boolean(errors.date)}
                  helperText={"Scholarship Deadline"}
                  id="outlined-basic"
                  type="date"
                  variant="outlined"
                  {...field}
                />
              )}
            /> */}
            <Typography></Typography>
            <Typography></Typography>
            <Typography></Typography>
            <Typography></Typography>
          </Stack>
          <Box
            mt={2}
            sx={{
              width: "70%",
              position: "absolute",
              height: "7%",
              bottom: "3%",
              right: "3%",
            }}
          >
            <Stack spacing={2} direction="row" sx={{ display: "flex", justifyContent: "end" }}>
              <Button variant="outlined" onClick={goBack}>
                Back
              </Button>
              <Button variant="contained" type="submit">
                Next
              </Button>
              <Button variant="outlined"  color="error" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default RequirementsForm;
