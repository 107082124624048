// import { Box } from "@mui/material";
// import { useLocation } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { students } from "../../Data/StudentCardData";
// import { CRUDFunction } from "../../reduxCURD/container";
// import { getLoggedInUser } from "../../utils/loggedInUser";
// import StudentCard from "./StudentCard";
// import StudentForm from "./StudentForm";
// import { Typography } from "@mui/material";
// import TopBar from "./TopBar";
// import { curdActions } from "../../reduxCURD/actions";
// import { fileUploads } from "../../reduxRelated/actions/fileActions";
// import { DndContext } from "@dnd-kit/core";
// import NewStudentCard from "./NewStudentCard";
// import { Button } from "@mui/material";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ArrowLeft from "../../Images/ArrowLeft";
// import ArrowRight from "../../Images/ArrowRight";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";

// const Students = (props) => {
//   // console.log(props);

//   const [studentData, setStudentData] = useState([]);
//   const [filesToUp, setFilesToUp] = useState(null);
//   const [query, setQuery] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);

//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   const handleCancel = () => {
//     setModal(false);
//   };
//   const itemsPerPage = 10; // Number of items to display per page
//   // console.log(studentData);
//   // console.log("the props are", props);

//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;

//   const displayedStudents = props.students
//     .filter((student) => {
//       if (query === "") {
//         return student;
//       } else if (
//         student?.registrationData?.firstName?.toLowerCase()?.includes(query?.toLowerCase()) ||
//         student?.registeration?.toLowerCase()?.includes(query?.toLowerCase()) ||
//         student?.counselor?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
//         student?.registrationData?.lastName?.toLowerCase()?.includes(query?.toLowerCase()) ||
//         student?.registrationData?.middleName?.toLowerCase()?.includes(query?.toLowerCase())
//       ) {
//         return student;
//       }
//     })
//     .reverse()
//     .slice(startIndex, endIndex);
//   const getStudents = async () => {
//     const response = await props.getStudents();
//   };

//   function generateUniqueId() {
//     const currentDate = new Date();
//     const day = currentDate.getDate().toString().padStart(2, "0");
//     const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
//     const year = currentDate.getFullYear().toString().substr(-2);

//     return `-${day}-${month}${year}`;
//   }

//   const onSubmit = (data, files) => {
//     const user = getLoggedInUser();
//     const branch = user.branch;
//     // console.log("the user is", user);
//     const studentData = {
//       registrationData: data,
//       registeration: generateUniqueId(),
//       counselor: { id: user._id, name: user.name, email: user.email },
//       branch: branch,
//     };
//     // console.log("branch is", branch);
//     setFilesToUp(files);
//     let fileKeys = Object.keys(files);
//     fileKeys.map((k) => {
//       studentData.registrationData[k] = files[k].name;
//     });

//     props.createStudent(studentData);
//   };

//   useEffect(() => {
//     if (props.actionType === "STUDENT_CREATE_SUCCESS") {
//       setSnackbarOpen(true);
//     }
//   }, [props.actionType]);
//   //console.log(snackbarOpen);
//   useEffect(() => {
//     getStudents();
//   }, []);
//   const user = getLoggedInUser();
//   const branch = user.branch;
//   // console.log("the user is", user);
//   // console.log("the user branch is", branch);
//   const uploadFilesOnCreate = () => {
//     if (props.actionType === "STUDENT_CREATE_SUCCESS") {
//       if (filesToUp) {
//         if (!props.student?.registeration) console.log("student data not available");
//         let fileKeys = Object.keys(filesToUp);
//         fileKeys.map((k) => {
//           props.fileUploads(filesToUp[k], "/uploadsingle?stdId=" + props.student?.registeration);
//         });
//         setFilesToUp(null);
//       }
//       getStudents();
//     }
//   };

//   useEffect(() => {
//     uploadFilesOnCreate();
//   }, [props.actionType]);
//   const [modal, setModal] = useState(false);

//   return (
//     <Box
//       width="100%"
//       sx={{
//         pl: 3,
//         pr: 3,
//         py: 12,
//       }}
//     >
//       <TopBar setModal={setModal} setQuery={setQuery} />

//       {props.actionType === "STUDENTS_READ_REQUEST" ? (
//         <Typography
//           sx={{
//             pl: 3,
//             pr: 3,
//             py: 12,
//           }}
//         >
//           Loading...
//         </Typography>
//       ) : (
//         <Box width="100%" sx={{ display: "flex", flexWrap: "wrap", gap: "3.5rem 1.5rem ", marginTop: "3rem" }}>
//           {/* {props.students.map((item, id) => {
//           return (
//             <Link to={`/students/${item.registeration}`}>
//               <StudentCard key={item} data={item} />
//             </Link>
//           );
//         })} */}

//           {displayedStudents.map((item, id) => {
//             return (
//               <Link>
//                 <NewStudentCard data={item} key={item._id} />
//               </Link>
//             );
//           })}
//         </Box>
//       )}

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={4000}
//         onClose={handleCloseSnackbar}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <MuiAlert variant="filled" onClose={handleCloseSnackbar} severity="success">
//           Student has been created Successfully !
//         </MuiAlert>
//       </Snackbar>

//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           marginTop: "2.5rem",
//           [`@media (max-width: 459px)`]: {
//             flexDirection: "column",
//           },
//         }}
//       >
//         <Button
//           variant="contained"
//           onClick={() => setCurrentPage(currentPage - 1)}
//           disabled={currentPage === 1}
//           sx={{
//             marginRight: {
//               xs: "0",
//               md: "1rem",
//               [`@media (max-width: 459px)`]: {
//                 marginBottom: "1rem",
//               },
//             },
//           }}
//           startIcon={<ArrowLeft />}
//         >
//           Previous Page
//         </Button>
//         <Button
//           variant="contained"
//           onClick={() => setCurrentPage(currentPage + 1)}
//           disabled={endIndex >= props.students.length}
//           endIcon={<ArrowRight />}
//         >
//           Next Page
//         </Button>
//       </Box>

//       {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
//     <Button>hello</Button>
//     <Button>hello</Button>
//   </Box> */}

//       {modal && (
//         <Box
//           sx={{
//             width: "100%",
//             height: "100vh",
//             position: "fixed",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             bgcolor: "rgba(0, 0, 0, 0.4)",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             zIndex: "1212",
//           }}
//         >
//           <StudentForm setModal={setModal} setStudentData={setStudentData} onSubmit={onSubmit} studentData={studentData} />
//         </Box>
//       )}
//     </Box>
//   );
// };

// let actionOptions = {
//   create: true,
//   read: true,
//   update: true,
//   delete: true,
//   others: { fileUploads },
// };
// const studentsContainer = CRUDFunction(Students, "student", actionOptions);
// export default studentsContainer;

import { Box, IconButton, Modal, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { students } from "../../Data/StudentCardData";
import { CRUDFunction } from "../../reduxCURD/container";
import { getLoggedInUser } from "../../utils/loggedInUser";
import StudentCard from "./StudentCard";
import StudentForm from "./StudentForm";
import { Typography } from "@mui/material";
import TopBar from "./TopBar";
import { curdActions } from "../../reduxCURD/actions";
import { fileUploads } from "../../reduxRelated/actions/fileActions";
import { DndContext } from "@dnd-kit/core";
import NewStudentCard from "./NewStudentCard";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TextField from "@mui/material/TextField";
import ArrowLeft from "../../Images/ArrowLeft";
import ArrowRight from "../../Images/ArrowRight";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AddIcon from "../../Images/AddIcon";
import SearchIcon from "../../Images/SearchIcon";
import Line from "../../Images/Line";

const Students = (props) => {
  const [studentData, setStudentData] = useState([]);

  const [filesToUp, setFilesToUp] = useState(null);

  // const [query, setQuery] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCancel = () => {
    setModal(false);
  };
  const itemsPerPage = 10; // Number of items to display per page
  // console.log(studentData);
  // console.log("the props are", props);

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;

  // const displayedStudents = props.students
  //   .filter((student) => {
  //     if (query === "") {
  //       return student;
  //     } else if (
  //       student?.registrationData?.firstName?.toLowerCase()?.includes(query?.toLowerCase()) ||
  //       student?.registeration?.toLowerCase()?.includes(query?.toLowerCase()) ||
  //       student?.counselor?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
  //       student?.registrationData?.lastName?.toLowerCase()?.includes(query?.toLowerCase()) ||
  //       student?.registrationData?.middleName?.toLowerCase()?.includes(query?.toLowerCase())
  //     ) {
  //       return student;
  //     }
  //   })
  //   .reverse()
  // .slice(startIndex, endIndex);
  const getStudents = async () => {
    const response = await props.getStudents();
  };

  function generateUniqueId() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().substr(-2);

    return `-${day}-${month}${year}`;
  }

  const studentTimeline = (content, status) => {
    const loggedInUser = getLoggedInUser();

    const currentDate = new Date();

    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();

    const currentTime = new Date();

    let hours = currentTime.getHours();
    const minutes = currentTime.getMinutes().toString().padStart(2, "0");
    const meridiem = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    const formattedTime = `${hours}:${minutes}`;

    const formattedDate = `${month}/${day}/${year}`;

    props.updateStudent(
      {
        timeline: {
          content: content,
          date: formattedDate,
          time: formattedTime,
          name: loggedInUser?.name,
          status: status,
        },
      },
      props.student?._id,
    );
  };

  const onSubmit = (data, files) => {
    const user = getLoggedInUser();
    const branch = user.branch;
    // console.log("the user is", user);
    const studentData = {
      registrationData: data,
      registeration: generateUniqueId(),
      // here change the user to the logged in user to assign the student to the counselor
      counselor: { id: user._id, name: user.name, email: user.email },
      branch: branch,
    };
    // console.log("branch is", branch);
    setFilesToUp(files);
    let fileKeys = Object.keys(files);
    fileKeys.map((k) => {
      studentData.registrationData[k] = files[k].name;
    });

    props.createStudent(studentData);
  };

  useEffect(() => {
    if (props.actionType === "STUDENT_CREATE_SUCCESS") {
      setSnackbarOpen(true);
      studentTimeline("New student created", "Created");
    }
  }, [props.actionType]);
  //console.log(snackbarOpen);
  useEffect(() => {
    getStudents();
  }, []);

  const user = getLoggedInUser();
  const branch = user.branch;
  // console.log("the user is", user);
  // console.log("the user branch is", branch);
  const uploadFilesOnCreate = () => {
    if (props.actionType === "STUDENT_CREATE_SUCCESS") {
      if (filesToUp) {
        if (!props.student?.registeration) console.log("student data not available");
        let fileKeys = Object.keys(filesToUp);
        fileKeys.map((k) => {
          props.fileUploads(filesToUp[k], "/uploadsingle?stdId=" + props.student?.registeration);
        });
        setFilesToUp(null);
      }
      getStudents();
    }
  };

  useEffect(() => {
    uploadFilesOnCreate();
  }, [props.actionType]);
  const [modal, setModal] = useState(false);
  // const [showCards, setShowCards] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [counselorFilter, setCounselorFilter] = useState("");
  const [branchNames, setBranchNames] = useState("");
  const [registerationId, setRegisterationId] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [showFields, setShowFields] = useState(false);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCounselorChange = (e) => {
    setCounselorFilter(e.target.value);
  };
  const handleBranchChange = (e) => {
    setBranchNames(e.target.value);
  };

  const handleRegisterationChange = (e) => {
    setRegisterationId(e.target.value);
  };
  // console.log(studentData)

  //   const filteredStudents = props?.students?.filter((student) =>
  //   student?.registrationData?.firstName?.toLowerCase()?.includes(searchQuery.toLowerCase())

  // );

  // const filteredStudents = props?.students?.filter((student) => {
  //       if (searchQuery === "") {
  //         return student;
  //       } else if (
  //         student?.registrationData?.firstName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
  //         student?.registrationData?.lastName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
  //         student?.registrationData?.middleName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  //       ) {
  //         return student;
  //       }
  //     })
  // console.log({filteredStudents});

  // const filteredStudents = props?.students?.filter((student) => {
  //   const fullName = `${student?.registrationData?.firstName || ""} ${student?.registrationData?.middleName || ""} ${student?.registrationData?.lastName || ""}`.toLowerCase();
  //   const counselorName = `${student?.counselor?.name || ""}`.toLowerCase();
  //   const branchName =  `${student?.branch || student?.registrationData?.branch?.text || "" }`.toLowerCase()
  //   const registerationID = `${student?.registeration || ""}`

  //   return (
  //     (searchQuery === "" || fullName.includes(searchQuery.toLowerCase())) &&
  //     (counselorFilter === "" || counselorName.includes(counselorFilter.toLowerCase())) &&
  //     (branchNames === "" || branchName?.includes(branchNames?.toLowerCase()) )  &&
  //     (registerationId === "" || registerationID?.includes(registerationId))
  //   );
  // });
  const handleSearch = () => {
    const newFilteredStudents = props.students.filter((student) => {
      const fullName = `${student?.registrationData?.firstName || ""} ${student?.registrationData?.middleName || ""} ${
        student?.registrationData?.lastName || ""
      }`.toLowerCase();
      const counselorName = `${student?.counselor?.name || ""}`.toLowerCase();
      const branchName = `${student?.branch || student?.registrationData?.branch?.text || ""}`.toLowerCase();
      const registerationID = `${student?.registeration || ""}`;

      return (
        (searchQuery === "" || fullName.includes(searchQuery.toLowerCase())) &&
        (counselorFilter === "" || counselorName.includes(counselorFilter.toLowerCase())) &&
        (branchNames === "" || branchName?.includes(branchNames?.toLowerCase())) &&
        (registerationId === "" || registerationID?.includes(registerationId))
      );
    });

    setFilteredStudents(newFilteredStudents);
    handleClose();
  };

  const handleSearchClick = () => {
    if (searchQuery === "" && counselorFilter === "" && branchNames === "" && registerationId === "") {
      // If all search fields are empty, fetch all students
      setFilteredStudents(props.students);
    } else {
      // Perform the regular search
      handleSearch();
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isMobile = useMediaQuery("(max-width:768px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 4,
  };

  // console.log({displayedStudents});

  return (
    <Box
      width="100%"
      sx={{
        pl: 3,
        pr: 3,
        py: 12,
      }}
    >
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            "@media (max-width: 430px)": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgb(25, 104, 173)",
              width: "fit-content",
              // ml: '45px',
              height: "55px",
              borderRadius: "1rem",
              transition: "width 0.3s ease",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ margin: "0px 0px 0px 12px" }}
              onClick={handleOpen}
            >
              <SearchIcon />
            </IconButton>

            <IconButton color="inherit" sx={{ margin: "0 0px" }}>
              <Line />
            </IconButton>

            <IconButton color="inherit" sx={{ margin: "0px 15px 0px 15px" }} onClick={() => setModal(true)}>
              <AddIcon />
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "300px",
                  marginLeft: "10px",
                  mb: "2px",
                }}
              >
                Add Student
              </Typography>
            </IconButton>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Search by Name"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{
                width: "300px",
                "@media (max-width: 1024px)": {
                  width: "250px",
                },
              }}
            />
            <TextField
              id="outlined-basic"
              label="Counsellor"
              variant="outlined"
              value={counselorFilter}
              onChange={handleCounselorChange}
              sx={{
                width: "300px",
                "@media (max-width: 1024px)": {
                  width: "250px",
                },
              }}
            />
            <TextField
              id="outlined-basic"
              label="Branch"
              variant="outlined"
              value={branchNames}
              onChange={handleBranchChange}
              sx={{
                width: "300px",
                "@media (max-width: 1024px)": {
                  width: "250px",
                },
              }}
            />
            <TextField
              id="outlined-basic"
              label="Registration Id"
              variant="outlined"
              value={registerationId}
              onChange={handleRegisterationChange}
              sx={{
                width: "300px",
                "@media (max-width: 1024px)": {
                  width: "250px",
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleSearchClick}
              sx={{
                height: "55px",
                width: "100px",
                borderRadius: "10px",
              }}
            >
              Search
            </Button>
            <TopBar setModal={setModal} setShowFields={setShowFields} showFields={showFields} />
          </Box>
        </>
      )}

      {/* <TopBar setModal={setModal} setShowFields={setShowFields} showFields={showFields} />
      {showFields && (
        <Box sx={{ display: "flex", gap: "10px" }}>
          <TextField id="outlined-basic" label="Search by Name" variant="outlined" value={searchQuery} onChange={handleSearchChange} />
          <TextField id="outlined-basic" label="Cousellor" variant="outlined" value={counselorFilter} onChange={handleCounselorChange} />
          <TextField id="outlined-basic" label="Branch" variant="outlined" value={branchNames} onChange={handleBranchChange} />
          <TextField
            id="outlined-basic"
            label="Registeration Id"
            variant="outlined"
            value={registerationId}
            onChange={handleRegisterationChange}
          />
          <Button variant="contained" onClick={handleSearchClick}>
            Search
          </Button>
        </Box>
      )} */}

      <Box width="100%" sx={{ display: "flex", flexWrap: "wrap", gap: "3.5rem 1.5rem ", marginTop: "3rem" }}>
        {/* {props.students.map((item, id) => {
          return (
            <Link to={`/students/${item.registeration}`}>
              <StudentCard key={item} data={item} />
            </Link>
          );
        })} */}

        {filteredStudents.map((item, id) => {
          return (
            <Link>
              <NewStudentCard data={item} key={item._id} />
            </Link>
          );
        })}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert variant="filled" onClose={handleCloseSnackbar} severity="success">
          Student has been created Successfully !
        </MuiAlert>
      </Snackbar>

      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2.5rem",
          [`@media (max-width: 459px)`]: {
            flexDirection: "column",
          },
        }}
      >
        <Button
          variant="contained"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{
            marginRight: {
              xs: "0",
              md: "1rem",
              [`@media (max-width: 459px)`]: {
                marginBottom: "1rem",
              },
            },
          }}
          startIcon={<ArrowLeft />}
        >
          Previous Page
        </Button>
        <Button
          variant="contained"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={endIndex >= props.students.length}
          endIcon={<ArrowRight />}
        >
          Next Page
        </Button>
      </Box> */}

      {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
    <Button>hello</Button>
    <Button>hello</Button>
  </Box> */}

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Search by Name"
              fullWidth
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <TextField
              id="outlined-basic"
              label="Counsellor"
              fullWidth
              variant="outlined"
              value={counselorFilter}
              onChange={handleCounselorChange}
            />
            <TextField id="outlined-basic" label="Branch" fullWidth variant="outlined" value={branchNames} onChange={handleBranchChange} />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Registration Id"
              variant="outlined"
              value={registerationId}
              onChange={handleRegisterationChange}
            />

            <Button
              variant="contained"
              onClick={handleSearchClick}
              fullWidth
              sx={{
                height: "45px",
                borderRadius: "10px",
              }}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Modal>

      {modal && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(0, 0, 0, 0.4)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1212",
          }}
        >
          <StudentForm setModal={setModal} setStudentData={setStudentData} onSubmit={onSubmit} studentData={studentData} />
        </Box>
      )}
    </Box>
  );
};

let actionOptions = {
  create: true,
  read: true,
  update: true,
  delete: true,
  others: { fileUploads },
};
const studentsContainer = CRUDFunction(Students, "student", actionOptions);
export default studentsContainer;
