import React, { useEffect, useState } from "react";
import { CRUDFunction } from "../../../reduxCURD/container";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import VisaTopDetail from "./VisaTopDetail";
import VisaApprovalForCompilance from "./VisaApprovalForCompilance";
import { curdActions } from "../../../reduxCURD/actions";
import VisaCompliance from "./VisaCompliance";
import { VisaAccessMethods } from "../../../BussinessLogic/Visa/VisaAccessMethod";
import DocumentCheckList from "./DocumentCheckList/DocumentCheckListView.jsx";
import { fileUploads } from "../../../reduxRelated/actions/fileActions";
import { getCountryDocuments } from "../../../BussinessLogic/Visa/CheckCountry";
import DocumentVerification from "./DocumentVerification/visaProf.jsx";
import Schedule from "./ScheduleMeeting/Schedule.jsx";

const VisaProfile = (props) => {
  // console.log("props", props);
  // this state is for whether we want to go to compliance stage or not
  const [approvalStatus, setApprovalStatus] = useState("");
  //state for document uploades in doucment checklist to store name of file
  const [activeSection, setActiveSection] = useState();

  const visaStage = props?.visaview?.stage || 5;

  useEffect(() => {
    if (visaStage !== undefined) {
      setActiveSection(visaStage);
    }
  }, [visaStage]);

  //get Single visa Profile data
  const getSingleVisa = () => {
    props?.getVisaview(props?.match?.params?.id);
  };

  useEffect(() => {
    getSingleVisa();
  }, []);

  // get ALL uSERS
  const getUsers = () => {
    props?.getUsers();
  };

  useEffect(() => {
    getUsers();
  }, []);

  const visaFileUploading = async (multiArrayFiles) => {
    // console.log("multiArrayFiles on onSubmit", multiArrayFiles);

    // if (multiArrayFiles) {
    await props.fileUploads(multiArrayFiles, "/uploadsingle?stdId=" + props?.visaview?.studentId);

    // }
  };

  const steps = ["Document Check List", "Meeting Schedule", "Document Submission Proof", "Enrollment Proof",];

  const handleTab = (step) => {
    // console.log("step", step);

    switch (step) {
      case 5:
        if (getCountryDocuments(props?.visaview).length <= 0) return  <div style={{textAlign: "center", marginTop: "50px"}}>No Documents Found</div>;
        return (
          <DocumentCheckList
            updateVisa={props?.updateVisaview}
            visaFileUploading={visaFileUploading}
            visa={props?.visaview}
            users={props?.users}
            documents={getCountryDocuments(props?.visaview)}
          />
        );
      case 6:
        return (
         <Schedule
         updateVisa={props?.updateVisaview}
         visa={props?.visaview}
          />
         
        );
      case 7:
        return (
          <DocumentVerification
          updateVisa={props?.updateVisaview}
          visaFileUploading={visaFileUploading}
          visa={props?.visaview}
          users={props?.users}
          profFileName = "visaSubmissionProf"
        />
        
        );
      case 8 :
        case 9 : // this is just to temporarily show that all stages is completed
        return (
          <DocumentVerification
          updateVisa={props?.updateVisaview}
          visaFileUploading={visaFileUploading}
          visa={props?.visaview}
          users={props?.users}
          profFileName = "visaEnrollmentProf"
        />
        );
        
      // default:
      //   return null; 
    }
  };

  const handleStepClick = (step) => {
    if (visaStage >= step) {
      setActiveSection(step);
    }
  };

  return (
    <Box sx={{ marginTop: "100px" }}>
      <VisaTopDetail updateVisa={props?.updateVisaview} visaView={props?.visaview} users={props?.users} />

      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeSection} alternativeLabel>
          {steps.map((label, index) => (
            <Step
              key={label}
              onClick={() => handleStepClick(index + 5)}
              active={activeSection === index + 5}
              completed={activeSection > index + 5}
              disabled={activeSection < index + 5}
              title={label}
              sx={{ cursor: "pointer" }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>


      {handleTab(activeSection)}

      {/* {!props?.visaview?.status && (
        <VisaApprovalForCompilance
          updateVisa={props?.updateVisaview}
          visaView={props?.visaview}
          setApprovalStatus={setApprovalStatus}
          approvalStatus={approvalStatus}
        />
      )}

      {props?.visaview?.status &&
        props?.visaview?.complianceApproval === "unapproved" &&
        (VisaAccessMethods?.visaOfficerRoleCheck() ||
          VisaAccessMethods?.adminRoleCheck() ||
          VisaAccessMethods?.managementRoleCheck() ||
          VisaAccessMethods?.branchManagerRoleCheck()) && <VisaCompliance updateVisa={props?.updateVisaview} visaView={props?.visaview} />}

      {(props?.visaview?.complianceApproval === "approved" || props?.visaview?.visaOfficerForm?.interviewResult === "pass") && ( */}

      {/* )} */}
    </Box>
  );
};

let variableList = {
  userReducer: {
    users: [],
  },
};
const getUsers = curdActions.getUsers;

let actionOptions = {
  create: true,
  update: true,
  delete: true,
  read: true,
  others: {
    fileUploads,
    getUsers,
  },
};
let addditionalREducer = ["userReducer"];

let VisaContainer = CRUDFunction(VisaProfile, "visaview", actionOptions, variableList, addditionalREducer, "visa");
export default VisaContainer;
