import { Autocomplete, Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputForm from "../../../Components/Common/Form/InputForm";
import { useForm, FormProvider, Controller } from "react-hook-form";

const Joining = ({ setModal, goBack, control, handleSubmit, nextStep, setStudentData, isEdit, existingStudentData: sData }) => {
  const handleCancel = () => {
    setModal(false);
  };
  const [listOfCountries, setListOfCountries] = useState([]);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => {
    const year = currentYear + i;
    return { label: year.toString(), value: year.toString(), level: i + 2 };
  });
  // useEffect(() => {
  //   const headers = new Headers();
  //   headers.append("X-CSCAPI-KEY", "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg==");

  //   const requestOptions = {
  //     method: "GET",
  //     headers: headers,
  //     redirect: "follow",
  //   };

  //   fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
  //     .then((response) => response.json())
  //     .then((data) =>
  //       setListOfCountries(
  //         data.map((item) => ({
  //           label: item.name,
  //           value: item.name,
  //           level: item.id,
  //         })),
  //       ),
  //     )
  //     .catch((error) => console.log("error", error));
  // }, []);
  const onSubmit = (data) => {
    setStudentData((prevData) => [...prevData, data]);
    nextStep();
  };
  const [showOtherField, setShowOtherField] = useState("");

  const methods = useForm({
    defaultValues: {
      // countryPreference: "",
      year: "",
      intake: "",
      // otherIntake: "",
    },
  });
  const intakeOptions = [
    { label: "Fall", level: 1 },
    { label: "Spring", level: 2 },
    { label: "Summer", level: 3 },
    // { label: "Other", level: 4 },
  ];

  // const addOtherField = (data) => {
  //   if (data?.label === "Other") {
  //     setShowOtherField(data?.label);
  //   } else {
  //     setShowOtherField("");
  //   }
  // };

  return (
    <Box my={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
          <Stack spacing={2}>
            {/* <Controller
              control={control}
              defaultValue={""}
              name="countryPreference"
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listOfCountries}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Country Preference" />}
                  {...field}
                />
              )}
            /> */}
            <Controller
              control={control}
              defaultValue={isEdit ? sData?.registrationData?.year : ""}
              name="year"
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={years}
                  onChange={(_, data) => onChange(data?.value)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Year" />}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={isEdit ? sData?.registrationData?.intake : ""}
              name="intake"
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={intakeOptions}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Intake" />}
                  {...field}
                />
              )}
            />
            {/* {showOtherField === "Other" && (
              <Controller
                defaultValue={""}
                control={control}
                name="otherIntake"
                render={({ field }) => (
                  <TextField id="outlined-basic" type="text" label="Other Intake" variant="outlined" {...field} sx={{ width: "100%" }} />
                )}
              />
            )} */}
          </Stack>
          <Box
            mt={2}
            sx={{
              width: "70%",
              position: "absolute",
              height: "7%",
              bottom: "3%",
              right: "3%",
            }}
          >
            <Stack spacing={2} direction="row" sx={{display: "flex", justifyContent: "end"}}>
              <Button variant="outlined" onClick={goBack}>
                Back
              </Button>
              <Button variant="contained" type="submit">
                Next
              </Button>
               <Button variant="outlined" color="error" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </FormProvider>

      {/* <InputForm
        config={{
          noPaper: true,
          saveText: "Next",
          cancelButton: true,
          backButton: true,
          headerValue: "Date of joining",
        }}
        handleSave={nextStep}
        handleClose={handleCancel}
        handleBack={backStep}
        formFieldSchema={JoiningForm}
      /> */}
    </Box>
  );
};

export default Joining;
