import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const EnhancedTable = ({ tabalData }) => {
  console.log("tabalData", tabalData);

  const tableColumns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "owner", headerName: "Owner", width: 150 },
    { field: "branch", headerName: "Branch", editable: true, width: 150 },
    { field: "namePrefix", headerName: "Name Prefix", width: 150 },
    { field: "fname", headerName: "First Name", width: 150 },
    { field: "mname", headerName: "Middle Name", width: 150 },
    { field: "lname", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Emails", width: 150 },
    { field: "dc", headerName: "Dialing Code", width: 150 },
    { field: "mobile", headerName: "Mobile No", width: 150 },
    { field: "hno", headerName: "House No", width: 150 },
    { field: "education", headerName: "Education", width: 150 },
    { field: "institute", headerName: "Institute Name", width: 150 },
    { field: "dob", headerName: "DOB", width: 120 },
  ];
  const manualData = tabalData?.map((lead, index) => {
    const counselorsNames = lead?.assignTo ? lead.assignTo.map((person) => person?.name || "N/A").join(", ") : "N/A";
    if (lead) {
      return {
        id: index,
        owner: counselorsNames,
        branch: lead?.branchName?.value || "N.A",
        namePrefix: lead?.namePrefix?.label || "N.A",
        fname: lead?.firstName || "N/A",
        mname: lead?.middleName || "N/A",
        lname: lead?.lastName || "N/A",
        dc: lead?.dialingCode?.label || "N/A",
        mobile: lead?.cellNo || "N/A",
        hno: lead?.homeNo || "N/A",
        dob: lead?.dob || "N/A",
        education: lead?.education || "N/A",
        institute: lead?.instituteName || "N/A",
        email: lead?.infoEmail || "N/A",
      };
    }

    return [];
  });

  //   console.log("manualData", manualData);

  return (
    <DataGrid
      sx={{
        height: `700px`,
      }}
      rows={manualData}
      columns={tableColumns}
    />
  );
};

export default EnhancedTable;
