import React, { useState, useEffect, useRef } from "react";
import { getLoggedInUser } from "../../utils/loggedInUser";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  MenuList,
  Modal,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  Tooltip,
} from "@mui/material";
import { borderRadius, Stack } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
import { Link } from "react-router-dom";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import EscalateIcon from "../../Images/EscalateIcon";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import IconSquareRotated from "../../Images/IconSquareRotated";
import IconStar from "../../Images/IconStar";
import VisibilityIcon from "@mui/icons-material/Visibility";

const NewStudentCard = ({ data, location, updateStudent, getStudents, isProcessingRoute, setStdUpdate }) => {
  // console.log({ data })
  const [userBranch, setUserBranch] = useState(null);
  const [StatusModal, setStatusModal] = useState(false);
  const [StatusProcessModal, setStatusProcessModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setStatusModal(false);
        setStatusProcessModal(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Modal For Registration Status
  const StatusModalOpen = () => setStatusModal(true);

  const StatusModalClose = () => {
    setStatusModal(false);
  };

  // For InProcess Status
  const handleProcess = async () => {
    await updateStudent({ isProcess: true, isClosed: false }, data?._id);
    getStudents();
    StatusModalClose();
    setStdUpdate(true);
  };
  // For Closed Status
  const handleClose = async () => {
    await updateStudent({ isClosed: true, isProcess: false }, data?._id);
    getStudents();
    StatusModalClose();
    setStdUpdate(true);
  };

  // Modal For Processing Status
  const StatusModalOpen1 = () => setStatusProcessModal(true);

  const StatusModalClose1 = () => {
    setStatusProcessModal(false);
  };

  // For InProcess Status
  const handleProcessing = async () => {
    await updateStudent({ inProcess: true, ProcessClosed: false, isClosed: false }, data?._id);
    getStudents();
    StatusModalClose1();
    setStdUpdate(true);
  };
  // For ProcessClosed Status
  const handleClosing = async () => {
    await updateStudent({ ProcessClosed: true, inProcess: false, isClosed: true }, data?._id);
    getStudents();
    StatusModalClose1();
    setStdUpdate(true);
  };

  const handleFavorite = async () => {
    await updateStudent({ isFavorite: !data?.isFavorite }, data?._id);
    getStudents();
    setStdUpdate(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const user = getLoggedInUser();
    setUserBranch(user.branch);
  }, []);

  let name = "";
  if (data?.registrationData?.firstName) name += data.registrationData.firstName;
  if (data?.registrationData?.middleName) name += " " + data.registrationData.middleName;
  if (data?.registrationData?.lastName) name += " " + data.registrationData.lastName;

  // console.log("data", data);

  const getStyles = (data, isProcessingRoute) => {
    let backgroundColor = "white";
    let boxShadowColor = "Grey";

    if (!isProcessingRoute) {
      if (data?.isProcess || data?.inProcess) {
        if (data?.appPackStats?.applicationCounter >= 0 && data?.appPackStats?.approved >= 0) {
          if (data?.appPackStats?.applicationCounter >= data?.appPackStats?.approved) {
            backgroundColor = "#EAF1FF";
          }
          boxShadowColor = "#4361ee";
        } else {
          backgroundColor = "#EAF1FF";
          boxShadowColor = "#4361ee";
        }
      }

      if (data?.isClosed) {
        backgroundColor = "#FFF9ED";
        boxShadowColor = "#E2A03F";
      }
      if (data?.isProcessing && data?.appPackStats?.applicationCounter === data?.appPackStats?.approved) {
        backgroundColor = "#67d58363";
        boxShadowColor = "#118409de";
      }
    } else {
      if (data?.isProcessing && !data?.moveToVisa && !data?.isClosed) {
        backgroundColor = "#EAF1FF";
        boxShadowColor = "#4361ee";
      }
      if (data?.isClosed) {
        backgroundColor = "#FFF9ED";
        boxShadowColor = "#E2A03F";
      }
      if (data?.moveToVisa) {
        backgroundColor = "#67d58363";
        boxShadowColor = "#118409de";
      }
    }

    return {
      backgroundColor,
      boxShadow: `0px 2px 3px ${boxShadowColor}`,
    };
  };

  const styles = getStyles(data, isProcessingRoute);

  return (
    <>
      <Card
        style={styles}
        sx={{
          width: 316,
          overflow: "initial",
          height: 300,
          overflowY: "hidden",

          [`@media (max-width: 455px)`]: {
            width: 300,
          },

          [`@media (max-width: 390px)`]: {
            width: 270,
          },
        }}
      >
        {/* <CardMedia
        sx={{ height: 140 }}
        image="https://images.unsplash.com/photo-1531545514256-b1400bc00f31?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
        title="green iguana"
      /> */}

        <CardContent>
          {/* <Stack spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px" }}> */}

          <Box sx={{ display: "flex", justifyContent: "start", alignItems: "start  ", gap: "5px" }}>
            <Avatar
              sx={{
                // zIndex: 1,
                width: 35,
                height: 35,
                // position: "relative",
                // marginTop: "-40px",
                overflow: "hidden",
              }}
              src=""
            />
            <Box>
              <Typography variant="p" component="div" sx={{ textAlign: "center", fontSize: 14, fontWeight: "600", color: "black" }}>
                {name}
              </Typography>
              <Typography variant="subtitle2" component="div" sx={{ fontSize: "12px", color: "gray" }}>
                Reg #: {data?.registeration}
              </Typography>
            </Box>
          </Box>

          {/* </Stack> */}

          <Stack mt={2} direction="row" justifyContent="space-between" alignItems="center" color="gray">
            <Typography variant="subtitle1" color="black" sx={{ fontWeight: "400" }}>
              Counselor(s)
            </Typography>
            <span>{data?.counselor?.name}</span>
          </Stack>

          <Stack my={1} direction="row" justifyContent="space-between" alignItems="center" color="gray">
            <Typography variant="subtitle1" color="black" sx={{ fontWeight: "400" }}>
              Branch{" "}
            </Typography>
            <span style={{ color: "gray", fontSize: "0.8rem" }}>
              {data?.registrationData?.branch?.text || data?.registrationData?.branch}
            </span>
          </Stack>

          <Stack my={1} direction="row" justifyContent="space-between" alignItems="center" color="gray">
            <Typography variant="subtitle1" color="black" sx={{ fontWeight: "400" }}>
              Application Officer
            </Typography>
            <span>{data?.appOfficer?.name}</span>
          </Stack>

          <Stack mt={1} spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" color="black" sx={{ fontWeight: "400" }}>
                Applications
              </Typography>
              <Box
                sx={{
                  width: "23px",
                  height: "23px",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: ".9rem",
                  fontWeight: "bold",
                  color: "gray",
                }}
              >
                {data?.appPackStats?.applicationCounter || 0}
              </Box>
            </Stack>
            {/* {!isProcessingRoute && (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" color="black" sx={{ fontWeight: "400" }}>
                  Move To Processing
                </Typography>
                <Box
                  sx={{
                    width: "23px",
                    height: "23px",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: ".9rem",
                    fontWeight: "bold",
                    color: "gray",
                  }}
                >
                  {data?.appPackStats?.approved || 0}
                </Box>
              </Stack>
            )} */}

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" color="black" sx={{ fontWeight: "400" }}>
                Escalate
              </Typography>
              <Box>
                {data?.appPackStats?.escalatedValue == "Low Priorty" ? (
                  <EscalateIcon color={"blue"} />
                ) : data?.appPackStats?.escalatedValue == "High Priority" ? (
                  <EscalateIcon color={"red"} />
                ) : data?.appPackStats?.escalatedValue == "Medium Priority" ? (
                  <EscalateIcon color={"green"} />
                ) : null}
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {!isProcessingRoute ? (
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {/* <Typography variant="subtitle1" color="black">
                  Status
                </Typography> */}
                  <Tooltip
                    title={
                      (data?.isProcess || data?.inProcess) && !data?.isProcessing
                        ? "In Process"
                        : data?.isClosed
                        ? "Registration Closed"
                        : data?.isProcessing && data?.appPackStats?.applicationCounter === data?.appPackStats?.approved
                        ? "Moved to Processing"
                        : "In Process"
                    }
                    placement="top"
                  >
                    <button
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setStatusModal((prev) => !prev);
                      }}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <IconSquareRotated
                        fill={
                          (data?.isProcess || data?.inProcess) && !data?.isProcessing
                            ? "#4361ee"
                            : data?.isClosed
                            ? "#E2A03F"
                            : data?.isProcessing && data?.appPackStats?.applicationCounter === data?.appPackStats?.approved
                            ? "#118409de"
                            : "#4361ee"
                        }
                        sx={{
                          marginRight: "5px",
                          color:
                            data?.isProcess || data?.inProcess
                              ? "#4361ee"
                              : data?.isClosed
                              ? "#E2A03F"
                              : data?.isProcessing
                              ? "#118409de"
                              : "Grey",
                        }}
                      />
                    </button>
                  </Tooltip>

                  <Popper open={StatusModal} anchorEl={anchorEl} placement="bottom-start">
                    <Paper ref={dropdownRef} sx={{ p: 1, width: 180 }}>
                      <MenuList>
                        <MenuItem onClick={handleProcess} style={{ gap: "5px" }}>
                          <IconSquareRotated fill="#4361ee" />
                          In Process
                        </MenuItem>
                        <MenuItem onClick={handleClose} style={{ gap: "5px" }}>
                          <IconSquareRotated fill="#E2A03F" />
                          Closed
                        </MenuItem>
                      </MenuList>
                    </Paper>
                  </Popper>
                </Stack>
              ) : (
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {/* <Typography variant="subtitle1" color="#1968AD" sx={{fontWeight: "400"}}>
                  Status
                </Typography> */}
                  <Tooltip
                    title={
                      data?.inProcess && !data?.moveToVisa
                        ? "In Processing"
                        : data?.isClosed === true
                        ? "Processing Closed"
                        : data?.moveToVisa
                        ? "Moved to Visa"
                        : "Not Started"
                    }
                    placement="top"
                  >
                    <button
                      onClick={(event) => {
                        if (!data?.moveToVisa && event) {
                          setAnchorEl(event.currentTarget);
                          StatusModalOpen1((prev) => !prev);
                        }
                      }}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <IconSquareRotated
                        fill={
                          data?.isProcessing && !data?.moveToVisa && !data?.isClosed
                            ? "#4361ee"
                            : data?.ProcessClosed === true
                            ? "#E2A03F"
                            : data?.moveToVisa === true
                            ? "#118409de"
                            : "Grey"
                        }
                      />
                    </button>
                  </Tooltip>

                  <Popper open={StatusProcessModal} anchorEl={anchorEl} placement="bottom-start">
                    <Paper ref={dropdownRef} sx={{ p: 1, width: 180 }}>
                      <MenuList>
                        <MenuItem onClick={handleProcessing} style={{ gap: "5px" }}>
                          <IconSquareRotated fill="#4361ee" />
                          In Processing
                        </MenuItem>
                        <MenuItem onClick={handleClosing} style={{ gap: "5px" }}>
                          <IconSquareRotated fill="#E2A03F" />
                          Closed
                        </MenuItem>
                      </MenuList>
                    </Paper>
                  </Popper>
                </Stack>
              )}

              <Stack direction="row" justifyContent="space-center" alignItems="center" color={"gray"} gap={1}>
                <button onClick={handleFavorite} style={{ border: "none", backgroundColor: "transparent" }}>
                  <IconStar fill={data?.isFavorite ? "#E2A040" : ""} />
                </button>

                <Link to={location == "/processing" ? `/processing/${data?.registeration}` : `/students/${data?.registeration}`}>
                  <Box
                    sx={{
                      // color: "#4361ee",
                      paddingTop: "6px",
                    }}
                  >
                    <VisibilityIcon />
                  </Box>
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>

        {/* <CardActions>
        <Button size="medium">Action</Button>
        <Button size="medium">Action</Button>
      </CardActions> */}
      </Card>

      {/* Status Modal For Registration */}
      {/* <Modal open={StatusModal} onClose={StatusModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Registration Status
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-name-label"> Registration Status</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              input={<OutlinedInput label="Registration Status" />}
            >
              <MenuItem onClick={handleProcess}>In Process</MenuItem>
              <MenuItem onClick={handleClose}>Closed</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Modal> */}

      {/* Status Modal For Processing */}
      {/* <Modal
        open={StatusProcessModal}
        onClose={StatusModalClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Processing Status
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-name-label"> Processing Status</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              input={<OutlinedInput label="Processing Status" />}
            >
              <MenuItem onClick={handleProcessing}>In Process</MenuItem>
              <MenuItem onClick={handleClosing}>Closed</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Modal> */}
    </>
  );
};

export default NewStudentCard;
