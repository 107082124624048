import { useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Personal from "./forms/Personal";
import Education from "./forms/Education";
import EnglishProficiency from "./forms/EnglishProficiency";
import EntranceTest from "./forms/EntranceTest";
// import Objective from "./forms/Objective";
import State from "./forms/State";
import Comments from "./forms/Comments";
import Joining from "./forms/Joining";
import Appointment from "./forms/Appointment";
import Registration from "./forms/Registration";
import { useForm } from "react-hook-form";
import Reference from "./forms/Reference";
import Employment from "./forms/Employment";

const steps = [
  "Registration",
  "Personal",
  "Education",
  "Testing",
  "Entrance Test",
  // "Objective",
  "Country of Interest",
  "Reference",
  "Employment",
  "Intake",
  "Comments",
  "Appointment",
];

const StudentForm = ({ setModal, setStudentData, studentData, onSubmit, isEdit, existingStudentData }) => {
  const [registrationFormFiles, setRegistrationFormFiles] = useState({});
  const [activeSteps, setActiveSteps] = useState(0);

  const { control, handleSubmit } = useForm();

  const nextStep = () => {
    setActiveSteps(activeSteps + 1);
  };

  const goBack = () => {
    setActiveSteps(activeSteps - 1);
  };
  // console.log(registrationFormFiles);
  const handleStepClick = (step) => {
    setActiveSteps(step);
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <Registration
            handleSubmit={handleSubmit}
            control={control}
            setModal={setModal}
            goBack={goBack}
            nextStep={nextStep}
            setStudentData={setStudentData}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      case 1:
        return (
          <Personal
            handleSubmit={handleSubmit}
            control={control}
            setModal={setModal}
            goBack={goBack}
            nextStep={nextStep}
            setStudentData={setStudentData}
            registrationFormFiles={registrationFormFiles}
            setRegistrationFormFiles={setRegistrationFormFiles}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      case 2:
        return (
          <Education
            handleSubmit={handleSubmit}
            control={control}
            setModal={setModal}
            goBack={goBack}
            nextStep={nextStep}
            setStudentData={setStudentData}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      case 3:
        return (
          <EnglishProficiency
            handleSubmit={handleSubmit}
            eControl={control}
            setModal={setModal}
            registrationFormFiles={registrationFormFiles}
            goBack={goBack}
            nextStep={nextStep}
            setStudentData={setStudentData}
            setRegistrationFormFiles={setRegistrationFormFiles}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      case 4:
        return (
          <EntranceTest
            handleSubmit={handleSubmit}
            eControl={control}
            setModal={setModal}
            goBack={goBack}
            nextStep={nextStep}
            setStudentData={setStudentData}
            setRegistrationFormFiles={setRegistrationFormFiles}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      // case 5:
      //   return <Objective setModal={setModal} goBack={goBack} nextStep={nextStep} setStudentData={setStudentData} />;
      case 5:
        return (
          <State
            handleSubmit={handleSubmit}
            control={control}
            setModal={setModal}
            goBack={goBack}
            nextStep={nextStep}
            setStudentData={setStudentData}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      case 6:
        return (
          <Reference
            handleSubmit={handleSubmit}
            eControl={control}
            setModal={setModal}
            goBack={goBack}
            nextStep={nextStep}
            setStudentData={setStudentData}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      case 7:
        return (
          <Employment
            handleSubmit={handleSubmit}
            eControl={control}
            setModal={setModal}
            goBack={goBack}
            nextStep={nextStep}
            setStudentData={setStudentData}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      case 8:
        return (
          <Joining
            handleSubmit={handleSubmit}
            control={control}
            setModal={setModal}
            goBack={goBack}
            nextStep={nextStep}
            setStudentData={setStudentData}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      case 9:
        return (
          <Comments
            handleSubmit={handleSubmit}
            control={control}
            setModal={setModal}
            goBack={goBack}
            nextStep={nextStep}
            setStudentData={setStudentData}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      case 10:
        return (
          <Appointment
            handleSubmit={handleSubmit}
            control={control}
            setModal={setModal}
            goBack={goBack}
            nextStep={nextStep}
            studentData={studentData}
            setStudentData={setStudentData}
            onSubmit={onSubmit}
            registrationFormFiles={registrationFormFiles}
            isEdit={isEdit}
            existingStudentData={existingStudentData}
          />
        );
      default:
        return setModal(false);
    }
  };

  return (
    <Box
      // sx={{ bgcolor: "white", p: 2, width: "75rem", height: "38rem", borderRadius: ".8rem", overflowY: "scroll", position: "relative" }}
      sx={{
        background: "white",
        width: "75rem",
        height: "73%",
        position: "relative",
        borderRadius: "1rem",
        overflowY: "auto",
        p: 2,
      }}
    >
      <Typography fontSize="1.5rem" mb={2}>
        {isEdit ? "Update Student" : "New Student Registration"}
      </Typography>
      {/* Form Steps */}
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeSteps} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}
            onClick={() => handleStepClick(index)}
              title={label}
              sx={{cursor: "pointer"}}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {/* Form */}
      {handleSteps(activeSteps)}
    </Box>
  );
};

export default StudentForm;
