import React, { useState } from "react";
import { Typography, Box, Stack, Grid, Button, TextField } from "@mui/material";
import InputForm from "../../../../Components/Common/Form/InputForm";
import DocumentCheckListItem from "./DocumentCheckListItem";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { CRUDFunction } from "../../../../reduxCURD/container";

// {
//   id: 1,
//   title: "Application Form",
//   name: "applicationForm",
//   required: true,
// },
// {
//   id: 2,
//   title: "SOP",
//   name: "sop",
// },
// {
//   id: 3,
//   title: "Resume",
//   name: "resume",
//   required: true,
// },
// {
//   id: 4,
//   title: "Student Working Sheet",
//   name: "academicChart",
//   required: true,
// },
// {
//   id: 5,
//   title: "Master's (Degree / Transcript) or Completion / Hope letter",
//   name: "masters",
// },
// {
//   id: 6,
//   title: "Bachelors (Degree / Transcript) or Completion / Hope letter",
//   name: "bachelors",
//   required: true,
// },
// {
//   id: 7,
//   title: "GCE A Levels (Statement of Entry / Certificate) / HSC (Marksheet / Certificate)",
//   name: "gceAlevel",
// },
// {
//   id: 8,
//   title: "GCE O Levels (Statement of Entry / Certificate) / SSC (Marksheet / Certificate)",
//   name: "gceOlevel",
// },
// {
//   id: 9,
//   title: "English Proficiency Testing ",
//   name: "testing",
//   required: true,
// },
// {
//   id: 10,
//   title: "References (Academic / Job)",
//   name: "references",
// },
// {
//   id: 11,
//   title: "Passport (Pages 1 and 2)",
//   name: "passport",
// },
// {
//   id: 12,
//   title: "Work experience / Internship letter in case of gap year in study",
//   name: "workExperience",
// },
// {
//   id: 13,
//   title: "Extra Curriculum",
//   name: "extraCurriculum",
// },
// {
//   id: 14,
//   title: "Affidavit of Support",
//   name: "affidavit",
// },
// {
//   id: 15,
//   title: "Bank Letter / Statement",
//   required: true,
//   name: "bankLetter",
// },
// {
//   id: 16,
//   title: "Passport size photographs for Malaysia",
//   name: "passportSizePhotographsForMalaysia",
// },
// {
//   id: 17,
//   title: "Passport for Malaysia & Australia: Photocopy of 36 pages attested by Notary Public",
//   name: "passportForMalaysiaAustralia",
// },

const DocumentUpload = ({
  setShowForm,
  nextStep,
  goBack,
  setApplicationFormData,
  applicationFormData,
  setApplicationDataFinal,
  setSuccessOpen,
  onSubmit,
  onUpdate,
  edit,
  data,
  applicationFormFiles,
  sheetEditData,
  setSheetEditData,
  applications,
  student,
  studentTimeline,
}) => {
  const commentsInput = {
    comments: {
      name: "comments",
      type: "text",
      label: "Comments",
      value: "",
    },
  };
  const handleCancel = () => {
    setShowForm(false);
    setSheetEditData({});
  };
  const [allFiles, setAllFiles] = useState({});
  // console.log(allFiles);

  const methods = useForm({
    defaultValues: {
      requirement: "",
    },
  });
  function generateUniqueId() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().substr(-2);

    if (!generateUniqueId.count) {
      generateUniqueId.count = 1;
    }

    const count = generateUniqueId.count.toString().padStart(5, "0");
    generateUniqueId.count++;

    return `${count}-${day}-${month}${year}`;
  }

  const loadEditDatabaseFiles = () => {
    if (!sheetEditData || !sheetEditData.workingSheet || !sheetEditData.workingSheet.documentUpload) return {};
    // for (let key in data.documentUpload) {

    // }
    return { ...sheetEditData.workingSheet.documentUpload };
  };
  const [databaseFiles, setDatabaseFiles] = useState(loadEditDatabaseFiles());

  const onSend = (event) => {
    setApplicationFormData((prevData) => [...prevData, event]);
    const applicationDataObject = applicationFormData.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});
    setApplicationDataFinal((prevData) => [
      ...prevData,
      { ...applicationDataObject, id: generateUniqueId(), documentUpload: allFiles, ...event },
    ]);
    let multiArrayFiles = [];
    // console.log("allfiles", allFiles);
    Object.keys(allFiles).map((fKey) => [...allFiles[fKey].map((fil) => multiArrayFiles.push(fil))]);
    // console.log("multi array files in document uploading", multiArrayFiles);
    if (edit) {
      // console.log("working sheet is editing");
      let finalAppPack = {
        ...applicationDataObject,
        documentUpload: databaseFiles,
        ...event,
      };
      let finalAllFiles = { ...applicationFormFiles };
      Object.keys(finalAllFiles).map((k) => (finalAppPack[k] = finalAllFiles[k].name));
      onUpdate(
        {
          appData: {
            ...finalAppPack,
            documentUpload: {
              ...databaseFiles,
              ...data.appData.documentUpload,
            },
          },
        },
        data?._id,
        finalAllFiles,
        multiArrayFiles,
      );
    } else {
      // console.log("working sheet is created");
      onSubmit({ ...applicationDataObject, documentUpload: databaseFiles, ...event }, { ...applicationFormFiles }, multiArrayFiles);
      // setSuccessOpen(true);
    }
    nextStep();
  };

  const workingSheetDocs = applications
    ?.filter((app) => app?.appData?.status == "no" && app?.workingSheet?.documentUpload)
    ?.map((sheet) => {
      return sheet?.workingSheet?.documentUpload;
    });

  const resultObject = {};

  // workingSheetDocs.forEach((item) => {
  //   for (const [key, value] of Object.entries(item)) {
  //     if (!resultObject[key]) {
  //       resultObject[key] = value;
  //     } else {
  //       resultObject[key] = [...resultObject[key], ...value];
  //     }
  //   }
  // });

  workingSheetDocs.forEach((item) => {
    for (const [key, value] of Object.entries(item)) {
      if (!resultObject[key]) {
        resultObject[key] = Array.from(new Set(value));
      } else {
        resultObject[key] = Array.from(new Set([...resultObject[key], ...value]));
      }
    }
  });

  const checkList = [
    {
      id: 1,
      title: "SAT",
      name: "sat",
      prevDocs: resultObject?.sat,
    },
    {
      id: 2,
      title: "IELTS",
      name: "ielts",
      prevDocs: resultObject?.ielts,
    },
    {
      id: 3,
      title: "Teacher's recommendations",
      name: "teacherRecommendation",
      prevDocs: resultObject?.teacherRecommendation,
    },
    {
      id: 4,
      title: "Counselor's recommendations",
      name: "counselorRecommendation",
      prevDocs: resultObject?.counselorRecommendation,
    },
    {
      id: 5,
      title: "School reports",
      name: "schoolReports",
      prevDocs: resultObject?.schoolReports,
    },
    {
      id: 6,
      title: "Predicted grades",
      name: "predictedGrades",
      prevDocs: resultObject?.predictedGrades,
    },
    // {
    //   id: 7,
    //   title: "Predicted grades",
    //   name: "predictedGrades",
    //   prevDocs: resultObject?.predictedGrades,
    // },
    {
      id: 8,
      title: "Internship/ECA's certificate",
      name: "internship",
      prevDocs: resultObject?.internship,
    },
    {
      id: 9,
      title: "O Levels certificate",
      name: "olevelCertificate",
      prevDocs: resultObject?.olevelCertificate,
    },
    {
      id: 10,
      title: "AS Levels certificate",
      name: "aslevelCertificate",
      prevDocs: resultObject?.aslevelCertificate,
    },
    {
      id: 11,
      title: "Passport",
      name: "passport",
      prevDocs: resultObject?.passport,
    },
    {
      id: 18,
      title: "Other Documents",
      name: "otherDocuments",
      prevDocs: resultObject?.otherDocuments,
    },
  ];

  console.log("database files", databaseFiles);

  const [documentCheckList, setDocumentCheckList] = useState(checkList);

  return (
    <Box mt={4}>
      <Box>
        <Stack spacing={2}>
          <Grid container spacing={2}>
            {documentCheckList.map((item) => {
              return (
                <Grid key={item.id} item md={6} xs={12}>
                  <DocumentCheckListItem
                    student={student}
                    data={item}
                    setAllFiles={setAllFiles}
                    setDatabaseFiles={setDatabaseFiles}
                    allFiles={allFiles}
                    selectedFiles={databaseFiles && databaseFiles[item.name]}
                  />
                </Grid>
              );
            })}
          </Grid>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSend)} style={{ marginTop: "2rem" }}>
              <Stack spacing={2}>
                <Controller
                  defaultValue={sheetEditData?.workingSheet ? sheetEditData?.workingSheet?.requirement : ""}
                  name="requirement"
                  render={({ field }) => <TextField label="Requirements" id="outlined-basic" type="text" variant="outlined" {...field} />}
                />
                <Typography></Typography>
                <Typography></Typography>
                <Typography></Typography>
                <Typography></Typography>
              </Stack>
              <Box
                mt={2}
                sx={{display: "flex", justifyContent: "end"}}
              >
                <Stack spacing={2} direction="row" sx={{display: "flex", justifyContent: "end"}}>
                  <Button variant="outlined" onClick={goBack}>
                    Back
                  </Button>
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                  <Button variant="outlined"  color="error" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </form>
          </FormProvider>
        </Stack>
      </Box>
    </Box>
  );
};

export default DocumentUpload;
