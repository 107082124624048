import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React from "react";
import Overview from "./Overview";
import Actions from "./Actions";
import TimelineData from "./Timeline";

const DataModal = ({
  leadData,
  leadScheduleCallEdit,
  editCall,
  setleadScheduleCallEdit,
  DeleteSchedule,
  setSchedule,
  setColaburator,
  users,
  allLeadData,
  doneCall,
  scheduleCallData,
  setScheduleCallData,
  setComment,
  onDelete,
  EmailComment,
  CallSchedule
}) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", p: "1rem", position: "relative" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Overview" value="1" />
            <Tab label="Appointment" value="2" />
            <Tab label="Comment Sheet" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Overview leadData={leadData} EmailComment={EmailComment} allLeadData={allLeadData} CallSchedule={CallSchedule} />
        </TabPanel>
        <TabPanel value="2">
          <Actions
            leadScheduleCallEdit={leadScheduleCallEdit}
            setleadScheduleCallEdit={setleadScheduleCallEdit}
            scheduleCallData={scheduleCallData}
            // DeleteSchedule={DeleteSchedule}
            onDelete={onDelete}
            leadData={leadData}
            doneCall={doneCall}
            editCall={editCall}
            setSchedule={setSchedule}
            setColaburator={setColaburator}
            users={users} 
            allLeadData={allLeadData}
            setScheduleCallData={setScheduleCallData}
          />
        </TabPanel>
        <TabPanel value="3">
          <TimelineData allLeadData={allLeadData} setComment={setComment} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default DataModal;
