import { Box, Button, Stack, TextField } from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { getLoggedInUser } from "../../../utils/loggedInUser";

const OtherForm = ({
  control,
  handleSubmit,
  goBack,
  nextStep,
  handleClose,
  setEnquiryData,
  setFormFile,
  enquiryData,
  createEnquiry,
  enquiryEditData,
  editEnquiry,
}) => {
  const methods = useForm({
    defaultValues: {
      comment: "",
      referredBy: "",
    },
  });
  const user = getLoggedInUser();

  const onSend = (data) => {
    const finalEnquiryData = enquiryData.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    // createEnquiry({ ...finalEnquiryData, ...data, purpose: "Created", content: `${user?.name} generated this query`, });
    const enquiryPayload = {
      ...finalEnquiryData,
      ...data,
      purpose: "Created",
      content: `${user?.name} generated this query`,
    };

    if (user?.role === "Counselor" || user?.role === "Student Engagement Specialist") {
      enquiryPayload.assignTo = { id: user._id, name: user?.name, email: user?.email };
    }

    createEnquiry(enquiryPayload);

    nextStep();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSend)} style={{ marginTop: "2rem" }}>
        <Controller
          control={control}
          name="referredBy"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.referredBy : ""}
          render={({ field }) => (
            <TextField id="outlined-basic" label="Referred By" type="text" variant="outlined" {...field} sx={{ width: "100%", mb: 2 }} />
          )}
        />
        <Controller
          name="comment"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.comment : ""}
          control={control}
          render={({ field }) => (
            <TextField label="Comments" id="outlined-basic" type="text" variant="outlined" {...field} sx={{ width: "100%", mb: 2 }} />
          )}
        />
        <Box
          mt={2}
          sx={{
            width: "70%",
            position: "absolute",
            height: "7%",
            bottom: "3%",
            right: "3%",
          }}
        >
          <Stack spacing={2} direction="row" sx={{ position: "flex", justifyContent: "end" }}>
            <Button variant="outlined" onClick={goBack}>
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </form>
    </FormProvider>
  );
};

export default OtherForm;
