import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

const branchesOptions = [
  { label: "Call", id: 1 },
  { label: "Email", id: 2 },
  { label: "In Person", id: 3 },
  { label: "Online Meeting", id: 4 },
];

const ScheduleForm = ({ handleClose, setSchedule, setColaburator, leadData, scheduleCallData, leadScheduleCallEdit, editCall , users}) => {
  // console.log("editCall", editCall);
  // console.log(scheduleCallData);
  // console.log("leadData", leadData);
  // console.log(setSchedule);
  // console.log(leadScheduleCallEdit);
  console.log('users in lead', users);
  const methods = useForm({
    defaultValues: {
      date: leadScheduleCallEdit?.date ? leadScheduleCallEdit?.date : scheduleCallData?.date || "",
      time: leadScheduleCallEdit?.time ? leadScheduleCallEdit?.time : scheduleCallData?.time || "",
      mode: leadScheduleCallEdit?.mode ? leadScheduleCallEdit?.mode : scheduleCallData?.mode || "",
      scheduledWith: leadScheduleCallEdit?.scheduledWith ? leadScheduleCallEdit?.scheduledWith : scheduleCallData?.scheduledWith || [],
      offSet: leadScheduleCallEdit?.offSet ? leadScheduleCallEdit?.offSet : scheduleCallData?.offSet || "",
      // scheduleComment: scheduleCallData?.scheduleComment || "",
    },
  });

  function generateUniqueID() {
    const min = 1000; // Minimum 4-digit number
    const max = 9999; // Maximum 4-digit number
    const uniqueID = Math.floor(Math.random() * (max - min + 1)) + min;
    return uniqueID;
  }

  const onSend = (data) => {
    // console.log(data);
    const uId = generateUniqueID();
    setSchedule(
      {
        ...data,
        email: leadData?.email,
      },
      leadData?.data?._id,
      uId,
    );
   
    if (data?.scheduledWith.length > 0) {
      setColaburator(
        {
          scheduledWith: data?.scheduledWith, // Ensure you're passing scheduledWith correctly
        },
        leadData?.data?._id,
      );
    }
  
    handleClose(handleClose);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSend)} style={{ marginTop: "2rem" }}>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Controller
            control={methods.control}
            defaultValue={leadScheduleCallEdit?.date ? leadScheduleCallEdit?.date : scheduleCallData?.date || ""}
            name="date"
            render={({ field }) => (
              <TextField id="outlined-basic" type="date" variant="outlined" {...field} sx={{ width: "100%", mb: 2 }} />
            )}
          />
          <Controller
            name="time"
            defaultValue={leadScheduleCallEdit?.time ? leadScheduleCallEdit?.time : scheduleCallData?.time || ""}
            control={methods.control}
            render={({ field }) => (
              <TextField id="outlined-basic" type="time" variant="outlined" {...field} sx={{ width: "100%", mb: 2 }} />
            )}
          />

          <Controller
            control={methods.control}
            name="offSet"
            defaultValue={scheduleCallData?.offSet || ""}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[
                  {
                    label: "10",
                    id: 1,
                  },
                  {
                    label: "20",
                    id: 2,
                  },
                  {
                    label: "30",
                    id: 3,
                  },
                ]}
                onChange={(_, data) => onChange(data?.label)}
                renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Notification reminder (mins)" />}
                {...field}
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
        </Box>

        <Controller
          control={methods.control}
          name="mode"
          defaultValue={scheduleCallData?.mode || ""}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={branchesOptions}
              onChange={(_, data) => onChange(data?.label)}
              renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Mode" />}
              {...field}
              sx={{ width: "100%", mb: 2 }}
            />
          )}
        />
       <Controller
  control={methods.control}
  name="scheduledWith"
  defaultValue={scheduleCallData?.scheduledWith || []} // Ensure default is an array
  render={({ field: { ref, onChange, value, ...field } }) => (
    <Autocomplete
      multiple // Enable multiple selections
      id="combo-box-demo"
      options={[
        {
          label: 'Self Assign',  
          id: 'self-assign',  
        },
        ...users
          .filter((user) => user?.role === "Counselor" || user?.email === "sfh@successfactor.org")
          .map((user) => ({
            label: user.name,
            id: user._id,
            email: user.email,
          }))
      ]}
      getOptionLabel={(option) => option.label} // Ensure labels display correctly
      onChange={(_, data) => onChange(data)} // Store selected options as an array
      renderInput={(params) => (
        <TextField {...params} {...field} inputRef={ref} label="Scheduled With" />
      )}
      value={value} // Ensure selected values persist
      sx={{ width: "100%", mb: 2 }}
    />
  )}
/>


        {/* {users
                        .filter((user) => user?.role === "Counselor" || user?.email === "sfh@successfactor.org")
                        .map((user) => (
                          <MenuItem key={user._id} value={user} onClick={() => handleOptionClick(user)}>
                            <Checkbox checked={selectedUsers.some((u) => u.id === user._id)} />
                            <ListItemText primary={user.name} />
                          </MenuItem>
                        ))} */}

        {/* <Controller
          name="scheduleComment"
          control={methods.control}
          defaultValue={scheduleCallData?.scheduleComment || ""}
          render={({ field }) => (
            <TextField id="outlined-basic" type="text" variant="outlined" label="Comment" {...field} sx={{ width: "100%", mb: 2 }} />
          )}
        /> */}

        <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ScheduleForm;
