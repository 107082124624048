import { useForm, FormProvider, Controller } from "react-hook-form";
import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const Intake = ({ control, handleSubmit, goBack, nextStep, handleClose, setEnquiryData, setFormFile, enquiryData }) => {
  const [listOfCountries, setListOfCountries] = useState([]);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => {
    const year = currentYear + i;
    return { label: year.toString(), value: year.toString(), level: i + 2 };
  });
  // console.log("data of year", data);

  useEffect(() => {
    const headers = new Headers();
    headers.append("X-CSCAPI-KEY", "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg==");

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((data) =>
        setListOfCountries(
          data.map((item) => ({
            label: item.name,
            value: item.name,
            level: item.id,
          })),
        ),
      )
      .catch((error) => console.log("error", error));
  }, []);
  const methods = useForm({
    defaultValues: {
      countryPreference: "",
      year: "",
      intake: "",
      // otherIntake: "",
    },
  });
  // const [showOtherField, setShowOtherField] = useState(
  //   !edit ? student.registrationData?.intake?.label : "" || edit ? data?.appData?.intake?.label : "",
  // );

  const onSubmit = (data) => {
    // setApplicationFormData((prevData) => [...prevData, data]);
    nextStep();
  };
  const intakeOptions = [
    { label: "Fall", level: 1 },
    { label: "Spring", level: 2 },
    { label: "Summer", level: 3 },
    // { label: "Other", level: 4 },
  ];
  // const addOtherField = (data) => {
  //   if (data?.label === "Other") {
  //     setShowOtherField(data?.label);
  //   } else {
  //     setShowOtherField("");
  //   }
  // };
  const errors = methods.formState.errors;

  return (
    <Box mt={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
          <Stack spacing={2}>
            <Controller
              control={control}
              //   rules={{ required: true }}
              name="countryPreference"
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listOfCountries}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Country Preference *" />}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="year"
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={years}
                  onChange={(_, data) => onChange(data?.value)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Year" />}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="intake"
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={intakeOptions}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Intake" />}
                  {...field}
                />
              )}
            />
            {/* {showOtherField === "Other" && (
                <Controller
                  defaultValue={!edit ? student.registrationData?.otherIntake : "" || edit ? data?.appData?.otherIntake : ""}
                  control={control}
                  name="otherIntake"
                  render={({ field }) => (
                    <TextField id="outlined-basic" type="text" label="Other Intake" variant="outlined" {...field} sx={{ width: "100%" }} />
                  )}
                />
              )} */}
          </Stack>
          <Box
            mt={2}
            sx={{
              width: "70%",
              position: "absolute",
              height: "7%",
              bottom: "3%",
              right: "3%",
            }}
          >
            <Stack spacing={2} direction="row" sx={{ display: "flex", justifyContent: "end" }}>
              <Button variant="outlined" onClick={goBack}>
                Back
              </Button>
              <Button variant="contained" type="submit">
                Next
              </Button>
               <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default Intake;
