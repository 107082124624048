import { Autocomplete, Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useForm, FormProvider, Controller, useFormContext, useFieldArray } from "react-hook-form";
import { repeaterDefaultValueIndex, repeaterLoader } from "../../StudentProfile/ApplicationForm/Forms/repeaterLoader";

const Reference = ({ setModal, eControl, handleSubmit, goBack, nextStep, setStudentData, isEdit, existingStudentData: sData }) => {
  const {
    // control,
    // handleSubmit,
    formState: { errors },
    control,
    // setValue,
  } = useForm(
    isEdit
      ? {
          defaultValues: {
            reference: repeaterLoader(!isEdit, "reference", sData, {
              referenceName: "",
              referenceType: "",
              contactNo: "",
              emailAddress: "",
              referenceInstitution: "",
              institutionAddress: "",
              designation: "",
            }),
          },
        }
      :
    {
      defaultValues: {
        reference: [
          {
            referenceName: "",
            referenceType: "",
            contactNo: "",
            emailAddress: "",
            referenceInstitution: "",
            institutionAddress: "",
            designation: "",
          },
        ],
      },
    },
  );

  const handleCancel = () => {
    setModal(false);
  };
  const onSubmit = (data) => {
    setStudentData((prevData) => [...prevData, data]);
    nextStep();
  };

  const { fields, append } = useFieldArray({
    control,
    name: "reference",
  });

  // const errors = methods.formState.errors;

  const referenceTypeOptions = [
    { label: "Academic", level: 1 },
    { label: "Professional", level: 2 },
  ];

  // useEffect(() => {
  //   // Initialize form values with existing data when in edit mode
  //   if (!isEdit) {
  //     sData?.registrationData?.reference?.forEach((existingData, index) => {
  //       Object.keys(existingData).forEach((key) => {
  //         setValue(`reference.${index}.${key}`, existingData[key]);
  //       });
  //     });
  //   } else {
  //     // If creating a new student, append fields based on existing data
  //     sData?.registrationData?.reference?.forEach((existingData, index) => {
  //       // Check if there are more default fields than existing data, and append if needed
  //       if (index >= fields.length) {
  //         append({
  //           referenceName: "",
  //           referenceType: "",
  //           contactNo: "",
  //           emailAddress: "",
  //           referenceInstitution: "",
  //           institutionAddress: "",
  //           designation: "",
  //         });
  //       }

  //       Object.keys(existingData).forEach((key) => {
  //         setValue(`reference.${index}.${key}`, existingData[key]);
  //       });
  //     });
  //   }
  // }, [isEdit, fields, setValue, sData.registrationData, append]);

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
        <Stack spacing={2}>
          {fields.map((field, index) => {
            return (
              <Stack spacing={2} key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0",mb: '25px' }}>
                <Stack direction="row">
                  <Controller
                    control={eControl}
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "reference", "referenceName", index, sData) : ""}
                    name={`reference.${index}.referenceName`}
                    render={({ field }) => (
                      <TextField
                        label="Reference Name"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        id="outlined-basic"
                        type="text"
                        variant="outlined"
                        {...field}
                        sx={{
                          width: "49.5%",
                          marginRight: "1%",
                        }}
                      />
                    )}
                  />
                  <Controller
                    name={`reference.${index}.referenceType`}
                    control={eControl}
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "reference", "referenceType", index, sData) : ""}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={referenceTypeOptions}
                        onChange={(_, data) => onChange(data)}
                        renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Reference Type" />}
                        {...field}
                        sx={{
                          width: "49.5%",
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack direction="row">
                  <Controller
                    name={`reference.${index}.contactNo`}
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "reference", "contactNo", index, sData) : ""}
                    control={eControl}
                    render={({ field }) => (
                      <TextField
                        label="Contact No"
                        error={Boolean(errors.contactNo)}
                        helperText={errors.contactNo?.message}
                        id="outlined-basic"
                        type="text"
                        variant="outlined"
                        {...field}
                        sx={{
                          width: "49.5%",
                          marginRight: "1%",
                        }}
                      />
                    )}
                  />
                  <Controller
                    name={`reference.${index}.emailAddress`}
                    control={eControl}
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "reference", "emailAddress", index, sData) : ""}
                    render={({ field }) => (
                      <TextField
                        label="Email Address"
                        error={Boolean(errors.emailAddress)}
                        helperText={errors.emailAddress?.message}
                        id="outlined-basic"
                        type="email"
                        variant="outlined"
                        {...field}
                        sx={{
                          width: "49.5%",
                        }}
                      />
                    )}
                  />
                </Stack>
                <Controller
                  name={`reference.${index}.referenceInstitution`}
                  control={eControl}
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "reference", "referenceInstitution", index, sData) : ""}
                  render={({ field }) => (
                    <TextField
                      label="Institution"
                      error={Boolean(errors.referenceInstitution)}
                      helperText={errors.referenceInstitution?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`reference.${index}.institutionAddress`}
                  control={eControl}
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "reference", "institutionAddress", index, sData) : ""}
                  render={({ field }) => (
                    <TextField
                      label="Institution Address"
                      error={Boolean(errors.institutionAddress)}
                      helperText={errors.institutionAddress?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`reference.${index}.designation`}
                  control={eControl}
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "reference", "designation", index, sData) : ""}
                  render={({ field }) => (
                    <TextField
                      label="Designation"
                      error={Boolean(errors.designation)}
                      helperText={errors.designation?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Stack>
            );
          })}
        </Stack>
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            onClick={() =>
              append({
                referenceName: "",
                referenceType: "",
                contactNo: "",
                emailAddress: "",
                referenceInstitution: "",
                institutionAddress: "",
                designation: "",
              })
            }
          >
            Add More
          </Button>
        </Box>
        <Box
          mt={2}
          sx={{display: "flex", justifyContent: "end"}}
        >
          <Stack spacing={2} direction="row" >
            <Button variant="outlined" onClick={goBack}>
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
             <Button variant="outlined" color="error" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export default Reference;
