import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ActiveApplications from "./ActiveApplications";
import ApplicationStatus from "./ApplicationStatus";
import DocumentCheckList from "./DocumentCheckList";
import Comments from "./Comments";
import ClosedApplications from "./ClosedApplications";
import CommentSection from "./CommentSection/CommentSection";
import { Button, IconButton, Menu, MenuItem, Modal } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import StateMachine from "./StateMachine/StateMachine";
import DocumentCheckListDisplay from "./DocumentCheckListDisplay";
import { useEffect } from "react";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  selectedTab,
  setAppDetail,
  appDetail,
  student,
  onUpdate,
  setShowForm,
  applicationDataFinal,
  setApplicationDataFinal,
  setDeleteOpen,
  applications,
  onDelete,
  onEdit,
  setEdit,
  edit,
  editData,
  fileUpload,
  location,
  updateStudent,
  setWorkingSheetForm,
  doNotShowGrid,
  studentTimelineUpdate,
}) {
  const [value, setValue] = React.useState(0);

  const pageLocation = location?.location?.pathname?.split("/")[1];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const newApplication = () => {
    setShowForm(true);
    setEdit(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCard, setshowCard] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [singleApplicationData, setSingleApplicationData] = useState({});

  let selectedOption = (ids) => {
    let singleApplication = applications?.find((item) => {
      return item?.appData?.id === ids[0];
    });
    setAppDetail(true);
    setSingleApplicationData(singleApplication);
  };

  useEffect(() => {
    if (location.location.search) {
      let singleApplication = applications?.find((item) => {
        return item?.appData?.id === location.location.search.substring(1);
      });
      setAppDetail(true);
      setSingleApplicationData(singleApplication);
    }
  }, [location]);

  useEffect(() => {
    if (singleApplicationData) {
      let singleApplication = applications?.find((item) => {
        return item?._id === singleApplicationData._id;
      });
      setSingleApplicationData(singleApplication);
    }
  }, [applications]);

  const [activeClose, setActiveClose] = useState("Active Applications");
  const [activeTabColor, setActiveTabColor] = useState({
    active: "primary.main",
    closed: "gray",
  });
  return (
    <Box>
      {singleApplicationData?._id ? (
        // <Box
        //   sx={{
        //     width: "100%",
        //     minHeight: "100vh",
        //     position: "absolute",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //     bgcolor: "rgba(0, 0, 0, 0.6)",
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     zIndex: "1212",
        //     overflowY: "scroll",
        //   }}
        // >

        <StateMachine
          student={student}
          onUpdate={onUpdate}
          fileUpload={fileUpload}
          updateStudent={updateStudent}
          setSingleApplicationData={setSingleApplicationData}
          singleApplicationData={singleApplicationData}
          studentTimelineUpdate={studentTimelineUpdate}
        />
      ) : (
        // </Box>
        <Box
          sx={{
            // width: "96.5%",
            background: "white",
            boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.1)",
            marginLeft: selectedTab === 5 ? "2rem" : "",
            marginRight: selectedTab === 5 ? "2rem" : "",
            [`@media (max-width: 425px)`]: {
              margin: 0,
            },
            // marginLeft: "1.75rem",
            // [`@media(max-width:1670px )`]: { width: "96%", marginLeft: "2rem" },
            // [`@media(max-width:451px )`]: { width: "85%", marginLeft: "2rem" },
          }}
        >
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Active Applications" {...a11yProps(0)} />
          <Tab label="Closed Applications" {...a11yProps(1)} />
          <Tab label="Event log" {...a11yProps(4)} />
        </Tabs>
      </Box> */}

          <Box
            sx={{
              // display: "flex",
              // justifyContent: "flex-start",
              // alignItems: "center",
              mb: "1.5rem",
              p: 3,
              "@media (max-width: 580px)": {
                // flexDirection: "column",
                gap: "1rem",
              },
              backgroundColor: "rgba(25, 104, 173, 1)",
            }}
          >
            <Box
              sx={{
                // display: "flex",
                // justifyContent: 'flex-start',
                // alignItems: "center",
                cursor: "pointer",
                // gap: ".5rem",
                color: "primary.main",
                "@media (max-width: 941px)": {
                  // display: "none",
                },
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  gap: ".5rem",
                  color: "primary.main",
                  "@media (max-width: 941px)": {
                    display: "none",
                  },
                }}
              >
                <Typography variant="h4" onClick={() => setActiveClose("Active Applications")}>Active </Typography>
                  <Typography variant="h4" sx={{color:"gray !important" , borderLeft:"2px solid black", paddingLeft:"0.3rem"}} onClick={() => setActiveClose("Closed Applications")}>Closed </Typography>
                <Typography
                  variant="h4"
                  onClick={() => {
                    setActiveClose("Active Applications");
                    setActiveTabColor({ active: "primary.main", closed: "gray" }); // Set the colors
                  }}
                  sx={{ color: activeTabColor.active }} // Use activeTabColor.active for color
                >
                  Active
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: activeTabColor.closed, // Use activeTabColor.closed for color
                    borderLeft: "2px solid black",
                    paddingLeft: "0.3rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setActiveClose("Closed Applications");
                    setActiveTabColor({ active: "gray", closed: "primary.main" }); // Set the colors
                  }}
                >
                  Closed
                </Typography>
              </Box> */}
            </Box>

            {/* <Typography variant="h5">Active Applications</Typography> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // flexDirection: 'row',
                // alignItems: "center",
                "@media (max-width: 425px)": {
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                },
                "@media (max-width: 375px)": {
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                },
              }}
            >
              <Box
                onClick={handleClick}
                sx={{
                  display: "flex",
                  // justifyContent: 'flex-start',
                  // alignItems: "center",
                  cursor: "pointer",
                  // gap: ".5rem",
                  color: "white",
                }}
              >
                <Typography variant="h4">{activeClose}</Typography>
                <ExpandMoreIcon fontSize="large" />
              </Box>

              <Menu id="icon-menu2" anchorEl={anchorEl?.parentElement} open={Boolean(anchorEl)} onClose={handleClose}>
                <Box
                  sx={{
                    p: 1,
                  }}
                >
                  <MenuItem onClick={() => setActiveClose("Closed Applications")}>Closed Applications</MenuItem>
                  <MenuItem onClick={() => setActiveClose("Active Applications")}>Active Applications</MenuItem>
                </Box>
              </Menu>
              {doNotShowGrid && (
                <Box>
                  <ViewColumnIcon sx={{ fontSize: "35px", color: "white", cursor: "pointer" }} onClick={() => setshowCard(true)} />
                  <ViewListIcon sx={{ fontSize: "35px", color: "white", cursor: "pointer" }} onClick={() => setshowCard(false)} />
                </Box>
              )}
            </Box>

            {/* <Button variant="contained" onClick={() => setShowForm(true)}>
              Start Working Sheet
            </Button> */}
            {/* <Button variant="contained" onClick={newApplication}>
              Master Application Pack
            </Button> */}
          </Box>
          {activeClose === "Active Applications" && (
            <>
              <Box
                sx={{
                  height: selectedTab === 5 ? "auto" : "600px",
                  px: 3,
                }}
              >
                <ActiveApplications
                  showCard={showCard}
                  edit={edit}
                  setEdit={setEdit}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  applicationDataFinal={applicationDataFinal}
                  applications={applications}
                  onUpdate={onUpdate}
                  setApplicationDataFinal={setApplicationDataFinal}
                  setAppDetail={setAppDetail}
                  appDetail={appDetail}
                  setShowForm={setShowForm}
                  setDeleteOpen={setDeleteOpen}
                  student={student}
                  selectedOption={selectedOption}
                  data={singleApplicationData}
                  setData={setSingleApplicationData}
                  doNotShowGrid
                  pageLocation={pageLocation}
                  selectedTab={selectedTab}
                />
              </Box>
            </>
          )}
          <Box sx={{ p: 3 }}>
            {activeClose === "Closed Applications" && (
              <ClosedApplications
                applications={applications}
                showCard={showCard}
                selectedTab={selectedTab}
                selectedOption={selectedOption}
                pageLocation={pageLocation}
              />
            )}
          </Box>
          {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              sx={{
                fontSize: "16px",
                // fontWeight: 'bold',
                backgroundColor: "rgba(25, 104, 173, 1)",
                padding: "5px 25px",
                justifyContent: "center",
                color: "white",
                "&:hover": {
                  backgroundColor: "white",
                  color: "rgba(25, 104, 173, 1)",
                  border: "2px solid rgba(25, 104, 173, 1)",
                },
              }}
            >
              Escalate
            </Button>
            <Button
              sx={{
                ml: "20px",
                fontSize: "16px",
                // fontWeight: 'bold',
                backgroundColor: "rgba(217, 217, 217, 1)",
                padding: "5px 25px",
                justifyContent: "center",
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  border: "2px solid black",
                },
              }}
            >
              Revert
            </Button>
          </Box> */}
          {/* <Box>
        <Box py={4}>{appDetail.show && <ApplicationStatus data={data} onUpdate={onUpdate} />}</Box>
        <Box py={4}>{appDetail.show && <DocumentCheckList data={data} />}</Box>
      </Box> */}
          {/* <TabPanel value={value} index={4}>
        My Tasklist
      </TabPanel>
      <TabPanel value={value} index={3}>
        Messages
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CommentSection />
      </TabPanel> */}
        </Box>
      )}
      {/* <Box py={4}>
        {appDetail && singleApplicationData && (
          <DocumentCheckListDisplay onUpdate={onUpdate} fileUpload={fileUpload} data={singleApplicationData} student={student} />
        )}
      </Box> */}
      {/* <Box py={4}>
        {appDetail && singleApplicationData && <ApplicationStatus student={student} data={singleApplicationData} onUpdate={onUpdate} />}
      </Box> */}
    </Box>
  );
}
