import Users from "../User/Users";
import Login from "../../Pages/Login/Login";
import Home from "../../Pages/Home/Home";
import Students from "../../Pages/Student/Students";
import Profile from "../../Pages/StudentProfile/Profile";
import Agreements from "../../Pages/Agreements/Agreements";
// import Dashboard from "../../Pages/Dashboard/Dashboard";
import DashBoardV2Container from "../../Pages/Dashboard/DashBoardV2";
import PrivateRoute from "./PrivateRoute";
import GoogleSignUpPage from "../../Pages/GoogleSIgnUp/GoogleSignUpPage";
import Lead from "../../Pages/Lead/Lead";
import Forms from "../../Pages/Lead/ZForms";
import Submission from "../../Pages/Lead/Submission";
import SubmissionTableData from "../../Pages/Lead/SubmissionTableData";
import Chat from "../../Pages/Chat/Chat";
import SingleForm from "../../Pages/Lead/SingleForm";
import Finance from "../../Pages/Finance/Finance";
import Enquiry from "../../Pages/Enquiry/Enquiry";
import Email from "../../Pages/Email/Email";
import Processing from "../../Pages/Student/Processing";
import Tasks from "../../Pages/Task/Tasks";
import TaskProfile from "../../Pages/Task/TaskProfile";
import Reports from "../../Pages/Reports/Reports";
import Visa from "../../Pages/Visa/Visa";
import VisaProfile from "../../Pages/Visa/VisaProfile/VisaProfile";
export let routes = [
  {
    path: "/login",
    component: Login,
    enableStyle: false,
  },

  {
    path: "/PrivateRoute",
    component: PrivateRoute,
    enableStyle: false,
  },

  {
    path: "/googlecreateuser",
    component: GoogleSignUpPage,
    enableStyle: false,
  },

  {
    path: "/students/:id",
    component: Profile,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Application Officer"],
  },
  {
    path: "/processing/:id",
    component: Profile,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Application Officer"],
  },
  {
    path: "/processing",
    // component: Processing,
    component: Students,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Application Officer"],
  },

  {
    path: "/students",
    component: Students,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Application Officer"],
  },

  {
    path: "/leads",
    component: Lead,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Marketing", "Counselor", "Student Engagement Specialist"],
  },
  {
    path: "/tasks/:studentId",
    component: TaskProfile,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Student", "Marketing", "Application Officer" ,"Student Engagement Specialist"],
  },
  {
    path: "/tasks",
    component: Tasks,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Student", "Marketing", "Application Officer" ,"Student Engagement Specialist"],
  },

  {
    path: "/forms/:id",
    component: SingleForm,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Marketing" ,"Student Engagement Specialist"],
  },
  // {
  //   path: "/forms",
  //   component: Lead,
  //   enableStyle: true,
  // },
  {
    path: "/query",
    component: Enquiry,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Marketing", "Counselor","Student Engagement Specialist"],
  },

  // {
  //   path: "/submission/:id",
  //   component: SubmissionTableData,
  //   enableStyle: true,
  //   permissions: ["Administrator", "Management", "Marketing","Student Engagement Specialist"],
  // },

  {
    path: "/submissions/:id",
    component: SubmissionTableData,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Marketing","Student Engagement Specialist" ,"Student Engagement Specialist"],
  },
  {
    path: "/report",
    component: Reports,
    enableStyle: true,
    permissions: ["Administrator", "Management","Student Engagement Specialist"],
  },
  // {
  //   path: "/submission",
  //   component: Submission,
  //   enableStyle: true,
  // },

  {
    path: "/agreements",
    component: Agreements,
    enableStyle: true,
  },
  {
    path: "/user",
    component: Users,
    enableStyle: true,
    permissions: ["Administrator"],
  },

  {
    path: "/dashboard",
    component: DashBoardV2Container,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Student", "Marketing", "Application Officer" ,"Student Engagement Specialist"],
  },
  // {
  //   path: "/home",
  //   component: Home,
  //   enableStyle: true,
  // },
  {
    path: "/chat",
    component: Chat,
    enableStyle: true,
    permissions: [
      "Administrator",
      "Management",
      "Branch Manager",
      "Counselor",
      "Student",
      "Marketing",
      "Application Officer",
      "Visa Officer",
      "Student Engagement Specialist",
    ],
  },

  {
    path: "/email",
    component: Email,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Student", "Marketing", "Application Officer" ,"Student Engagement Specialist"],
  },

  {
    path: "/finance",
    component: Finance,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Student", "Marketing", "Application Officer" ,"Student Engagement Specialist"],
  },
  {
    path: "/visa/:id",
    component: VisaProfile,
    enableStyle: true,
    permissions: [
      "Administrator",
      "Management",
      "Branch Manager",
      "Counselor",
      "Student",
      "Marketing",
      "Application Officer",
      "Visa Officer",
      "Student Engagement Specialist",
    ],
  },
  {
    path: "/visa",
    component: Visa,
    enableStyle: true,
    permissions: [
      "Administrator",
      "Management",
      "Branch Manager",
      "Counselor",
      "Student",
      "Marketing",
      "Application Officer",
      "Visa Officer",
      "Student Engagement Specialist",
    ],
  },

  {
    path: "/",
    component: DashBoardV2Container,
    enableStyle: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Student", "Marketing", "Application Officer" ,"Student Engagement Specialist"],
  },
];
